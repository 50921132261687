import { useEffect, useState } from "react";

function CreateNoOfVideos({ addData, packageDocReady, proPackage }) {
  const [data, setData] = useState();
  const [dataId, setDataId] = useState("noVideos");

  useEffect(() => {
    addData(dataId, data);
  }, [dataId, addData, data]);

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.noVideos);
    }
  }, [packageDocReady]);

  const onChange = (e) => {
    const { value, id } = e.target;
    setData(value);
    setDataId(id);

    // addData(id, value);
  };

  return (
    <>
      <div className="m-4">
        <label>
          <span>No. of Videos:</span>
          <input
            type="number"
            min="1"
            id="noVideos"
            value={data}
            onChange={onChange}
            className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
          />
        </label>
      </div>
    </>
  );
}

export default CreateNoOfVideos;
