import React from "react";

export default function Backdrop({ className }) {
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac">
          <g>
            <path d="M63,3h-5V1c0-.553-.447-1-1-1H7c-.553,0-1,.447-1,1V3H1c-.553,0-1,.447-1,1v4c0,.553,.447,1,1,1H6v2c0,.553,.447,1,1,1h2V53c0,2.618-1.915,8.794-3.005,9-.55,.002-.995,.449-.995,1s.447,1,1,1H58c.553,0,1.004-.447,1.004-1,0-.531-.411-.965-.934-.998-.021,0-.044-.002-.065-.002-1.09-.206-3.005-6.382-3.005-9V12h2c.553,0,1-.447,1-1v-2h5c.553,0,1-.447,1-1V4c0-.553-.447-1-1-1ZM2,7v-2H6v2H2ZM55.431,62H8.569c1.678-2.871,2.431-7.934,2.431-9V12H53V53c0,1.066,.753,6.129,2.431,9Zm.569-52H8V2H56V10Zm6-3h-4v-2h4v2Z" />
            <path d="M38,52H14c-.553,0-1,.447-1,1s.447,1,1,1h24c.553,0,1-.447,1-1s-.447-1-1-1Z" />
            <path d="M50,52h-2c-.553,0-1,.447-1,1s.447,1,1,1h2c.553,0,1-.447,1-1s-.447-1-1-1Z" />
            <path d="M44,52h-2c-.553,0-1,.447-1,1s.447,1,1,1h2c.553,0,1-.447,1-1s-.447-1-1-1Z" />
          </g>
        </g>
        <g id="ad" />
      </svg>
    </>
  );
}
