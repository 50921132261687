import { useState, useEffect } from "react";
import { servicesArray } from "./arrays/servicesArray";

function CreateServices({ addData, proPackage, packageDocReady }) {
  //set data and id
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("services");

  useEffect(() => {
    addData(dataId, data);
  }, [data, dataId, addData]);

  useEffect(() => {
    console.log("Services: ", dataId, data);
  }, [dataId, data]);

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.services);
    }
  }, [packageDocReady]);

  const onChange = (e) => {
    const { id, value, checked } = e.target;
    // console.log("OnChange before: ", id, value, checked);
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }
    setDataId(id);
  };

  return (
    <>
      <p className="ml-4 mb-4">Services included in this package</p>
      <div onChange={onChange}>
        <ul className="m-4 w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
          {servicesArray.map((item) => (
            <li
              key={item.value}
              className="w-full border-b border-gray-200 rounded-t-lg"
            >
              <div className="flex items-center pl-3">
                <input
                  id={item.id}
                  type={item.type}
                  value={item.value}
                  checked={data.includes(item.value)}
                  className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                />
                <label className="w-full py-3 ml-2 text-sm font-medium text-gray-900 ">
                  {item.title}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default CreateServices;
