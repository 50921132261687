import { ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import { db, storage } from "../../../firebase";
import { BsTrash } from "react-icons/bs";
import { toast } from "react-toastify";
import {
  collection,
  deleteDoc,
  where,
  query,
  getDocs,
  onSnapshot,
  doc,
} from "firebase/firestore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

//Components
import ConfirmModal from "./ConfirmModal";
import QuantityIndicators from "./editImageGalleryComponents/QuantityIndicators";
// import QuantityIndicators from "./portfolioIndicators/QuantityIndicators";

export default function EditImageGallery({ user, imageFolder, maxImages }) {
  const imageListRef = useRef(ref(storage, `${imageFolder}/${user}`));
  const [imageList, setImageList] = useState([]);
  const [imageIDs, setImageIDs] = useState([]);
  const [imageName, setImageName] = useState("");

  const [hidden, setHidden] = useState(true);
  const [continueDelete, setContinueDelete] = useState(false);
  const [deleteID, setDeleteID] = useState();

  const term = "image";

  const hostname = window.location.hostname;

  useEffect(() => {
    const colRef = collection(db, imageFolder);
    const q = query(colRef, where("user", "==", user));

    onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs;
      setImageIDs(docs.map((doc) => doc.id));
      setImageList(docs.map((doc) => doc.data()));
    });
  }, [user]);

  // useEffect(() => {
  //   console.log("All Images: ", imageList);
  //   console.log("All IDs: ", imageIDs);
  // }, [imageList]);

  // useEffect(() => {
  //   const deleteData = async () => {
  //     const deleteRef = ref(storage, deleteID);
  //     console.log("continue with the process:", deleteRef);
  //     await deleteObject(deleteRef)
  //       .then(() => {
  //         console.log("Item was deleted from Firebase Storage", deleteRef);
  //         setContinueDelete(false);
  //         window.location.reload(true);
  //       })
  //       .catch((error) => {
  //         console.log(error.message);
  //         toast.error("There was an error deleting your image");
  //       });
  //   };

  //   if (continueDelete === true) {
  //     deleteData();
  //   }

  //   return () => {
  //     console.log("cleanup");
  //   };
  // }, [continueDelete, deleteID]);

  useEffect(() => {
    console.log(imageFolder);
  }, [imageFolder]);

  useEffect(() => {
    const deleteImage = async () => {
      try {
        const deleteRef = doc(db, `${imageFolder}/${deleteID}`);
        await deleteDoc(deleteRef);
        // navigate(`/packages-editable/${user}`);
        // window.location.reload();
        toast.success("Image is deleted");
      } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong, please try again.");
        setContinueDelete(false);
      }
      setContinueDelete(false);
    };

    if (continueDelete === true) {
      deleteImage();
      console.log("Continue Delete is True");
    }
  }, [continueDelete, deleteID, user, imageFolder]);

  //Delete process
  const onDelete = async (e) => {
    setContinueDelete(false);
    const { id } = e.target;

    // console.log("onClick Url:", id);
    // console.log("Name: ", name);
    // console.log("Value: ", value);
    // console.log(e.target);
    setDeleteID(id);
    // setImageName(name);
    setHidden(false);
  };

  // useEffect(() => {
  //   console.log("imageName: ", imageName);
  // });

  //show/hide confirm modal
  const toggleConfirm = () => {
    setHidden(!hidden);
  };

  //confirm deletion by modal
  const confirmDelete = () => {
    setContinueDelete(true);
  };

  return (
    <>
      {!hidden && (
        <ConfirmModal
          toggleConfirm={toggleConfirm}
          confirmDelete={confirmDelete}
          term={term}
        />
      )}
      <div className="w-full sm:w-4/5 container px-2 mx-auto pt-1 m-10">
        <QuantityIndicators
          mediaQuantity={imageList.length}
          maxImages={maxImages}
        />
        <div className="-m-1 flex flex-wrap md:-m-2">
          {imageList.map((image, index) => {
            // console.log(image);
            let imageURL = "";
            if (image.imageURL.startsWith("https://storage.googleapis.com/")) {
              imageURL = image.imageURL.replace(
                "https://storage.googleapis.com/",
                ""
              );
            } else if (
              image.imageURL.startsWith(
                "https://firebasestorage.googleapis.com/"
              )
            ) {
              imageURL = image.imageURL.replace(
                "https://firebasestorage.googleapis.com/",
                ""
              );
            }

            // const imageURL = image.imageURL.replace(
            //   `https://storage.googleapis.com`,
            //   ``
            // );
            return (
              <div
                key={imageURL}
                className="relative flex w-full md:w-1/4 sm:w-1/2 flex-wrap"
              >
                <div key={imageURL} className="w-full h-full p-1">
                  {hostname === "localhost" ? (
                    <img alt={`portfolio-${index + 1}`} src={image.imageURL} />
                  ) : (
                    <LazyLoadImage
                      alt={`portfolio-${index + 1}`}
                      effect="blur"
                      src={`https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/${imageURL}`}
                      key={imageURL}
                    />
                  )}
                  <BsTrash
                    title="delete"
                    className="h-8 w-8 rounded-full bg-transparent absolute right-2 top-2 z-5 p-2 m-1 ring ring-red-600 text-red-600 text hover:ring-red-800 hover:text-red-800 cursor-pointer"
                    onClick={onDelete}
                    id={imageIDs[index]}
                    // name={image.imageName}
                    // value={image.imageName}
                  />
                </div>
                {/* <span>{url}</span> */}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

// import { ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";
// import React, { useEffect, useRef, useState } from "react";
// import { storage } from "../../../firebase";
// import { BsTrash } from "react-icons/bs";
// import { toast } from "react-toastify";
// import {
//   collection,
//   deleteDoc,
//   where,
//   query,
//   getDocs,
// } from "firebase/firestore";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";
// import { db } from "../../../firebase";

// //Components
// import ConfirmModal from "./ConfirmModal";
// import QuantityIndicators from "./editImageGalleryComponents/QuantityIndicators";
// import DeleteImage from "./editImageGalleryComponents/DeleteImage";

// export default function EditImageGallery({ user, imageFolder }) {
//   const imageListRef = useRef(ref(storage, `${imageFolder}/${user}`));
//   const [imageList, setImageList] = useState([]);

//   const [hidden, setHidden] = useState(true);
//   const [continueDelete, setContinueDelete] = useState(false);

//   const term = "image";

//   const hostname = window.location.hostname;

//   useEffect(() => {
//     console.log("hostname: ", hostname);
//   }, [hostname]);

//   //lists all images in a users repo
//   useEffect(() => {
//     listAll(imageListRef.current).then(async (response) => {
//       const promises = response.items.map((item) => getDownloadURL(item));
//       const urls = await Promise.all(promises);
//       setImageList(urls);
//     });
//   }, []);

//   // useEffect(() => {
//   //   (async () => {
//   //     const colRef = collection(db, "packages");
//   //     const q = query(colRef, where("user", "==", user));
//   //     const snapshots = await getDocs(q);
//   //     const docs = snapshots.docs.map((doc) => doc.data());
//   //     const docsID = snapshots.docs.map((doc) => doc.id);
//   //     setImages(docs);
//   //     setImageIds(docsID);
//   //   })();
//   // }, [user]);

//   useEffect(() => {
//     console.log("Image No: ", imageList.length);
//     console.log("Images: ", imageList);
//   }, [imageList]);

//   //show/hide confirm modal
//   const toggleConfirm = () => {
//     setHidden(!hidden);
//   };

//   //confirm deletion by modal
//   const confirmDelete = () => {
//     setContinueDelete(true);
//   };

//   const hideConfirm = () => {
//     setHidden(false);
//   };

//   const continueDeleteFalse = () => {
//     setContinueDelete(false);
//   };

//   return (
//     <>
//       {!hidden && (
//         <ConfirmModal
//           toggleConfirm={toggleConfirm}
//           confirmDelete={confirmDelete}
//           term={term}
//         />
//       )}
//       <div className="w-full sm:w-4/5 container px-2 mx-auto pt-1 m-10">
//         <QuantityIndicators mediaQuantity={imageList.length} max={100} />
//         <div className="-m-1 flex flex-wrap md:-m-2">
//           {imageList.map((url, index) => {
//             console.log(url, index);
//             const imageURL = url.replace(
//               `https://firebasestorage.googleapis.com`,
//               ``
//             );
//             return (
//               <div
//                 key={url}
//                 className="relative flex w-full md:w-1/4 sm:w-1/2 flex-wrap"
//               >
//                 <div key={url} className="w-full h-full p-1">
//                   {hostname === "localhost" ? (
//                     <img
//                       alt={`portfolio-${index + 1}`}
//                       src={imageURL}
//                       key={url}
//                     />
//                   ) : (
//                     <LazyLoadImage
//                       alt={`portfolio-${index + 1}`}
//                       effect="blur"
//                       src={`https://ik.imagekit.io/ajuaxvsrja/tr:q-50/${imageURL}`}
//                       key={url}
//                     />
//                   )}
//                   <DeleteImage
//                     url={url}
//                     hideConfirm={hideConfirm}
//                     continueDelete={continueDelete}
//                     continueDeleteFalse={continueDeleteFalse}
//                   />
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </>
//   );
// }
