import ActionCamera from "../equipmentIcons/ActionCamera";
import CellphoneCam from "../equipmentIcons/CellphoneCam";
import Drone from "../equipmentIcons/Drone";
import Dslr from "../equipmentIcons/Dslr";
import Mirrorlesscam from "../equipmentIcons/Mirrorlesscam";
import VideoCamera from "../equipmentIcons/VideoCamera";

export const videoCamsArray = (className) => [
  {
    id: "videoCams",
    title: "Video Camera",
    value: "videoCamera",
    type: "checkbox",
    icon: <VideoCamera className={className} />,
  },
  {
    id: "videoCams",
    title: "DSLR Camera",
    value: "dslrCamera",
    type: "checkbox",
    icon: <Dslr className={className} />,
  },
  {
    id: "videoCams",
    title: "Mirrorless Camera",
    value: "mirrorlessCamera",
    type: "checkbox",
    icon: <Mirrorlesscam className={className} />,
  },
  {
    id: "videoCams",
    title: "Action Camera",
    value: "actionCamera",
    type: "checkbox",
    icon: <ActionCamera className={className} />,
  },
  {
    id: "videoCams",
    title: "Cellphone Camera",
    value: "cellphoneCamera",
    type: "checkbox",
    icon: <CellphoneCam className={className} />,
  },
  {
    id: "videoCams",
    title: "Video Drone",
    value: "videoDrone",
    type: "checkbox",
    icon: <Drone className={className} />,
  },
];
//Primary
// action camera, cellphone camera, video drone, dslr, mirrorlesscam, video camera

//Secondary
// battery, lighting, sound equipment, stabilizer, tripod
