// Software
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";

// Components for Edit Details
import CreateName from "./details/CreateName";
import CreateProfilePhoto from "./details/CreateProfilePhoto";
import CreateContact from "./details/CreateContact";
import CreateProfession from "./details/CreateProfession";
import CreatePType from "./details/CreatePType";
import CreatePExpertise from "./details/CreatePExpertise";
import CreateVExpertise from "./details/CreateVExpertise";
import CreatePhotographerRate from "./details/CreatePhotographerRate";
import CreateVideographerRate from "./details/CreateVideographerRate";
import CreateProvince from "./details/CreateProvince";
import CreateRegion from "./details/CreateRegion";
import CreateAboutYou from "./details/CreateAboutYou";
import CreateProfilePublish from "./details/CreateProfilePublish";

//Extras
import { toast } from "react-toastify";

export default function EditDetails({ profile, profileDocReady }) {
  const params = useParams();
  const { user } = params;
  const [profession, setProfession] = useState([]);
  const [pExpertise, setPExpertise] = useState([]);
  const [vExpertise, setVExpertise] = useState([]);
  const [workEnvironment, setWorkEnvironment] = useState([]);
  const [workEnvError, setWorkEnvError] = useState(false);
  const [noProfessionError, setNoProfessionError] = useState(false);
  const [expertiseError, setExpertiseError] = useState(false);
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);

  //loading
  const [loading, setLoading] = useState(false);

  //Set Data that you want to update
  const [formData, setFormData] = useState([]);

  const hostname = window.location.hostname;

  //Set profession error
  useEffect(() => {
    if (profession.length === 0) {
      setNoProfessionError(true);
    } else {
      setNoProfessionError(false);
    }
  }, [profession]);

  //Set work environment error
  useEffect(() => {
    if (profession.length > 0 && workEnvironment.length === 0) {
      setWorkEnvError(true);
    } else {
      setWorkEnvError(false);
    }
  }, [workEnvironment, profession]);

  //Set Expertise errors
  useEffect(() => {
    if (
      profession.includes("photographer") &&
      (pExpertise?.length > 3 || pExpertise?.length === 0)
    ) {
      setExpertiseError(true);
    } else if (
      profession.includes("videographer") &&
      (vExpertise?.length > 3 || vExpertise?.length === 0)
    ) {
      setExpertiseError(true);
    } else {
      setExpertiseError(false);
    }
  }, [profession, pExpertise, vExpertise]);

  useEffect(() => {
    if (address && region === "") {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
  }, [address, region]);

  //Assign Error Variable from formData
  useEffect(() => {
    if (formData.profession) {
      setProfession(formData.profession);
    }
    if (formData.photo_expertise) {
      setPExpertise(formData.photo_expertise);
    }
    if (formData.video_expertise) {
      setVExpertise(formData.video_expertise);
    }
    if (formData.workEnvironment) {
      setWorkEnvironment(formData.workEnvironment);
    }
    if (formData.address) {
      setAddress(formData.address);
      setRegion(formData.region);
    }
  }, [formData]);

  // useEffect(() => {
  //   if (formData.photo_expertise) {
  //     setPExpertise(formData.photo_expertise);
  //   }
  // }, [formData]);

  // useEffect(() => {
  //   if (formData.video_expertise) {
  //     setVExpertise(formData.video_expertise);
  //   }
  // }, [formData]);

  // useEffect(() => {
  //   if (formData.workEnvironment) {
  //     setWorkEnvironment(formData.workEnvironment);
  //   }
  // }, [formData]);

  //Used to add key/value to form data
  const addData = useCallback(
    (id, data) => {
      if (hostname === "localhost") {
        console.log(id, data);
      }

      setFormData((prevValue) => {
        return {
          ...prevValue,
          [id]: data,
        };
      });
    },
    [hostname]
  );

  useEffect(() => {
    console.log("Form Data: ", formData);
  }, [formData]);

  // empties every associated if "photographer" or "videographer" are empty
  useEffect(() => {
    if (formData) {
      if (!profession.includes("photographer")) {
        // delete formData.photographerType;
        delete formData.photo_expertise;
        delete formData.photographer_rate;
      }
      if (!profession.includes("videographer")) {
        // delete formData.videographerType;
        delete formData.video_expertise;
        delete formData.videographer_rate;
      }
    }
  }, [profession]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const editRef = doc(db, `profiles/${user}`);
    console.log("Submit pressed", formData);

    try {
      setLoading(true);
      await updateDoc(editRef, {
        ...formData,
        updatedAt: serverTimestamp(),
        user: user,
      });
      toast.success("Your profile has been updated");
      setLoading(false);
      window.location.reload(true);
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <form onSubmit={onSubmit}>
          <div className="w-full md:w-2/3 md:shadow-lg md:pt-2 mx-auto content-center">
            <h3 className="text-center text-lg">
              Tell us a bit about yourself.
            </h3>
            <CreateName
              addData={addData}
              profileDocReady={profileDocReady}
              profile={profile}
            />
            <CreateProfilePhoto
              addData={addData}
              profileDocReady={profileDocReady}
              profile={profile}
            />
            <CreateContact
              addData={addData}
              profile={profile}
              profileDocReady={profileDocReady}
            />
            <CreateProfession
              addData={addData}
              profile={profile}
              profileDocReady={profileDocReady}
              noProfessionError={noProfessionError}
            />
            <CreatePType
              addData={addData}
              profile={profile}
              profileDocReady={profileDocReady}
              workEnvError={workEnvError}
            />
            <div className="md:flex">
              {profession.includes("photographer") && (
                <CreatePExpertise
                  addData={addData}
                  profile={profile}
                  profileDocReady={profileDocReady}
                  expertiseError={expertiseError}
                />
              )}
              {profession.includes("videographer") && (
                <CreateVExpertise
                  addData={addData}
                  profile={profile}
                  profileDocReady={profileDocReady}
                  expertiseError={expertiseError}
                />
              )}
            </div>

            {profession.includes("photographer") && (
              <CreatePhotographerRate
                addData={addData}
                profile={profile}
                profileDocReady={profileDocReady}
              />
            )}
            {profession.includes("videographer") && (
              <CreateVideographerRate
                addData={addData}
                profile={profile}
                profileDocReady={profileDocReady}
              />
            )}
            <CreateProvince
              addData={addData}
              profile={profile}
              profileDocReady={profileDocReady}
            />
            <CreateRegion
              addData={addData}
              profile={profile}
              profileDocReady={profileDocReady}
              addressError={addressError}
            />
            <CreateAboutYou
              addData={addData}
              profile={profile}
              profileDocReady={profileDocReady}
            />
            <CreateProfilePublish
              addData={addData}
              profile={profile}
              profileDocReady={profileDocReady}
            />
            <button
              disabled={
                noProfessionError ||
                expertiseError ||
                workEnvError ||
                addressError
              }
              className={`${
                noProfessionError ||
                expertiseError ||
                workEnvError ||
                addressError
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-orange-500 hover:bg-orange-600 active:bg-orange-700 cursor-pointer"
              } m-4 p-2`}
            >
              {loading ? `Working...` : `Make Changes`}
            </button>
            {noProfessionError && (
              <ul>
                <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                  Please specify if you're a photographer or videographer
                </li>
              </ul>
            )}
            {expertiseError && (
              <ul>
                <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                  Please choose atleast 1 and no more then 3 photo/video areas
                  of expertise
                </li>
              </ul>
            )}
            {workEnvError && (
              <ul>
                <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                  Please define whether you work in studio or on-site
                </li>
              </ul>
            )}
            {addressError && (
              <ul>
                <li className="list-disc text-red-600 font-bold ml-4 mb-4">
                  Select a physical address from the options provided once you
                  start typing
                </li>
              </ul>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
