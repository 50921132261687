import React, { useState } from "react";
// import CreateTeam from "../packageCreateComponents/CreateTeam";
import CreateTeam from "../packageCreateComponents/packageDetailComponents/CreateTeam";
import { BiEdit } from "react-icons/bi";

export default function EditTeam({ handleAddData, handleTeam }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      {handleTeam && (
        <p className="m-4">
          Team Size:{" "}
          <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
            {handleTeam}
          </span>
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>
      )}
      {edit && <CreateTeam handleAddData={handleAddData} />}
    </>
  );
}
