import { useEffect, useState } from "react";

function CreateAboutPackage({ addData, packageDocReady, proPackage }) {
  const maxChar = 500;
  const [data, setData] = useState();
  const [dataId, setDataId] = useState("aboutPackage");

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.description);
    }
  }, [packageDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { value, id } = e.target;
    setData(value);
    setDataId(id);
  };

  return (
    <>
      <div className="m-4">
        <label>
          <p>Describe the package in a few words</p>
          <p className="text-sm text-blue-500">
            Please note: Packages with contact details will not be approved.
          </p>
          <p className="text-xs">{`${maxChar} characters`}</p>
          <textarea
            onChange={onChange}
            id="description"
            placeholder="Short description of what customers can expect"
            required
            maxLength={maxChar}
            value={data}
            rows="4"
            className="caret-orange-400 block p-2.5 w-full md:w-2/3 text-sm text-gray-900 bg-gray-50 border-orange-400 rounded-lg border focus:border-orange-400"
          ></textarea>
        </label>
      </div>
    </>
  );
}

export default CreateAboutPackage;
