import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function FAQAnswer({ addData }) {
  const editorRef = useRef(null);

  return (
    <>
      {" "}
      <Editor
        onEditorChange={(newText) => addData("answer", newText)}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: 300,
          menubar: "file edit insert favs format tools table",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
            "advlist",
            "autolink",
            "link",
            "image",
            "lists",
            "charmap",
            "preview",
            "anchor",
            "pagebreak",
            "searchreplace",
            "wordcount",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "emoticons",
            "template",
            "codesample",
          ],
          font_formats:
            "Metrisch-light = Metrisch-light; metrischmedium = metrischmedium; metrischbook = metrischbook",
          // theme_advanced_fonts:
          // "Metrisch-light = Metrisch-light, sans-serif; metrischmedium = metrischmedium, sans-serif; metrischbook = metrischbook, sans-serif",
          toolbar:
            "undo redo | sizeselect| fontsize | fontfamily |" +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "link image | print preview media fullscreen | removeformat | help",
          menu: {
            favs: { title: "Menu", items: "code" },
          },
          // content_css: "../index.css",
          content_style: `
  @font-face {
    font-family: "metrischmedium";
    src: url("/fonts/Metrisch-Medium-webfont.woff2") format("woff2");
    src: url("/fonts/Metrisch-Medium-webfont.eot?#iefix") format("embedded-opentype"),
      url("/fonts/Metrisch-Medium-webfont.woff2") format("woff2"),
      url("/fonts/Metrisch-Medium-webfont.woff") format("woff"),
      url("/fonts/Metrisch-Medium-webfont.ttf") format("truetype"),
      url("/fonts/Metrisch-Medium-webfont.svg#metrischmedium") format("svg");
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'metrischmedium';
    font-variation-settings: "wght" 350;
    font-size: 16px;
    margin: 2rem;
    line-height: 1.35;
  }
`,
        }}
      />
    </>
  );
}
