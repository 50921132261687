// Software
import React, { useState } from "react";

// Components
// import ImageUpload from "../../portfolioUploadComponents/individualComponents/ImageUpload";
// import ImageUploadSubmit from "../../portfolioUploadComponents/individualComponents/ImageUploadSubmit";
// import EditImageGallery from "../../portfolioUploadComponents/individualComponents/EditImageGallery";

import ImageUpload from "../portfolioUploadComponents/individualComponents/ImageUpload";
import ImageUploadSubmit from "../portfolioUploadComponents/individualComponents/ImageUploadSubmit";
import EditImageGallery from "../portfolioUploadComponents/individualComponents/EditImageGallery";
import ExampleImages from "./studioGalleryComponents/ExampleImages";

// Others
import { useParams } from "react-router-dom";
import Loading from "../Loading";

export default function StudioGallery({ studio }) {
  //identify current user
  const params = useParams();
  const { user } = params;
  const imageFolder = "imagesStudio";
  const maxImages = 30;

  //   const [profile, setProfile] = useState("");

  const [images, setImages] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);

  // useEffect(() => {
  //   console.log("Are the images loading? ", imageUploading);
  // });

  const imageIsLoading = () => {
    setImageUploading(true);
  };

  const imageNotLoading = () => {
    setImageUploading(false);
  };

  const onImageSubmit = async (e) => {
    e.preventDefault();

    ImageUploadSubmit(
      images,
      user,
      imageIsLoading,
      imageNotLoading,
      imageFolder
    );
  };

  return (
    <>
      {studio ? (
        <>
          {imageUploading ? (
            <Loading />
          ) : (
            <>
              <div className="mx-auto w-full md:w-2/3 px-2">
                {/* <h1 className="my-2 text-2xl">Studio Gallery</h1> */}
                <h1>
                  Please upload images that show your studio space -{" "}
                  <span className="italic text-orange-500">
                    props, equipment, amenities and/or you working with a client
                  </span>
                  .
                </h1>
                <p>Images that show what potential customers can expect.</p>
                <p className="">
                  <span className="text-lg text-red-700 font-bold">
                    Please do not
                  </span>{" "}
                  upload images of the final product. Final product images are
                  reserved for your portfolio.
                </p>
                <p className="mt-2">Examples:</p>
                <ExampleImages />
              </div>
              <form onSubmit={onImageSubmit}>
                <ImageUpload setImages={setImages} />
                <button className="block mx-auto mt-4 p-4 border-2 border-orange-400">
                  Upload Images
                </button>
              </form>
              <EditImageGallery
                user={user}
                imageFolder={imageFolder}
                maxImages={maxImages}
              />
            </>
          )}
        </>
      ) : (
        <p className="text-center text-xl m-5">
          Please upload your studio details first
        </p>
      )}
    </>
  );
}
