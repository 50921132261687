import React from "react";

export default function Lighting({ className }) {
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v">
          <g>
            <path d="M35,8h-2v-2c0-1.103-.897-2-2-2h-2c-1.103,0-2,.897-2,2v2h-2c-.553,0-1,.447-1,1h-2c-1.103,0-2,.897-2,2h-4c-2.663,0-5,2.337-5,5s2.337,5,5,5c2.075,0,3,.925,3,3s-.925,3-3,3c-3.178,0-5,1.822-5,5s1.822,5,5,5c2.075,0,3,.925,3,3s-.925,3-3,3c-2.757,0-5,2.243-5,5,0,3.178,1.822,5,5,5,2.075,0,3,.925,3,3s-.925,3-3,3c-3.131,0-5,1.495-5,4,0,.553,.447,1,1,1s1-.447,1-1c0-.495,0-2,3-2,3.178,0,5-1.822,5-5s-1.822-5-5-5c-2.075,0-3-.925-3-3,0-1.654,1.346-3,3-3,3.178,0,5-1.822,5-5s-1.822-5-5-5c-2.075,0-3-.925-3-3s.925-3,3-3c3.178,0,5-1.822,5-5s-1.822-5-5-5c-1.689,0-3-1.612-3-3s1.311-3,3-3h4c0,1.103,.897,2,2,2h2c0,.553,.447,1,1,1h2v2c0,.553,.447,1,1,1h1v14h-1c-.553,0-1,.447-1,1v5c0,.553,.447,1,1,1h1v10.637l-9.769,11.722c-.354,.425-.296,1.056,.128,1.409,.426,.353,1.056,.296,1.409-.128l4.535-5.442,3.697,1.479v3.323c0,.553,.447,1,1,1s1-.447,1-1v-3.323l3.697-1.479,4.535,5.442c.198,.237,.482,.359,.77,.359,.226,0,.452-.076,.64-.231,.424-.354,.481-.984,.128-1.409l-9.769-11.722v-10.637h1c.553,0,1-.447,1-1v-5c0-.553-.447-1-1-1h-1v-14h1c.553,0,1-.447,1-1v-2h2c.553,0,1-.447,1-1v-6c0-.553-.447-1-1-1Zm-8.35,48.583l2.35-2.82v3.76l-2.35-.94Zm6.7,0l-2.35,.94v-3.76l2.35,2.82Zm-2.35-18.583h-2v-3h2v3ZM29,6h2v2h-2v-2Zm-7,7v-2h2v2h-2Zm9,4h-2v-1h2v1Zm3-3h-8v-4h8v4Z" />
            <path d="M49,8V1c0-.553-.447-1-1-1-6.617,0-12,5.383-12,12s5.383,12,12,12c.553,0,1-.447,1-1v-7c2.206,0,4-1.794,4-4s-1.794-4-4-4Zm-11,4c0-4.091,2.474-7.607,6-9.154V21.154c-3.526-1.547-6-5.063-6-9.154Zm9,9.95c-.339-.034-.672-.087-1-.154V2.204c.328-.067,.661-.121,1-.154V21.95Zm2-7.95v-4c1.103,0,2,.897,2,2s-.897,2-2,2Z" />
          </g>
        </g>
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
