import React, { useState, useEffect } from "react";
import { Rating } from "react-simple-star-rating";

export default function ReviewModal({ reviews }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [starSize, setStarSize] = useState("40");
  const breakpoint = 768;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (width < breakpoint) {
      setStarSize("25");
    }
  }, [width, breakpoint]);

  return (
    <>
      {reviews.map((review) => {
        const date = new Date(review.shootDate).toLocaleString("en-us", {
          month: "long",
          year: "numeric",
        });
        return (
          <div key={review.createdAt}>
            <div className="mb-2">
              <div>
                <h1 className="text-2xl font-bold">
                  {review.clientName} - {date}
                </h1>
              </div>
              <div>
                {review.shootTitle} in {review.shootLocation}
              </div>
              {/* <div>{review.shootLocation}</div>
              <div>{review.shootDate}</div> */}
            </div>
            <div className="my-5">
              <div className="grid grid-cols-2 md:grid-cols-5 items-center">
                <div>
                  <h1 className="inline-block">Punctuality</h1>
                </div>
                <div className="col-start-2">
                  <Rating
                    SVGstyle={{ display: "inline" }}
                    size={starSize}
                    initialValue={review.ratingPunctuality}
                    readonly={true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-5 items-center">
                <div>
                  <h1 className="inline-block">Professionalism</h1>
                </div>
                <div className="col-start-2">
                  <Rating
                    SVGstyle={{ display: "inline" }}
                    size={starSize}
                    initialValue={review.ratingProfessionalism}
                    readonly={true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-5 items-center">
                <div>
                  <h1 className="inline-block">Final Product</h1>
                </div>
                <div className="col-start-2">
                  <Rating
                    SVGstyle={{ display: "inline" }}
                    size={starSize}
                    initialValue={review.ratingFinalProduct}
                    readonly={true}
                  />
                </div>
              </div>
            </div>
            {review.commentsPublic ? (
              <p>{review.commentsPublic}</p>
            ) : (
              <p className="text-gray-400 text-xs">
                (no comment left by reviewer)
              </p>
            )}
            <hr className="h-px my-2 border-0 bg-gray-700"></hr>
          </div>
        );
      })}
    </>
  );
}
