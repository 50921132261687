import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

//other components
import EditDetails from "../components/profileEditComponents/EditDetails";
import EditDiscounts from "../components/profileEditComponents/EditDiscounts";
// import EditStudio from "../components/profileEditComponents/EditStudio";
import EditTravel from "../components/profileEditComponents/EditTravel";
import Tutorials from "../components/profileEditComponents/Tutorials";

//image icons
import { CgDetailsMore } from "react-icons/cg";
import { AiTwotoneCar } from "react-icons/ai";
import { MdDiscount } from "react-icons/md";
// import { AiTwotoneShop } from "react-icons/ai";
import { useParams } from "react-router-dom";

export default function ProfileEdit() {
  const [details, setDetails] = useState(true);
  const [discounts, setDiscounts] = useState(false);
  // const [studio, setStudio] = useState(false);
  const [travel, setTravel] = useState(false);

  const [profile, setProfile] = useState();
  const [profileDocReady, setProfileDocReady] = useState(false);

  const params = useParams();
  const { user } = params;

  useEffect(() => {
    const docRef = doc(db, "profiles", user);
    getDoc(docRef).then((snapshot) => {
      setProfile(snapshot.data());
      setProfileDocReady(true);
    });
  }, [user]);

  useEffect(() => {
    console.log("Profile: ", profile);
  });

  const onDetailsClick = () => {
    setDetails(true);
    setDiscounts(false);
    setTravel(false);
  };

  const onDiscountsClick = () => {
    setDiscounts(true);
    setDetails(false);
    setTravel(false);
  };

  const onTransportClick = () => {
    setTravel(true);
    setDetails(false);
    setDiscounts(false);
  };

  return (
    <div className="w-full mx-auto px-1">
      <h1 className="text-center mt-10 text-2xl">Edit Your Profile</h1>
      <div className="w-full inline-flex justify-center py-4">
        <button
          onClick={onDetailsClick}
          className={`${
            details
              ? "bg-orange-400 hover:bg-orange-500"
              : "bg-white hover:bg-gray-200"
          }  text-gray-800 flex font-bold py-2 lg:px-4 px-1 border border-gray-200 rounded-l-lg transition-all`}
        >
          {" "}
          Details{" "}
          <CgDetailsMore className="hidden lg:inline-block align-middle text-2xl ml-1" />
        </button>

        <button
          onClick={onTransportClick}
          className={`${
            travel
              ? "bg-orange-400 hover:bg-orange-500"
              : "bg-white hover:bg-gray-200"
          }  text-gray-800 font-bold py-2 lg:px-4 px-1 border border-gray-200 transition-all`}
        >
          Travel
          <AiTwotoneCar className="hidden lg:inline-block align-middle text-2xl ml-1" />
        </button>
        <button
          onClick={onDiscountsClick}
          className={`${
            discounts
              ? "bg-orange-400 hover:bg-orange-500"
              : "bg-white hover:bg-gray-200"
          }  text-gray-800 font-bold py-2 lg:px-4 px-1 border border-gray-200 rounded-r-lg transition-all`}
        >
          Discounts
          <MdDiscount className="hidden lg:inline-block align-middle text-2xl ml-1" />
        </button>
      </div>
      <Tutorials />
      {details && (
        <EditDetails profile={profile} profileDocReady={profileDocReady} />
      )}
      {travel && <EditTravel profile={profile} />}
      {discounts && <EditDiscounts profile={profile} />}
    </div>
  );
}
