import ActionCamera from "../equipmentIcons/ActionCamera";
import CellphoneCam from "../equipmentIcons/CellphoneCam";
import Drone from "../equipmentIcons/Drone";
import Dslr from "../equipmentIcons/Dslr";
import Mirrorlesscam from "../equipmentIcons/Mirrorlesscam";

export const photoCamsArray = (className) => [
  {
    id: "photoCams",
    title: "DSLR Camera",
    value: "DSLRCamera",
    type: "checkbox",
    icon: <Dslr className={className} />,
  },
  {
    id: "photoCams",
    title: "Mirrorless Camera",
    value: "mirrorlessCamera",
    type: "checkbox",
    icon: <Mirrorlesscam className={className} />,
  },
  {
    id: "photoCams",
    title: "Action Camera",
    value: "actionCamera",
    type: "checkbox",
    icon: <ActionCamera className={className} />,
  },
  {
    id: "photoCams",
    title: "Cellphone Camera",
    value: "cellphoneCamera",
    type: "checkbox",
    icon: <CellphoneCam className={className} />,
  },
  {
    id: "photoCams",
    title: "Photography Drone",
    value: "photoDrone",
    type: "checkbox",
    icon: <Drone className={className} />,
  },

  //   {
  //     id: "primary_photo",
  //     title: "Professional Video Camera",
  //     value: "video_camera",
  //     type: "checkbox",
  //   },
];
