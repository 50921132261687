import { useEffect, useState } from "react";

function CreatePackageName({ addData, proPackage, packageDocReady }) {
  const [data, setData] = useState();
  const [dataId, setDataId] = useState("name");

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.name);
      console.log("Propackage name: ", proPackage.name);
    }
  }, [packageDocReady]);

  useEffect(() => {
    console.log("Data: ", data);
    console.log("is the Package Ready: ", packageDocReady);
    // console.log("ProPackage: ", proPackage.name);
  }, [data, packageDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { value, id } = e.target;
    setData(value);
    setDataId(id);

    // addData(id, value);
  };

  return (
    <>
      <div onChange={onChange} className="m-4 md:w-1/2 w-full">
        <label>
          <span>Package Name:</span>

          <input
            type="text"
            required
            value={data}
            id="name"
            name="name"
            className="w-1/2 border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
          />
          <p className="mb-5 mt-2 text-sm text-blue-500">
            Tip: Choose a name that describes the photoshoot rather than you or
            your studio. E.g. Vintage Themed Family Photoshoot or Wedding
            Photography and Drone Footage.
          </p>
        </label>
      </div>
    </>
  );
}

export default CreatePackageName;
