export const professionArray = [
  {
    id: "photographer",
    title: "Photographer",
    value: "photographer",
    type: "checkbox",
  },
  {
    id: "videographer",
    title: "Videographer",
    value: "videographer",
    type: "checkbox",
  },
];
