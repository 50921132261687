import { useState } from "react";
import CreatePrice from "../packageCreateComponents/packageDetailComponents/CreatePrice";
import { BiEdit } from "react-icons/bi";

export default function EditPrice({ handleAddData, handlePrice }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      {handlePrice && (
        <p className="m-4">
          Photographer rate/hour:{" "}
          <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
            {handlePrice}
          </span>
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>
      )}
      {edit && <CreatePrice handleAddData={handleAddData} />}
    </>
  );
}
