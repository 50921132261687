import React from "react";

export default function ProTestimonials() {
  const testimonial = [
    {
      name: "Mo Matli",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,f-auto/MoMatli.jpeg?updatedAt=1697274693035",
      title: "Photographer and Videographer",
      company: "Mo Matli Visuals",
      text: "The BraThula Network is a brilliant service offering that connects you to clients you wouldn't have access to. The platform guarantees a simplified process boasting a highly communicative and transparent team. The team informs you exactly when the shoot is and where you should be, as well as the payment structure beforehand.",
    },
    {
      name: "Lindo Khumalo",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,f-auto/profilephoto.jpg?updatedAt=1697272843866",
      title: "Photographer",
      company: "CEO - The Blacklist ZA",
      text: "BraThula is an exceptional initiative and service that not only makes the lives of photographers easy but brings a professional edge to clients too, absolutely love it!!",
    },
    {
      name: "Kabelo Mathiane",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,f-auto/tempFileForShare_20230612-225201.jpg?updatedAt=1697272627269",
      title: "Photographer and Videographer",
      company: "Booga Projects",
      text: "As a freelancer, the BraThula platform has allowed me to get more clients and leads, with me not having to search for clients. It gives you an essence of peace knowing that your marketed on other platforms rather than your own. Also, You don't have to deal with the client, BraThula facilitates all communication, logistics and payments.",
    },
    {
      name: "Karabo Mphahlele",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,q-80,f-auto/IMG_9317-min.jpeg?updatedAt=1697274417450",
      title: "Photographer and Videographer",
      company: "Iconic Photography",
      text: "Its great to get more business. I would recommend the platform as a way to broaden your visibility as a photographer. You have nothing to lose.",
    },
  ];

  return (
    <>
      <div className="my-5 md:my-20">
        <h1 className="text-center text-2xl md:text-3xl font-light">
          Our Photographers and Videographers
        </h1>
        <div className="w-full h-fit grid grid-cols-1 grid-rows-4 md:grid-cols-2 md:grid-rows-2 gap-4">
          {testimonial.map((item) => {
            return (
              <div key={item.name} className="bg-orange-50 p-2 shadow rounded">
                <img
                  className="p-1 mx-auto ring-2 ring-orange-400 rounded-full block h-32 w-32 object-cover object-center"
                  src={item.src}
                  alt={item.name}
                />
                <h1 className="text-center text-xl font-light">{item.name}</h1>
                {/* <h2 className="text-center font-light">{item.title}</h2> */}
                <h6 className="text-sm text-center">{item.company}</h6>
                <blockquote className="text-6xl font-bold">
                  <p className="mx-auto text-center text-sm px-1 w-full md:w-4/5 font-light">
                    {`"${item.text}"`}
                  </p>
                </blockquote>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
