// import { useState } from "react";
import { useDropzone } from "react-dropzone";

export default function ImageUpload({ setImages }) {
  const maxImages = 10;
  const maxImageSize = 3;

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: { "image/jpg": [".jpeg", ".jpg"], "img/png": [".png"] },
      maxFiles: maxImages,
      multiple: true,
      maxSize: maxImageSize * 1000000,
      onDrop: (acceptedFiles, fileRejections) => {
        console.log("Accepted Files: ", acceptedFiles);
        console.log("Rejected Files: ", fileRejections);
        setImages(acceptedFiles);
      },
    });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {(file.size / 1000000).toFixed(1)} MB
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {(file.size / 1000000).toFixed(1)} MB
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  return (
    <>
      <section className="w-11/12 sm:w-1/2 mx-auto mt-10 border border-slate-600 border-dashed rounded">
        <div
          {...getRootProps({
            className:
              "w-full p-10 bg-slate-300 border-slate-400 border-solid cursor-pointer",
          })}
        >
          <input {...getInputProps()} />
          <p>
            Drag 'n' drop <b>images</b> here, or click to select files
          </p>
          <em className="text-sm">
            (Up to {maxImages} images at a time of {maxImageSize}MB file size)
          </em>
        </div>
        <aside>
          {acceptedFileItems.length > 0 && (
            <h4 className="text-green-600 text-lg">Accepted Files</h4>
          )}
          <ul>{acceptedFileItems}</ul>
          {fileRejectionItems.length > 0 && (
            <h4 className="text-red-600 text-lg">Rejected Files</h4>
          )}
          <ul>{fileRejectionItems}</ul>
        </aside>
      </section>
    </>
  );
}
