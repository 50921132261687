import { useEffect, useState } from "react";
import { storage, auth } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
// import { useParams } from "react-router-dom";
// import logo from "../../assets/logo.png";
import logo from "../../../assets/logo.png";

export default function CreateProfilePhoto({
  addData,
  profile,
  profileDocReady,
}) {
  const user = auth.currentUser;

  const hostname = window.location.hostname;

  const [image, setImage] = useState();
  const [url, setUrl] = useState(logo);
  const [imageID, setImageID] = useState("profileImageURL");
  const [isLoading, setIsLoading] = useState(false);

  // const [originUrl, setOriginUrl] = useState();

  //To validate image size is less than 3MB
  const [largeImage, setLargeImage] = useState(false);

  //To validate image is there before upload
  const [imageMissing, setImageMissing] = useState();

  useEffect(() => {
    if (profileDocReady && profile.profileImageURL) {
      setUrl(profile.profileImageURL);
    }
  }, [profileDocReady]);

  useEffect(() => {
    if (url === logo) {
      addData(imageID, "");
    } else {
      console.log("No more placeholder");
      addData(imageID, url);
    }
    // const work = handleAddData(imageID, url);
    // console.log(imageID, url);
    // return work;
  }, [imageID, url, addData]);

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      if (e.target.files[0].size > 1000000) {
        setLargeImage(true);
        return;
      } else {
        setLargeImage(false);
        setImage(e.target.files[0]);
      }
    }
    setImageID(e.target.id);
  };

  const handleSubmit = () => {
    // console.log("request.auth.uid", request.auth.uid);
    // console.log("userId", userId);

    if (image) {
      setIsLoading(true);
      setImageMissing(false);
      const imageRef = ref(
        storage,
        "profileImage/" + user?.uid + "/profilePhoto"
      );
      uploadBytes(imageRef, image)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              if (url) {
                setUrl(
                  url.replace("https://firebasestorage.googleapis.com", "")
                );
              } else {
                setUrl();
              }
            })
            .catch((error) => {
              console.log(error.message, "download URL catch");
              toast.error("Something went wrong");
              setIsLoading(false);
            });
          setImage(null);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error.message, "upload bytes URL");
          toast.error(error.message);
          setIsLoading(false);
        });
    } else {
      setImageMissing(true);
    }
    console.log(url);
  };

  return (
    <div className="m-5">
      <h3>Profile Picture</h3>
      {largeImage && (
        <p className="text-red-600 text-xs">Image must be 1MB or less</p>
      )}
      {imageMissing && (
        <p className="text-red-600 text-xs">
          Please attach image before upload
        </p>
      )}
      {hostname === "localhost" ? (
        <img
          className="w-20 h-20 rounded-full ring-2 ring-orange-400 p-1 m-2"
          src={url}
          alt="Profile"
        />
      ) : (
        <img
          className="w-20 h-20 rounded-full ring-2 ring-orange-400 p-1 m-2"
          src={
            url === logo
              ? logo
              : `https://ik.imagekit.io/ajuaxvsrja/tr:fo-auto,w-400,h-400,q-20/${url}`
          }
          alt="Profile"
        />
      )}

      <input
        type="file"
        onChange={handleImageChange}
        id="profileImageURL"
        accept="image/png, image/jpeg"
        data-testid="upload-prompt"
      />
      <button
        type="button"
        className={`${
          largeImage ? "cursor-not-allowed" : "hover:border-orange-400"
        } border-2 p-2`}
        onClick={handleSubmit}
        disable={largeImage}
      >
        {isLoading ? "Busy..." : "Upload Image"}
      </button>
    </div>
  );
}
