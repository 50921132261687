//Icons
import { useEffect, useState } from "react";
import { ImLocation } from "react-icons/im";
import { BsFillCameraFill, BsFillCameraReelsFill } from "react-icons/bs";

// Other Components
import EnquiryForm from "./EnquiryForm";
import ProBookingForm from "./ProBookingForm";
import PackageListButton from "./components/PackageListButton";
import ReviewProfile from "./ReviewProfile";

import avatar from "../../assets/Avatar/photo.svg";

export default function ProfileDetails({ profile }) {
  const [hide, setHide] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const breakPoint = 768;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    if (width < breakPoint) {
      setHide(true);
    }

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <>
      {profile && (
        <>
          <div className="ml-2 w-11/12 md:w-1/5 bg-gray-200 border">
            <div className="h-48 p-2">
              <img
                // src={`https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/${profile.profileImageURL}`}
                src={
                  profile.profileImageURL ===
                    "/static/media/logo.7e36e6aeac51429ea175.png" ||
                  profile.profileImageURL === ""
                    ? avatar
                    : `https://ik.imagekit.io/ajuaxvsrja/tr:q-10,f-auto/${profile.profileImageURL}`
                }
                alt={`${profile.name} profile`}
                className="p-1 mx-auto ring-2 ring-orange-400 rounded-full block h-32 w-32 object-cover object-center"
              />
              <h2 className="text-center">{profile.name}</h2>
            </div>
            <ReviewProfile profile={profile} />

            {profile.images > 0 && profile.photographer_rate && (
              <div className="text-center my-2">
                <p>Photographer Rates</p>
                <p className="font-bold">R{profile.photographer_rate}/hour</p>
              </div>
            )}
            {profile.videos > 0 && profile.videographer_rate && (
              <div className="text-center my-2">
                <p>Videographer Rates</p>
                <p className="font-bold">R{profile.videographer_rate}/hour</p>
              </div>
            )}

            <div className="place-content-center items-center">
              <ProBookingForm profile={profile} />
              <PackageListButton profile={profile} />
              <EnquiryForm profile={profile} />

              {/* <button
                onClick={() => console.log("Let me view Portfolio")}
                className="min-w-min block mx-auto my-2 p-2 border rounded-md shadow text-white text-lg sm:text-xl bg-blue-700 hover:bg-blue-800 active:bg-blue-900"
              >
                <GiPaintBrush className="inline-block" />
                Make Enquiry
              </button> */}
            </div>

            <div className="p-5">
              {hide === true ? (
                <div className="flex">
                  <p
                    className="text-orange-400 mx-auto"
                    onClick={() => setHide(false)}
                  >
                    Show More...
                  </p>
                </div>
              ) : (
                <>
                  <div className="text-center mt-5">
                    <ImLocation className="inline" />
                    <p>{profile.province}</p>
                    <p>{profile.region}</p>
                  </div>
                  {profile.images > 0 && profile.photographer && (
                    <div className="content-center text-center mt-5 mb-2 p-2">
                      <BsFillCameraFill
                        title="photographer"
                        className="mx-1 inline cursor-pointer text-black"
                      />
                      <span className="inline mx-1">
                        {profile.photographer}
                      </span>
                    </div>
                  )}
                  <ul>
                    {profile.images > 0 &&
                      profile.photo_expertise &&
                      profile.photo_expertise.map((item) => {
                        const searchReplace = item.replace("_", " ");
                        return (
                          <div key={item} className="text-center py-1">
                            <li
                              key={item}
                              className="lowercase inline rounded-md px-2 py-1 content-center text-sm text-white bg-black"
                            >
                              {searchReplace}
                            </li>
                          </div>
                        );
                      })}
                  </ul>

                  {profile.videos > 0 && profile.videographer && (
                    <div className="content-center text-center my-5 p-2">
                      <BsFillCameraReelsFill
                        title="videographer"
                        className="mx-1 inline cursor-pointer text-white"
                      />
                      <span className="inline mx-1">
                        {profile.videographer}
                      </span>
                    </div>
                  )}

                  {profile.videos > 0 &&
                    profile.video_expertise &&
                    profile.video_expertise.map((item) => {
                      return (
                        <ul key={item}>
                          <div className="text-center py-1" key={item}>
                            <li
                              key={item}
                              className="lowercase inline rounded-md px-2 py-1 content-center text-sm text-black bg-white"
                            >
                              {item}
                            </li>
                          </div>
                        </ul>
                      );
                    })}

                  <div className="my-5 m-1">
                    <h4 className="underline decoration-double">
                      a bit about {profile.name}
                    </h4>
                    <p className="text-justify">{profile.aboutyou}</p>
                  </div>
                  {width < breakPoint && hide === false ? (
                    <div className="flex">
                      <p
                        className="text-orange-400 mx-auto"
                        onClick={() => setHide(true)}
                      >
                        Show Less
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
