import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

import ProfileDetails from "../components/portfolioPageComponents/ProfileDetails";
import MultiMedia from "../components/portfolioPageComponents/MultiMedia";

export default function Portfolio() {
  // const hostname = window.location.hostname;
  const params = useParams();
  const { user } = params;

  const [profile, setProfile] = useState();
  const [publish, setPublish] = useState(true);

  useEffect(() => {
    const docRef = doc(db, "profiles", user);
    getDoc(docRef).then((snapshot) => {
      setProfile(snapshot.data());
    });
  }, [user]);

  useEffect(() => {
    if (profile) {
      if (profile.userPublish === "true" && profile.adminPublish === "true") {
        setPublish("true");
      } else {
        setPublish("false");
      }
    }
  }, [profile]);

  // useEffect(() => {
  //   console.log("Published Status: ", publish);
  //   console.log("Profile: ", profile);
  //   console.log("User: ", profile.user);
  // }, [publish, profile]);

  return (
    <>
      {profile ? (
        publish === "true" ? (
          <>
            <h1 className="text-lg text-center text-orange-400 bg-black">
              Portfolio
            </h1>
            <div className="relative md:flex bg-black pt-2">
              <ProfileDetails profile={profile} />
              <MultiMedia profile={profile} />
            </div>
          </>
        ) : (
          <p>This user profile is Inactive.</p>
        )
      ) : (
        <div className="flex">
          <p className="text-orange-400 text-2xl mx-auto">Working...</p>
        </div>
      )}
    </>
  );
}
