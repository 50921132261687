import React from "react";

export default function Battery({ className }) {
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o" />
        <g id="p" />
        <g id="q">
          <g>
            <path d="M27,4h-4V1c0-.552-.447-1-1-1h-6c-.553,0-1,.448-1,1v3h-4c-1.654,0-3,1.346-3,3V59c0,1.654,1.346,3,3,3v2H27v-2c1.654,0,3-1.346,3-3V7c0-1.654-1.346-3-3-3Zm-10-2h4v2h-4V2Zm-7,5c0-.551,.448-1,1-1H27c.552,0,1,.449,1,1v11H10V7Zm18,13v26H10V20H28Zm0,39c0,.552-.448,1-1,1H11c-.552,0-1-.448-1-1v-11H28v11Z" />
            <path d="M19,7c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm0,8c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Z" />
            <polygon points="20 10 18 10 18 11 17 11 17 13 18 13 18 14 20 14 20 13 21 13 21 11 20 11 20 10" />
            <path d="M19,59c2.757,0,5-2.243,5-5s-2.243-5-5-5-5,2.243-5,5,2.243,5,5,5Zm0-8c1.654,0,3,1.346,3,3s-1.346,3-3,3-3-1.346-3-3,1.346-3,3-3Z" />
            <rect x="17" y="53" width="4" height="2" />
            <rect x="14" y="23" width="2" height="20" />
            <rect x="18" y="23" width="2" height="16" />
            <rect x="18" y="41" width="2" height="2" />
            <rect x="22" y="27" width="2" height="16" />
            <rect x="22" y="23" width="2" height="2" />
            <path d="M53,2V0h-16V2c-1.654,0-3,1.346-3,3V57c0,1.654,1.346,3,3,3h4v3c0,.553,.447,1,1,1h6c.553,0,1-.447,1-1v-3h4c1.654,0,3-1.346,3-3V5c0-1.654-1.346-3-3-3Zm-17,3c0-.551,.448-1,1-1h16c.552,0,1,.449,1,1v11h-18V5Zm18,13v26h-18V18h18Zm-7,44h-4v-2h4v2Zm7-5c0,.552-.448,1-1,1h-16c-.552,0-1-.448-1-1v-11h18v11Z" />
            <path d="M45,57c2.757,0,5-2.243,5-5s-2.243-5-5-5-5,2.243-5,5,2.243,5,5,5Zm0-8c1.654,0,3,1.346,3,3s-1.346,3-3,3-3-1.346-3-3,1.346-3,3-3Z" />
            <polygon points="44 54 46 54 46 53 47 53 47 51 46 51 46 50 44 50 44 51 43 51 43 53 44 53 44 54" />
            <path d="M45,5c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm0,8c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Z" />
            <rect x="43" y="9" width="4" height="2" />
            <rect x="48" y="21" width="2" height="20" />
            <rect x="44" y="25" width="2" height="16" />
            <rect x="44" y="21" width="2" height="2" />
            <rect x="40" y="21" width="2" height="16" />
            <rect x="40" y="39" width="2" height="2" />
          </g>
        </g>
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
