import { ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import { db, storage } from "../../../firebase";
import { BsTrash } from "react-icons/bs";
import { toast } from "react-toastify";
import {
  collection,
  deleteDoc,
  where,
  query,
  getDocs,
} from "firebase/firestore";

import ConfirmModal from "./ConfirmModal";

export default function EditVideoGallery({ user }) {
  const videoListRef = useRef(ref(storage, `videos/${user}`));
  const [videoList, setVideoList] = useState([]);

  const [hidden, setHidden] = useState(true);
  const [continueDelete, setContinueDelete] = useState(false);
  const [id, setID] = useState();

  const term = "video";

  useEffect(() => {
    listAll(videoListRef.current).then(async (response) => {
      const promises = response.items.map((item) => getDownloadURL(item));
      const urls = await Promise.all(promises);
      setVideoList(urls);
    });
  });

  useEffect(() => {
    console.log("All Videos: ", videoList.length);
  }, [videoList.length]);

  useEffect(() => {
    const deleteData = async () => {
      const deleteRef = ref(storage, id);
      console.log("Continue with process:", deleteRef);
      await deleteObject(deleteRef)
        .then(() => {
          console.log("Item was deleted from Firebase Storage", deleteRef);
        })
        .catch((error) => {
          console.log(error.message);
          toast.error(error.message);
        });

      try {
        const videoDelete = collection(db, "video");
        const q = query(videoDelete, where("videoURL", "==", id));
        const snaps = await getDocs(q);
        snaps.docs.forEach(async (doc) => {
          await deleteDoc(doc.ref);
          console.log("Deleted Doc: ", doc.ref.id);
        });
        console.log("All documents deleted");
        window.location.reload(true);
      } catch (error) {
        console.log(error.message);
        setContinueDelete(false);
      }
      setContinueDelete(false);
    };

    if (continueDelete === true) {
      deleteData();
    }

    return () => {
      console.log("cleanup");
    };
  }, [continueDelete, id]);

  const onDelete = (e) => {
    setContinueDelete(false);
    const { id } = e.target;
    console.log("onClick Url: ", id);

    setID(id);
    setHidden(false);
  };

  const toggleConfirm = () => {
    setHidden(!hidden);
  };

  const confirmDelete = () => {
    setContinueDelete(true);
  };

  return (
    <>
      {!hidden && (
        <ConfirmModal
          toggleConfirm={toggleConfirm}
          confirmDelete={confirmDelete}
          term={term}
        />
      )}
      <div className="w-full sm:w-4/5 container px-2 mx-auto pt-1 m-10">
        <div className="-m-1 flex flex-wrap md:-m-2">
          {videoList.map((url, index) => {
            // console.log(url, index);
            const videoURL = url.replace(
              `https://firebasestorage.googleapis.com`,
              ``
            );
            return (
              <div
                key={url}
                className="relative flex w-full md:w-1/4 sm:w-1/2 flex-wrap"
              >
                <div className="w-full p-1">
                  {/* <img
                      src={`https://ik.imagekit.io/ajuaxvsrja/tr:q-50/${imageURL}`}
                      alt={`portfolio-${index + 1}`}
                    /> */}
                  <video
                    src={`https://ik.imagekit.io/ajuaxvsrja/${videoURL}`}
                    preload="metadata"
                    volume="true"
                    controls="controls"
                    controlsList="nodownload"
                    //   ref={(el) => vidRef.current.push(el)}
                  />
                  <BsTrash
                    title="delete"
                    className="h-8 w-8 rounded-full bg-transparent absolute right-2 top-2 z-5 p-2 m-1 ring ring-red-600 text-red-600 text hover:ring-red-800 hover:text-red-800 cursor-pointer"
                    onClick={onDelete}
                    id={url}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
