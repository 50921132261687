import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  where,
  getDocs,
  doc,
  getDoc,
  query,
} from "firebase/firestore";
import { db } from "../firebase";

// React Icons
// import { FaArrowRight } from "react-icons/fa";
// import { FaArrowLeft } from "react-icons/fa";

// Components
import StudioDetails from "../components/studioEditComponents/StudioDetails";
import StudioGallery from "../components/studioEditComponents/StudioGallery";

export default function StudioEdit() {
  const params = useParams();
  const { user } = params;

  const [studio, setStudio] = useState("");
  const [studioId, setStudioId] = useState("");
  const [profile, setProfile] = useState();

  const [details, setDetails] = useState(true);
  const [gallery, setGallery] = useState(false);

  const [docReady, setDocReady] = useState(false);

  useEffect(() => {
    // console.log("User: ", user);
    try {
      const docRef = doc(db, "profiles", user);
      getDoc(docRef).then((snapshot) => {
        setProfile(snapshot.data());
        console.log("Profile Pulling: ", snapshot.data());
      });
      console.log("Profile: ", profile);
    } catch (error) {
      console.log(error.message);
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      const studiosRef = collection(db, "studios");
      const q = query(studiosRef, where("user", "==", user));
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      const docIds = snapshots.docs.map((doc) => doc.id);
      setStudio(docs[0]);
      setStudioId(docIds[0]);
      setDocReady(true);
      console.log("pulling from database");
    })();
  }, [user]);

  const onToDetails = () => {
    setDetails(true);
    setGallery(false);
  };

  const onToGallery = () => {
    setGallery(true);
    setDetails(false);
  };

  useEffect(() => {
    console.log("studio: ", studio);
  }, [studio]);

  useEffect(() => {
    console.log("profile: ", profile);
  }, [profile]);

  return (
    <>
      {profile?.workEnvironment?.includes("studio") ? (
        <>
          <div className="flex flex-row items-center justify-center my-5">
            <button
              onClick={onToDetails}
              className={`${
                details
                  ? "bg-orange-400 hover:bg-orange-500 active:bg-active-600"
                  : "bg-white hover:bg-gray-200"
              }  text-gray-800 flex font-bold py-2 lg:px-4 border border-gray-200 rounded-l-lg transition-all`}
            >
              {/* <FaArrowLeft className="inline text-3xl pl-1" />  */}
              Studio Details
            </button>
            <button
              className={`${
                gallery
                  ? "bg-orange-400 hover:bg-orange-500 active:bg-active-600"
                  : "bg-white hover:bg-gray-200"
              } text-gray-800 flex font-bold py-2 lg:px-4 border border-gray-200 rounded-r-lg transition-all`}
              onClick={onToGallery}
            >
              Studio Gallery
              {/* <FaArrowRight className="inline text-3xl pl-1" /> */}
            </button>
          </div>
          {details && (
            <StudioDetails
              studioId={studioId}
              studio={studio}
              profile={profile}
              docReady={docReady}
            />
          )}
          {gallery && <StudioGallery studio={studio} profile={profile} />}
        </>
      ) : (
        <div className="w-full">
          <p className="my-10 text-center">
            This feature is only reserved for photographers with studios.
          </p>
        </div>
      )}
    </>
  );
}
