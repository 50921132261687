export const servicesArray = [
  {
    id: "services",
    value: "photography",
    title: "Photography",
    type: "checkbox",
    name: "services",
  },
  {
    id: "services",
    value: "videography",
    title: "Videography",
    type: "checkbox",
    name: "services",
  },
];
