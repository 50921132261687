export const workEnvironment = [
  {
    id: "workEnvironment",
    title: "Studio",
    value: "studio",
    type: "checkbox",
  },
  {
    id: "workEnvironment",
    title: "On Site/Location",
    value: "onSite",
    type: "checkbox",
  },
];
