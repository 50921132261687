export const studioAvailabilityArray = [
  {
    id: "availability",
    title: "Weekends",
    value: "weekends",
    type: "radio",
    name: "availability",
  },
  {
    id: "availability",
    title: "Weekdays",
    value: "weekdays",
    type: "radio",
    name: "availability",
  },
  {
    id: "availability",
    title: "7 Days a Week",
    value: "7Days",
    type: "radio",
    name: "availability",
  },
];
