import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import CreateAdvanceNotice from "../packageCreateComponents/packageDetailComponents/CreateAdvanceNotice";

export default function EditAdvanceNotice({
  handleAdvanceNotice,
  handleAddData,
}) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      <div className="flex flex-col">
        <p className="ml-4 mt-4">
          Advance Notice:{" "}
          <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
            {handleAdvanceNotice} hours
          </span>
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>

        {edit && <CreateAdvanceNotice handleAddData={handleAddData} />}
      </div>
    </>
  );
}
