export const exampleImagesArray = [
  {
    title: "white studio with black props",
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/StudioImages/photo-studio-with-digital-photo-camera-on-tripod-2023-11-27-05-33-05-utc.jpeg?updatedAt=1718429838132",
  },
  {
    title: "photographer with model",
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/StudioImages/professional-photographer-and-beautiful-model-on-f-2023-11-27-05-30-23-utc.jpeg?updatedAt=1718429840841",
  },
  {
    title: "Yellow Backdrop",
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/StudioImages/photographing-studio-with-light-set-up-modern-lig-2023-11-27-05-36-57-utc.jpeg?updatedAt=1718429835943",
  },
  {
    title: "Product photography shooting a bag",
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/StudioImages/young-man-content-manager-standing-on-ladder-and-t-2023-11-27-05-15-58-utc.jpeg?updatedAt=1718429835651",
  },
  {
    title: "Flower photographer",
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/StudioImages/professional-young-photographer-working-with-camer-2023-11-27-05-10-50-utc.jpeg?updatedAt=1718429838657",
  },
  {
    title: "Art photography",
    image:
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/StudioImages/digital-camera-woman-or-art-painting-photograph-f-2023-11-27-05-17-02-utc.jpeg?updatedAt=1718429830642",
  },
];
