import React, { useEffect, useState } from "react";

function CreateAdvanceNotice({ addData, packageDocReady, proPackage }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("advanceNotice");

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.advanceNotice);
    }
  }, [packageDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setData(value);
    setDataId(id);

    // addData(id, value);
  };
  return (
    <>
      <div className="flex items-center m-4">
        <h3>Advance notice to prepare for the shoot</h3>
        <select
          required
          id="advanceNotice"
          name="advanceNotice"
          onChange={onChange}
          className="ml-2 border-orange-400 hover:border-orange-400"
        >
          <option
            value=""
            defaultValue="Please Select"
            selected={data === ""}
            disabled
          >
            Please Select
          </option>
          <option selected={data === "1"} value={1}>
            1 hour
          </option>
          <option selected={data === "6"} value={6}>
            6 hours
          </option>
          <option selected={data === "12"} value={12}>
            12 hours
          </option>
          <option selected={data === "24"} value={24}>
            1 day
          </option>
          <option selected={data === "72"} value={72}>
            3 days
          </option>
          <option selected={data === "168"} value={168}>
            7 days
          </option>
        </select>
      </div>
    </>
  );
}

export default CreateAdvanceNotice;
