import React from "react";
import { Rating } from "react-simple-star-rating";

export default function ProReview({ reviews }) {
  return (
    <>
      {reviews ? (
        <>
          {reviews.map((review) => {
            const date = new Date(review.shootDate).toLocaleString("en-us", {
              month: "long",
              year: "numeric",
            });
            return (
              <div className="w-full" key={review.createdAt}>
                <div className="w-11/12 md:w-2/3 mx-auto">
                  <div>
                    <div>
                      <h1 className="text-2xl font-bold">
                        {review.clientName} - {date}
                      </h1>
                    </div>
                    <div>
                      {review.shootTitle} in {review.shootLocation}
                    </div>
                    {/* <div>{review.shootLocation}</div>
          <div>{review.shootDate}</div> */}
                  </div>
                  <div className="my-5">
                    <div className="grid md:grid-cols-5 items-center">
                      <div>
                        <h1 className="inline-block">Punctuality</h1>
                      </div>
                      <div className="col-start-2">
                        <Rating
                          SVGstyle={{ display: "inline" }}
                          initialValue={review.ratingPunctuality}
                          readonly={true}
                        />
                      </div>
                    </div>
                    <div className="grid md:grid-cols-5 items-center">
                      <div>
                        <h1 className="inline-block">Professionalism</h1>
                      </div>
                      <div className="col-start-2">
                        <Rating
                          SVGstyle={{ display: "inline" }}
                          initialValue={review.ratingProfessionalism}
                          readonly={true}
                        />
                      </div>
                    </div>
                    <div className="grid md:grid-cols-5 items-center">
                      <div>
                        <h1 className="inline-block">Final Product</h1>
                      </div>
                      <div className="col-start-2">
                        <Rating
                          SVGstyle={{ display: "inline" }}
                          initialValue={review.ratingFinalProduct}
                          readonly={true}
                        />
                      </div>
                    </div>
                  </div>{" "}
                  <div>
                    <h1 className="text-xl">
                      Public Comment(the public can see)
                    </h1>
                    {review.commentsPublic ? (
                      <p className="py-2">{review.commentsPublic}</p>
                    ) : (
                      <p className="text-gray-400 text-xs py-2">
                        (no comment left by reviewer)
                      </p>
                    )}
                    <h1 className="text-xl">
                      Private Comments(only you can see)
                    </h1>
                    {review.commentsPrivate ? (
                      <>
                        <p className="py-2">{review.commentsPrivate}</p>
                      </>
                    ) : (
                      <p className="text-gray-400 text-xs py-2">
                        (no comment left by reviewer)
                      </p>
                    )}
                    <div className="my-5">
                      {review.adminPublish === true ? (
                        <h1 className="text-xl font-bold">
                          Review Status:{" "}
                          <span className="p-2 bg-green-600 text-white rounded-sm">
                            Published
                          </span>
                        </h1>
                      ) : (
                        <h1 className="text-xl font-bold">
                          Review Status:{" "}
                          <span className="p-2 bg-red-600 text-white rounded">
                            Unpublished
                          </span>
                        </h1>
                      )}
                    </div>

                    <hr className="h-px my-2 border-0 bg-gray-700"></hr>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <p className="text-center">No Reviews</p>
      )}
    </>
  );
}
