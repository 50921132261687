//software imports
import {
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase";
import { toast } from "react-toastify";

//component imports
import EditAboutPackage from "../components/packageEditComponents/EditAboutPackage";
import EditAdvanceNotice from "../components/packageEditComponents/EditAdvanceNotice";
import EditCancelPolicy from "../components/packageEditComponents/EditCancelPolicy";
import EditEditing from "../components/packageEditComponents/EditEditing";
import EditNoOfImages from "../components/packageEditComponents/EditNoOfImages";
import EditNoOfVideos from "../components/packageEditComponents/EditNoOfVideos";
import EditPackageName from "../components/packageEditComponents/EditPackageName";
import EditPrice from "../components/packageEditComponents/EditPrice";
import EditProductDelivery from "../components/packageEditComponents/EditProductDelivery";
import EditRegion from "../components/packageEditComponents/EditRegion";
import EditServices from "../components/packageEditComponents/EditServices";
import EditShootDuration from "../components/packageEditComponents/EditShootDuration";
import EditVideoDuration from "../components/packageEditComponents/EditVideoDuration";
import EditTeam from "../components/packageEditComponents/EditTeam";
import EditPackagePublish from "../components/packageEditComponents/EditPackagePublish";
import EditPhotoCams from "../components/packageEditComponents/EditPhotoCams";
import EditPhotoEquipment from "../components/packageEditComponents/EditPhotoEquipment";
import EditVideoCams from "../components/packageEditComponents/EditVideoCams";
import EditVideoEquipment from "../components/packageEditComponents/EditVideoEquipment";
import ConfirmModal from "../components/packageEditComponents/deleteComponents/ConfirmModal";

//extra imports
import { BsTrash } from "react-icons/bs";

export default function PackageEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const { docId, user } = params;

  //Delete variables
  const [hidden, setHidden] = useState(true);
  const [continueDelete, setContinueDelete] = useState(false);
  const [deleteID, setDeleteID] = useState();

  //Fetch data from Firestore
  const [proPackage, setProPackage] = useState();

  //Set Data that you want to update
  const [formData, setFormData] = useState([]);

  //Used to add key/value to form data
  const addData = useCallback((id, data) => {
    // console.log("addData: ", id, data);
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  useEffect(() => {
    console.log("formData: ", formData);
  }, [formData]);

  useEffect(() => {
    const docRef = doc(db, "packages", docId);
    getDoc(docRef).then((snapshot) => {
      // console.log("Snapshot: ", snapshot);
      // console.log("Snapshot data:, ", snapshot.data());
      setProPackage(snapshot.data());
    });
  }, [docId]);

  useEffect(() => {
    const deletePackage = async () => {
      try {
        await deleteDoc(doc(db, "packages", docId));
        navigate(`/packages-editable/${user}`);
      } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong, please try again.");
        setContinueDelete(false);
      }
      setContinueDelete(false);
    };

    if (continueDelete === true) {
      deletePackage();
      console.log("Continue Delete is True");
    }
  }, [continueDelete, docId, user, navigate]);

  // useEffect(() => {
  //   console.log("Continue Delete: ", continueDelete);
  // }, [continueDelete]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const editRef = doc(db, `packages/${docId}`);
    console.log("Submit pressed", formData);

    try {
      await updateDoc(editRef, {
        ...formData,
        updatedAt: serverTimestamp(),
      });
      toast.success("Your profile has been updated");
      window.location.reload(true);
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong");
    }
  };

  const onDelete = (e) => {
    // console.log(e.target);
    // console.log(docId);
    setHidden(false);
  };

  const toggleConfirm = () => {
    setHidden(!hidden);
  };

  const confirmDelete = () => {
    setContinueDelete(true);
  };

  // in every field, we read the current state on the database and then we
  // also pass in the function that will handle changes. This is is actually
  // the very same function that was used in the initial package creation except,
  // instead of using create/set a document, it updates the document.
  return (
    <>
      {/* <p>{proPackage.packageName}</p> */}
      {proPackage && (
        <div className="w-full h-full bg-gray-100 p-2">
          <form onSubmit={onSubmit}>
            <div className="relative w-full md:w-4/5 border m-5 p-2 mx-auto content-center bg-white shadow-lg">
              {proPackage.packageName}
              <EditPackageName
                handleAddData={addData}
                handlePackageName={proPackage.packageName}
              />
              <EditServices
                handleAddData={addData}
                handlePhotography={proPackage.photography}
                handleVideography={proPackage.videography}
              />
              {proPackage.photoCams && (
                <EditPhotoCams
                  handleAddData={addData}
                  handlePhotoCams={proPackage.photoCams}
                />
              )}
              {proPackage.photoEquipment && (
                <EditPhotoEquipment
                  handleAddData={addData}
                  handlePhotoEquipment={proPackage.photoEquipment}
                />
              )}
              {proPackage.videoCams && (
                <EditVideoCams
                  handleAddData={addData}
                  handleVideoCams={proPackage.videoCams}
                />
              )}
              {proPackage.videoEquipment && (
                <EditVideoEquipment
                  handleAddData={addData}
                  handleVideoEquipment={proPackage.videoEquipment}
                />
              )}

              <EditRegion
                handleAddData={addData}
                handleRegion={proPackage.regions}
              />
              <EditPrice
                handleAddData={addData}
                handlePrice={proPackage.price}
              />
              <EditAdvanceNotice
                handleAddData={addData}
                handleAdvanceNotice={proPackage.advanceNotice}
              />
              <EditTeam
                handleAddData={addData}
                handleTeam={proPackage.teamSize}
              />
              <EditEditing
                handleAddData={addData}
                handleEdit={proPackage.shootImages}
              />
              <EditShootDuration
                handleAddData={addData}
                handleShootDuration={proPackage.shootHours}
              />
              <EditProductDelivery
                handleAddData={addData}
                handleDelivery={proPackage.delivery_time}
              />
              <EditNoOfImages
                handleAddData={addData}
                handleImageNo={proPackage.noImages}
              />
              <EditNoOfVideos
                handleAddData={addData}
                handleVideoNo={proPackage.noVideos}
              />
              <EditVideoDuration
                handleAddData={addData}
                handleVideoDuration={proPackage.videoDuration}
              />
              <EditCancelPolicy
                handleAddData={addData}
                handleCancel={proPackage.cancellationPolicy}
              />
              <EditAboutPackage
                handleAddData={addData}
                handleAbout={proPackage.aboutPackage}
              />
              <EditPackagePublish
                handleAddData={addData}
                handlePackagePublish={proPackage.userPublish}
              />
              <button
                disabled={!formData}
                className={`${
                  formData
                    ? "cursor-pointer bg-orange-500 hover:bg-orange-600 active:bg-orange-700"
                    : "bg-gray-300 cursor-not-allowed"
                } m-4 p-2 `}
              >
                Make Changes
              </button>
              <BsTrash
                title="delete"
                className="absolute bottom-4 right-8 h-12 w-12 rounded-full bg-transparent z-5 p-2 m-2 ring-4 ring-red-600 text-red-600 text hover:ring-red-800 hover:text-red-800 cursor-pointer"
                onClick={onDelete}
              />
            </div>
          </form>
          {!hidden && (
            <ConfirmModal
              toggleConfirm={toggleConfirm}
              confirmDelete={confirmDelete}
            />
          )}
        </div>
      )}
    </>
  );
}
