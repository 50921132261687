import React from "react";

export default function YoutubeVideo() {
  return (
    <div className="relative w-full h-[500px] my-2">
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src="https://www.youtube.com/embed/nEDLr1n7nEo"
        title="YouTube video player"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}
