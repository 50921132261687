import { useState } from "react";
import CreateAboutPackage from "../packageCreateComponents/packageDetailComponents/CreateAboutPackage";
import { BiEdit } from "react-icons/bi";

export default function EditAboutPackage({ handleAddData, handleAbout }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };
  return (
    <>
      <p className="ml-4">About package:</p>
      <div className="flex items-center">
        <p className="ml-4 w-3/4 bg-blue-400 border-blue-400 border p-1 rounded shadow-lg">
          {handleAbout}
        </p>
        <BiEdit
          onClick={onClick}
          className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
        />
      </div>

      {edit && <CreateAboutPackage handleAddData={handleAddData} />}
    </>
  );
}
