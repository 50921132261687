import { photoCamsArray } from "../../assets/globalArrays/photoCamsArray";

export default function ShowPhotoCams({
  handleData,
  handleIconClass,
  handleContainerClass,
}) {
  return (
    <>
      {handleData.map((item) => {
        const i = photoCamsArray().findIndex((e) => e.value === item);
        const icon = photoCamsArray(handleIconClass)[i].icon;
        const cam = photoCamsArray()[i].value.replace("_", " ");
        return (
          <div key={item} className={handleContainerClass}>
            <span>{icon}</span>
            <span className="capitalize ml-2">{cam}</span>
          </div>
        );
      })}
    </>
  );
}
