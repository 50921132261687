import React from "react";
import { useNavigate } from "react-router-dom";
import ProTestimonials from "../components/homeComponents/ProTestimonials";

export default function BraThulaIs() {
  const navigate = useNavigate();

  const braThulaIsNot = [
    "a photography/videography company where we are trying to employ videographers/photographers.",
    "trying to strip freelances and small businesses of their independance",
    // "a rigid place to do business. By having different cancellation policies",
    "a lead generation company where you pay for leads that may/may not turn into paid work",
    "a subscription service where you pay upfront to use our service",
  ];

  return (
    <div className="bg-gray-100 w-full h-full sm:py-4 flex flex-col justify-center items-center">
      <div className="mx-auto w-full md:w-3/5 bg-white p-2 sm:p-10 drop-shadow rounded-xl">
        <div>
          <h1 className="text-2xl md:text-4xl">
            What BraThula{" "}
            <span className="font-amithen italic text-2xl md:text-4xl font-thin text-red-600 underline underline-offset-8 decoration-1">
              Is Not
            </span>
          </h1>
          <ul className="">
            {braThulaIsNot.map((item) => {
              return (
                <li key={item} className="my-2">
                  <span className="text-xl italic font-amithen text-red-600">
                    Its Not
                  </span>{" "}
                  {item}
                </li>
              );
            })}
          </ul>
        </div>

        <div className="">
          <h1 className="pt-10 text-2xl md:text-4xl">
            What BraThula{" "}
            <span className="font-amithen text-2xl md:text-4xl font-thin text-green-600 underline underline-offset-8 decoration-1">
              Is
            </span>
          </h1>
          <ul className="">
            <li className="my-2">
              BraThula is in the business of making life easier for
              photographers and videographers. Just upload your profile,
              portfolio and packages.
            </li>
            <li className="my-2">Get Exposure</li>
            <li className="my-2">Secure Bookings</li>
            <li className="my-2">Up to 10% transaction fee. </li>
            <li className="font-amithen italic text-3xl text-green-600 font-thin">
              If you don't get bookings, you don't pay.
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-gray-100 w-full flex">
        <button
          onClick={() => navigate("/signup")}
          className="text-white text-2xl mt-5 mx-auto bg-orange-400 p-2 m-5 hover:bg-orange-500 active:bg-orange-600 shadow-lg"
        >
          Sign Up
        </button>
      </div>
      <ProTestimonials />
    </div>
  );
}
