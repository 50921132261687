import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";

import { toast } from "react-toastify";

import PackageDetails from "../components/packageCreateComponents/PackageDetails";
import PackageGallery from "../components/packageCreateComponents/PackageGallery";

export default function PackageCreate() {
  const params = useParams();
  const { user, docId } = params;
  const [details, setDetails] = useState(true);
  const [gallery, setGallery] = useState(false);

  const [profile, setProfile] = useState();

  const [proPackage, setProPackage] = useState();
  const [packageDocReady, setPackageDocReady] = useState(false);

  const [studio, setStudio] = useState("");
  const [studioDocReady, setStudioDocReady] = useState(false);

  const [images, setImages] = useState([]);
  const [imageIds, setImageIds] = useState([]);

  // Pull package doc to prepopulate for editing.
  useEffect(() => {
    if (docId) {
      try {
        const docRef = doc(db, "packages", docId);
        getDoc(docRef).then((snapshot) => {
          setProPackage(snapshot.data());
          setPackageDocReady(true);
        });
      } catch (error) {
        toast.error("Something went wrong");
        console.log(error.message);
      }
    }
  }, [docId]);

  // Pulling studios in order to preset studio values in packages
  useEffect(() => {
    (async () => {
      const studiosRef = collection(db, "studios");
      const q = query(studiosRef, where("user", "==", user));
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      // const docIds = snapshots.docs.map((doc) => doc.id);
      setStudio(docs[0]);
      // setStudioId(docIds[0]);
      setStudioDocReady(true);
      console.log("pulling from database");
    })();
  }, [user]);

  // Pulling profile in order to regulate package values
  useEffect(() => {
    const docRef = doc(db, "profiles", user);
    getDoc(docRef).then((snapshot) => {
      setProfile(snapshot.data());
    });
  }, [user]);

  // Pulling images to link to each packages
  useEffect(() => {
    const colRef = collection(db, "images");
    const q = query(colRef, where("user", "==", user));

    onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs;
      setImageIds(docs.map((doc) => doc.id));
      setImages(docs.map((doc) => doc.data()));
    });
  }, [user]);

  const onToDetails = () => {
    setDetails(true);
    setGallery(false);
  };

  const onToGallery = () => {
    setGallery(true);
    setDetails(false);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-center my-5">
        <button
          onClick={onToDetails}
          className={`${
            details
              ? "bg-orange-400 hover:bg-orange-500 active:bg-active-600"
              : "bg-white hover:bg-gray-200"
          }  text-gray-800 flex font-bold p-2 lg:px-4 border border-gray-200 rounded-l-lg transition-all`}
        >
          {/* <FaArrowLeft className="inline text-3xl pl-1" />  */}
          Create/Edit Package
        </button>
        <button
          className={`${
            gallery
              ? "bg-orange-400 hover:bg-orange-500 active:bg-active-600"
              : "bg-white hover:bg-gray-200"
          } text-gray-800 flex font-bold p-2 lg:px-4 border border-gray-200 rounded-r-lg transition-all`}
          onClick={onToGallery}
        >
          Linked Images
          {/* <FaArrowRight className="inline text-3xl pl-1" /> */}
        </button>
      </div>
      {details && (
        <PackageDetails
          profile={profile}
          proPackage={proPackage}
          packageDocReady={packageDocReady}
          studio={studio}
          studioDocReady={studioDocReady}
        />
      )}
      {gallery &&
        (docId ? (
          <PackageGallery
            docId={docId}
            profile={profile}
            proPackage={proPackage}
            packageDocReady={packageDocReady}
            studio={studio}
            studioDocReady={studioDocReady}
            images={images}
          />
        ) : (
          <p className="ml-4 my-4 text-center">
            Please create your package first then you can link images from your
            portfolio.
          </p>
        ))}
    </>
  );
}
