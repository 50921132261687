import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TiTick } from "react-icons/ti";
import { serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { doc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

export default function PackageGallery({
  images,
  proPackage,
  docId,
  packageDocReady,
}) {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const hostname = window.location.hostname;

  useEffect(() => {
    console.log("Data: ", data);
  }, [data]);

  useEffect(() => {
    if (packageDocReady && proPackage.images) {
      setData(proPackage.images);
    }
  }, [packageDocReady]);

  console.log("Linked Images: ", data);

  const onChange = (e) => {
    const { value, checked } = e.target;
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    console.log("Images to Link: ", data);

    setIsLoading(true);
    try {
      const updateRef = doc(db, "packages", docId);
      await updateDoc(updateRef, {
        images: data,
        imagesUpdatedAt: serverTimestamp(),
      });
      toast.success("Images are linked");
      setIsLoading(false);
      // window.location.reload();
    } catch (error) {
      console.log(error.message);
      toast.error("There was an error updating your package images");
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      <h3 className="text-center">
        Select the images that represent what the customer can expect from a
        final product.
      </h3>
      <form onSubmit={onSubmit} className="relative">
        {" "}
        <div className="w-full sm:w-4/5 container px-2 mx-auto pt-1 m-10">
          <div className="-m-1 flex flex-wrap md:-m-2">
            {images &&
              images.map((image, index) => {
                const imageURL = image.imageURL.replace(
                  `https://storage.googleapis.com/`,
                  ``
                );
                return (
                  <div
                    key={image.imageURL}
                    className="relative flex w-full md:w-1/4 sm:w-1/2 flex-wrap"
                  >
                    <div className="w-full h-full p-1">
                      <label>
                        <input
                          className="hidden w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                          type="checkbox"
                          onChange={onChange}
                          value={image.imageURL}
                          checked={data?.includes(image.imageURL)}
                        />
                        <img
                          className={`${
                            data?.includes(image.imageURL)
                              ? "scale-95 border-green-400 border-2"
                              : "hover:border-orange-400 hover:border-2"
                          } transition-all ease-in-out delay-150`}
                          alt={`portfolio-${index + 1}`}
                          src={
                            hostname === "localhost"
                              ? image.imageURL
                              : `https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/${imageURL}`
                          }
                        />
                        {data?.includes(image.imageURL) && (
                          <TiTick className="transition-all ease-in-out delay-150 text-5xl absolute right-1 top-1 ring-4 ring-green-400 rounded-full text-green-400" />
                        )}
                      </label>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <button className="sticky left-0 bottom-0 w-full text-white text-lg bg-orange-400 hover:bg-orange-500 active:bg-orange-600 p-2 transition-all ease-in-out delay-75">
          Link Images
        </button>
      </form>
    </>
  );
}
