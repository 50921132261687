import { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  // geocodeByPlaceId,
  // getLatLng,
} from "react-places-autocomplete";

export default function CreateRegion({ addData }) {
  const [address, setAddress] = useState("");
  const [coordinate, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState("");
  // const [result, setResult] = useState("");

  useEffect(() => {
    //Used to ensure that there are no duplicates
    // let isSubscribed = true;
    const work = async () => {
      if (region) {
        if (!regions.includes(region)) {
          setRegions((prevValue) => {
            return [...prevValue, region];
          });
          setRegion("");
        }
      }
    };

    work();
  }, [region, regions]);

  useEffect(() => {
    addData("regions", regions);
    // console.log("useEffect Regions: ", regions);
  }, [regions, addData]);

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    // setResult(geocodeByAddress(value));
    // const coordinates = await getLatLng(result[0]);

    const full_region = result[0].formatted_address;
    // const part_region = full_region.substring(0, full_region.indexOf(","));
    let province = "";

    const region_name = result[0].address_components[0].long_name;

    if (result[0].types[0] === "administrative_area_level_1") {
      setRegion(result[0].address_components[0].long_name);
    } else {
      if (result[0].address_components[2].short_name.length <= 3) {
        province = result[0].address_components[2].long_name;
        setRegion(region_name.concat(", ", province));
      } else {
        province = result[0].address_components[3].long_name;
        setRegion(region_name.concat(", ", province));
      }
    }

    setAddress(value);
    setCoordinates(coordinate);

    setAddress("");
  };

  const onDelete = (e) => {
    const value = e.target.getAttribute("value");
    // console.log("Click onDelete: ", value);

    setRegions(regions.filter((item) => item !== value));
  };

  // setRegions(Array.from(new Set(regions)));
  // console.log("Regions: ", regions);

  return (
    <>
      <div className="m-4">
        <p>Regions</p>
        <p className="w-1/2 text-xs">
          suburb(s), township(s), city(ies), province(s) or any combination that
          your package covers without additional cost to customer
        </p>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
          searchOptions={{
            componentRestrictions: { country: ["za"] },
            types: ["(regions)"],
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Add region...",
                  className:
                    "location-search-input m-2 w-1/2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "orange", cursor: "pointer" }
                    : { backgroundColor: "silver", cursor: "pointer" };
                  return (
                    <div
                      key={suggestion.description}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <ul className="flex flex-wrap">
          {regions.map((region) => (
            <li
              key={region}
              title="remove"
              className="m-1 p-2 border border-amber-600 bg-amber-300 cursor-pointer hover:bg-red-500"
              onClick={onDelete}
              value={region}
            >
              {region}
            </li>
          ))}
        </ul>
        {regions.length > 0 ? (
          <p className="text-sm text-orange-600">Click to remove</p>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
