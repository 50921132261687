import React from "react";

export default function Tutorials() {
  return (
    <div className="md:flex w-full md:w-2/3 mx-auto">
      <iframe
        className="md:mx-5 my-2 h-48 md:h-96 md:w-1/2"
        // width="560"
        // height="315"
        src="https://www.youtube.com/embed/xaD4iXdbIVw?si=IzV8rTJHY7o--Nus"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <iframe
        className="my-2 h-48 md:h-96 md:w-1/2"
        // width="560"
        // height="315"
        src="https://www.youtube.com/embed/ziOt2lnqSPQ?si=u50BGC7gfGojnDj8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  );
}
