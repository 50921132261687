import React, { useEffect, useState } from "react";
import { studioPublishArray } from "./arrays/studioPublishArray";

export default function StudioPublish({ addData, studio, docReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("userPublish");
  // const [publishID, setPublishID] = useState("");

  const onChange = (e) => {
    const { value, id } = e.target;

    setData(value);
    setDataId(id);
    // addData(id, value);
  };

  useEffect(() => {
    if (docReady && studio) {
      setData(studio.userPublish);
    }
  }, [docReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  // useEffect(() => {
  //   console.log("userPublish: ", data);
  //   console.log("typeof Data: ", typeof data);
  // }, [data]);

  return (
    <>
      <div className="m-4">
        <h3>Publish your studio?</h3>
        {data === true && (
          <p className="text-xs text-blue-500">
            Your studio will be reviewed, if approved, it will be activated
          </p>
        )}
        {data === false && (
          <p className="text-xs text-blue-500">
            Your studio will remain in draft mode.
          </p>
        )}

        <div className="flex items-center mb-4">
          <ul className="flex w-1/2">
            {studioPublishArray.map((item) => {
              return (
                <li key={item.value} className="w-full border-gray-200">
                  <input
                    id={item.id}
                    type={item.type}
                    value={item.value}
                    name={item.name}
                    checked={data === item.value}
                    onChange={onChange}
                    required
                    // {...(studio?.userPublish === item.value
                    //   ? { checked: true }
                    //   : {})}
                    className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded-full focus:ring-orange-400 focus:ring-2"
                  />
                  <label className="w-full py-3 ml-2 text-gray-900 ">
                    {item.title}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
