import React, { useEffect, useState } from "react";
import { videoEquipmentArray } from "../../../assets/globalArrays/videoEquipmentArray";

export default function StudioVideoEquipment({
  addData,
  studio,
  docReady,
  videoEquipError,
}) {
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("videoEquipment");
  // const [error, setError] = useState(false);
  const iconClasses = "w-10 h-10 fill-current text-orange-700";

  useEffect(() => {
    if (docReady && studio?.videoEquipment) {
      setData(studio.videoEquipment);
      setDataId(videoEquipmentArray()[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { id, value, checked } = e.target;
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }

    setDataId(id);
  };
  return (
    <>
      <div>
        <h3 className="ml-4 font-semibold text-gray-900">Video Equipment</h3>
        <p className="ml-4 mb-4 text-sm">Please specify equipment</p>
        {videoEquipError && (
          <p className="ml-4 text-red-600 text-xs">Please select atleast 1</p>
        )}
        <div>
          <ul
            className={`${
              videoEquipError
                ? "border-2 border-red-500"
                : "bg-white border border-gray-200"
            } m-4 w-48 text-sm font-medium text-gray-900 rounded-lg`}
          >
            {videoEquipmentArray(iconClasses).map((item) => (
              <li
                key={item.value}
                className="w-full border-b border-gray-200 rounded-t-lg"
              >
                <div className="flex items-center pl-3">
                  <label className="w-full py-3 ml-2 text-sm font-medium text-gray-900 ">
                    <input
                      id={item.id}
                      type={item.type}
                      value={item.value}
                      checked={data.includes(item.value)}
                      onChange={onChange}
                      // {...(studio?.videoEquipment.includes(item.value)
                      //   ? { checked: true }
                      //   : {})}
                      className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                    />
                    <span className="ml-2">{item.title}</span>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
