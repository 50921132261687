import React from "react";

export default function VideoHeader() {
  return (
    <video
      autoPlay
      loop
      muted
      className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
    >
      <source
        src="https://ik.imagekit.io/ajuaxvsrja/BT_CompilationTrailer_v2.mp4?updatedAt=1679568519012"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  );
}
