import React, { useEffect } from "react";
import { packageTypeArray } from "./arrays/packageTypeArray";

export default function CreateLocationPreselected({ addData }) {
  // const [type, setType] = useState()
  //   const [typeId, setTypeId] = useState();

  useEffect(() => {
    addData(packageTypeArray[0].id, "location");
  }, []);

  return (
    <>
      <div className="p-4 bg-gray-100">
        <h3>What type of package is this?</h3>
        <div className="flex items-center mb-4">
          <ul className="flex w-1/2">
            {packageTypeArray.map((item) => {
              return (
                <li key={item.value} className="w-full border-gray-200">
                  <input
                    id={item.id}
                    type={item.type}
                    value={item.value}
                    name={item.name}
                    required
                    checked={item.value === "onSite"}
                    readOnly
                    className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded-full focus:ring-orange-400 focus:ring-2"
                  />
                  <label className="w-full py-3 ml-2 text-gray-900 ">
                    {item.title}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
        <p className="text-blue-500 text-sm">
          Preselected field since there is no studio associated with this
          profile
        </p>
      </div>
    </>
  );
}
