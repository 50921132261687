//react
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//firebase
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";

import CreateAdvanceNotice from "./packageDetailComponents/CreateAdvanceNotice";
import CreateEditing from "./packageDetailComponents/CreateEditing";
import CreatePackageName from "./packageDetailComponents/CreatePackageName";
import CreateNoOfImages from "./packageDetailComponents/CreateNoOfImages";
import CreatePrice from "./packageDetailComponents/CreatePrice";
import CreateProductDelivery from "./packageDetailComponents/CreateProductDelivery";
import CreateServices from "./packageDetailComponents/CreateServices";
import CreateShootDuration from "./packageDetailComponents/CreateShootDuration";
import CreateVideoDuration from "./packageDetailComponents/CreateVideoDuration";
import CreateAboutPackage from "./packageDetailComponents/CreateAboutPackage";
import CreateTeam from "./packageDetailComponents/CreateTeam";
import CreatePackagePublish from "./packageDetailComponents/CreatePackagePublish";
import CreateNoOfVideos from "./packageDetailComponents/CreateNoOfVideos";
import CreateCancelPolicy from "./packageDetailComponents/CreateCancelPolicy";
import CreatePhotoCams from "./packageDetailComponents/CreatePhotoCams";
import CreatePhotoEquipment from "./packageDetailComponents/CreatePhotoEquipment";
import CreateVideoCams from "./packageDetailComponents/CreateVideoCams";
import CreateVideoEquipment from "./packageDetailComponents/CreateVideoEquipment";
import CreateProps from "./packageDetailComponents/CreateProps";
import CreatePackageType from "./packageDetailComponents/CreatePackageType";
import CreateNoPeople from "./packageDetailComponents/CreateNoPeople";
import CreateLocationPreselected from "./packageDetailComponents/CreateLocationPreselected";

//others
import { toast } from "react-toastify";
import Loading from "../Loading";

export default function PackageDetails({
  profile,
  proPackage,
  packageDocReady,
  studio,
  studioDocReady,
}) {
  const [formData, setFormData] = useState({
    services: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const hostname = window.location.hostname;
  //   const [profile, setProfile] = useState();

  //   const [proPackage, setProPackage] = useState();
  //   const [packageDocReady, setPackageDocReady] = useState(false);

  //   const [studio, setStudio] = useState("");
  //   const [studioDocReady, setStudioDocReady] = useState(false);

  const { services } = formData;

  const params = useParams();
  const { user, docId } = params;
  const navigate = useNavigate();

  //   // Pull package doc to prepopulate for editing.
  //   useEffect(() => {
  //     if (docId) {
  //       try {
  //         const docRef = doc(db, "packages", docId);
  //         getDoc(docRef).then((snapshot) => {
  //           setProPackage(snapshot.data());
  //           setPackageDocReady(true);
  //         });
  //       } catch (error) {
  //         toast.error("Something went wrong");
  //         console.log(error.message);
  //       }
  //     }
  //   }, [docId]);

  //   // Pulling studios in order to preset studio values in packages
  //   useEffect(() => {
  //     (async () => {
  //       const studiosRef = collection(db, "studios");
  //       const q = query(studiosRef, where("user", "==", user));
  //       const snapshots = await getDocs(q);
  //       const docs = snapshots.docs.map((doc) => doc.data());
  //       // const docIds = snapshots.docs.map((doc) => doc.id);
  //       setStudio(docs[0]);
  //       // setStudioId(docIds[0]);
  //       setStudioDocReady(true);
  //       console.log("pulling from database");
  //     })();
  //   }, [user]);

  //   // Pulling profile in order to regulate package values
  //   useEffect(() => {
  //     const docRef = doc(db, "profiles", user);
  //     getDoc(docRef).then((snapshot) => {
  //       setProfile(snapshot.data());
  //     });
  //   }, [user]);

  const addData = useCallback((id, data) => {
    if (hostname === "localhost") {
      console.log("Local Data Runs: ", id, data);
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  useEffect(() => {
    if (!services.includes("photography")) {
      delete formData.photography;
      delete formData.noImages;
      delete formData.shootImages;
      delete formData.photoCams;
      delete formData.photoEquipment;
    }
    if (!services.includes("videography")) {
      delete formData.videography;
      delete formData.noVideos;
      delete formData.videoDuration;
      delete formData.videoCams;
      delete formData.videoEquipment;
    }
  }, [services]);

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted: ", formData);

    setIsLoading(true);

    if (proPackage) {
      try {
        const updateRef = doc(db, "packages", docId);
        await updateDoc(updateRef, {
          ...formData,
          updatedAt: serverTimestamp(),
        });
        toast.success("Your Package has been Updated");
        setIsLoading(false);
        navigate(`/packages-editable/${user}`);
        // window.location.reload(true);
      } catch (error) {
        console.log(error.message);
        toast.error("There was an error updating your package");
        setIsLoading(false);
        return;
      }
    } else {
      try {
        await addDoc(collection(db, "packages"), {
          ...formData,
          createdAt: serverTimestamp(),
          user: user,
          adminPublish: "false",
          profileName: profile.name,
          profileProfileImageURL: profile.profileImageURL,
        });
        toast.success("Your Package has been uploaded");
        navigate(`/packages-editable/${user}`);
      } catch (error) {
        console.log(error.message);
        toast.error("There was an error adding your package");
      }
      setIsLoading(false);
      console.log("On submit: ", formData);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          {/* <h1 className="my-4 text-2xl text-center">Create/Edit Package</h1> */}
          <div className="mb-5 flex mx-auto sm:border w-11/12 md:w-2/3 shadow-lg">
            <form className="relative" onSubmit={onSubmit}>
              <CreatePackageName
                addData={addData}
                proPackage={proPackage}
                packageDocReady={packageDocReady}
              />
              <CreateServices
                addData={addData}
                proPackage={proPackage}
                packageDocReady={packageDocReady}
              />
              {studio ? (
                <CreatePackageType
                  addData={addData}
                  proPackage={proPackage}
                  packageDocReady={packageDocReady}
                />
              ) : (
                <CreateLocationPreselected addData={addData} />
              )}
              {formData.type === "studio" && studio && (
                <>
                  <CreateNoPeople
                    addData={addData}
                    proPackage={proPackage}
                    packageDocReady={packageDocReady}
                  />{" "}
                  <CreateProps
                    addData={addData}
                    studio={studio}
                    studioDocReady={studioDocReady}
                    proPackage={proPackage}
                    packageDocReady={packageDocReady}
                  />
                  {/* <CreateOutfitChange addData={addData} /> */}
                </>
              )}
              {services.includes("photography") && (
                <>
                  <h2 className="m-4 text-lg">
                    Photo Cameras and Equipment for this package
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <CreatePhotoCams
                      addData={addData}
                      proPackage={proPackage}
                      packageDocReady={packageDocReady}
                    />
                    <CreatePhotoEquipment
                      addData={addData}
                      proPackage={proPackage}
                      packageDocReady={packageDocReady}
                    />
                  </div>
                </>
              )}

              {services.includes("videography") && (
                <>
                  <h2 className="m-4 text-lg">
                    Video Cameras and Equipment for this package
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <CreateVideoCams
                      proPackage={proPackage}
                      packageDocReady={packageDocReady}
                      addData={addData}
                    />
                    <CreateVideoEquipment
                      addData={addData}
                      proPackage={proPackage}
                      packageDocReady={packageDocReady}
                    />
                  </div>
                </>
              )}

              <CreateAdvanceNotice
                addData={addData}
                proPackage={proPackage}
                packageDocReady={packageDocReady}
              />
              {formData.type === "onSite" && (
                <CreateTeam
                  addData={addData}
                  proPackage={proPackage}
                  packageDocReady={packageDocReady}
                />
              )}

              <CreateShootDuration
                addData={addData}
                proPackage={proPackage}
                packageDocReady={packageDocReady}
              />
              {services.includes("photography") && (
                <CreateEditing
                  addData={addData}
                  proPackage={proPackage}
                  packageDocReady={packageDocReady}
                />
              )}
              {services.includes("photography") && (
                <CreateNoOfImages
                  addData={addData}
                  proPackage={proPackage}
                  packageDocReady={packageDocReady}
                />
              )}
              {services.includes("videography") && (
                <CreateNoOfVideos
                  addData={addData}
                  proPackage={proPackage}
                  packageDocReady={packageDocReady}
                />
              )}
              {services.includes("videography") && (
                <CreateVideoDuration
                  addData={addData}
                  proPackage={proPackage}
                  packageDocReady={packageDocReady}
                />
              )}
              <CreateProductDelivery
                addData={addData}
                proPackage={proPackage}
                packageDocReady={packageDocReady}
              />
              <CreateCancelPolicy
                addData={addData}
                proPackage={proPackage}
                packageDocReady={packageDocReady}
              />
              <CreateAboutPackage
                addData={addData}
                proPackage={proPackage}
                packageDocReady={packageDocReady}
              />
              <CreatePrice
                addData={addData}
                proPackage={proPackage}
                packageDocReady={packageDocReady}
              />
              <CreatePackagePublish
                addData={addData}
                proPackage={proPackage}
                packageDocReady={packageDocReady}
              />

              <button
                disabled={!services.length === 0}
                className={`p-2 m-2 shadow ${
                  services.length > 0
                    ? "bg-orange-500 hover:bg-orange-600 cursor-pointer"
                    : "bg-slate-400 cursor-not-allowed"
                }`}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
