//components
import HomeHeader from "../components/homeComponents/HomeHeader";
import YoutubeVideo from "../components/homeComponents/YoutubeVideo";
import Features from "../components/homeComponents/Features";
import Testimonials from "../components/homeComponents/Testimonials";
import CallToAction from "../components/homeComponents/CallToAction";
import Search from "../components/homeComponents/Search";
// import ProTestimonials from "../components/homeComponents/ProTestimonials";
// import StudioSignUp from "../components/homeComponents/StudioSignUp";

export default function Home() {
  return (
    <>
      <div className="bg-white p-2">
        <HomeHeader />
        <Features />
        {/* <StudioSignUp /> */}
        <Search />
        <Testimonials />
        {/* <ProTestimonials /> */}
        <YoutubeVideo />
        <CallToAction />
      </div>
    </>
  );
}
