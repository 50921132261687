import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  // geocodeByPlaceId,
  // getLatLng,
} from "react-places-autocomplete";

export default function BookingEventAddress({ addData }) {
  const [isProvince, setIsProvince] = useState();
  const [address, setAddress] = useState("");
  const [coordinate, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    const work = addData("address", address);

    return work;
  }, [address, addData]);

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    // console.log(result);

    setAddress(result[0].formatted_address);

    // console.log("Formatted Address:", result[0].formatted_address);
    // console.log("Components:", result[0].address_components);

    // const coordinates = await getLatLng(result[0]);
    // console.log(coordinates);

    // console.log(result[0].formatted_address);
    // console.log(result[0].address_components)
    // console.log(result[0].address_components[0].long_name,result[0].address_components[3].short_name)
    // setAddress(value);
    setCoordinates(coordinate);

    // handleAddData("address", result[0].formatted_address);
  };

  return (
    <>
      <div className="m-4">
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
          searchOptions={{
            componentRestrictions: { country: ["za"] },
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <label>
                <p className="mr-2">Event Address</p>
                <p className="text-sm w-4/5 sm:w-3/4 text-gray-500">
                  (Only the professional you choose will get access to this
                  address)
                </p>
                <input
                  {...getInputProps({
                    placeholder: "Enter Address",
                    className: "location-search-input",
                  })}
                  required
                  className="block w-4/5 md:w-1/2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
                />
              </label>

              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "white", cursor: "pointer" }
                    : { backgroundColor: "silver", cursor: "pointer" };
                  return (
                    <div
                      key={suggestion.description}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {isProvince && (
          <p className="text-sm text-red-500">
            Please choose a sub-region in a province
          </p>
        )}
      </div>
    </>
  );
}
