import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
// import { BsFillCameraFill, BsFillCameraReelsFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { BsPlayBtn } from "react-icons/bs";
import { MdOutlinePhotoLibrary } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";

import avatar from "../assets/Avatar/photo.svg";
// MdOutlinePhotoLibrary

export default function Packages() {
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();
  // const [docID, setDocID] = useState();

  // const packagesLocation = query(collection(db, `packages/${user}`))
  useEffect(() => {
    (async () => {
      const colRef = collection(db, "packages");
      const q = query(
        colRef,
        where("userPublish", "==", "true"),
        where("adminPublish", "==", "true")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => {
        // console.log(doc.id, " => ", doc.data());
        // console.log("Doc: ", doc);
        return doc;
      });

      setPackages(docs);
      console.log("Docs: ", docs);
    })();
  }, []);

  return (
    <>
      {packages && (
        <div className="w-full bg-gray-200 py-10 px-2">
          <div className="mx-auto grid w-full lg:w-10/12 flex-col gap-3 md:grid-cols-2">
            {packages.map((item, index) => {
              // console.log("Delivery Time: ", item.data().delivery_time);
              // const deliveryTime = deliveryTimeCalc(item.data().delivery_time);

              return (
                <button
                  className="pb-2 relative bg-white shadow-md border hover:border-orange-400  hover:shadow-xl"
                  onClick={() => navigate(`${item.id}`)}
                  key={index}
                >
                  <h5 className="absolute text-xl left-1 top-2 capitalize">
                    {item.data().packageName}{" "}
                    <span className="text-base invisible lg:visible">
                      by {item.data().name}
                    </span>
                  </h5>
                  <div className="grid grid-cols-3 sm:grid-cols-12 gap-2">
                    <div>
                      <div className="absolute w-28 top-1/4">
                        <img
                          // src={`https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/${profile.profileImageURL}`}
                          src={
                            item.data().profileImageURL ===
                              "/static/media/logo.7e36e6aeac51429ea175.png" ||
                            item.data().profileImageURL === ""
                              ? avatar
                              : `https://ik.imagekit.io/ajuaxvsrja/tr:q-10,f-auto/${
                                  item.data().profileImageURL
                                }`
                          }
                          alt={`${item.data().name} profile`}
                          className="mx-auto p-1 ring-2 ring-orange-400 rounded-full block h-20 w-20 object-cover object-center"
                        />
                        {/* <h2 className="text-center">{item.data().name}</h2> */}
                      </div>
                    </div>
                    <div className="col-span-2 sm:col-span-11">
                      <div className="flex gap-2 pt-10 place-content-center">
                        {item.data().photography && (
                          <MdOutlinePhotoLibrary
                            title="photo(s)"
                            className="w-6 h-6 text-black mr-2"
                          />
                        )}
                        {item.data().videography && (
                          <BsPlayBtn
                            title="video(s)"
                            className="w-6 h-6 text-black ml-2"
                          />
                        )}
                      </div>
                      <div className="text-sm">
                        {item.data().shootHours && (
                          <p>Shoot Duration: {item.data().shootHours} hours</p>
                        )}
                        {item.data().noImages && (
                          <p># Images: {item.data().noImages}</p>
                        )}
                        {item.data().noVideos && (
                          <p># Videos: {item.data().noVideos}</p>
                        )}
                        {item.data().delivery_time === "0" ? (
                          <p>Delivery Time: Immediate</p>
                        ) : item.data().delivery_time > 0 &&
                          item.data().delivery_time < 24 ? (
                          <p>
                            Delivery Time: {item.data().delivery_time} hours
                          </p>
                        ) : (
                          <p>
                            Delivery Time: {item.data().delivery_time / 24} days
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="p-2">
                    <ul className="place-content-center text-xs flex flex-wrap gap-2 p-1">
                      {item.data().regions &&
                        item
                          .data()
                          .regions.slice(0, 3)
                          .map((place) => {
                            return (
                              <li
                                key={place}
                                className="bg-transparent border px-5 py-1 rounded-full border-orange-400"
                              >
                                {place}
                              </li>
                            );
                          })}
                    </ul>
                  </div> */}
                  <div className="flex flex-wrap text-xs items-center place-content-center">
                    {item.data().regions &&
                      item
                        .data()
                        .regions.slice(0, 3)
                        .map((region) => {
                          return (
                            <div
                              key={region}
                              className="min-w-min border border-orange-400 text-center m-2 px-5 py-1 rounded-full"
                            >
                              <span className="">
                                <MdLocationOn className="text-orange-400 inline-block align-middle" />{" "}
                                {region}
                              </span>
                            </div>
                          );
                        })}
                  </div>

                  <span className="absolute right-0 top-0 p-1 bg-[#fa9204] shadow">
                    R{item.data().price}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

// const docRef = doc(db, "profiles", user);
//     getDoc(docRef).then((snapshot) => {
//       console.log("Snapshot: ", snapshot);
//       console.log("Snapshot data:, ", snapshot.data());
//       setProfile(snapshot.data());
//     });
//{
/* <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  Noteworthy technology acquisitions 2021
                </h5>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                  Here are the biggest enterprise technology acquisitions of
                  2021 so far, in reverse chronological order.
                </p> */
//}
