import { useEffect, useState } from "react";

function CreateVideoDuration({ addData, proPackage, packageDocReady }) {
  const [data, setData] = useState();
  const [dataId, setDataId] = useState("videoDuration");

  useEffect(() => {
    addData(dataId, data);
  }, [addData, data, dataId]);

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.videoDuration);
    }
  }, [packageDocReady]);

  const onChange = (e) => {
    const { value, id } = e.target;
    setData(value);
    setDataId(id);

    // addData(id, value);
  };

  return (
    <>
      <div className="m-4">
        <label>
          <span>Total Video duration(min)</span>
          <input
            type="number"
            min="1"
            id="videoDuration"
            onChange={onChange}
            value={data}
            className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
          />
        </label>
      </div>
    </>
  );
}

export default CreateVideoDuration;
