import { collection, serverTimestamp, addDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { toast } from "react-toastify";

export default function Estimate() {
  const navigate = useNavigate();
  const professionArray = [
    {
      id: "photographer",
      type: "checkbox",
      title: "Photographer",
      value: "photographer",
    },
    {
      id: "videographer",
      type: "checkbox",
      title: "Videographer",
      value: "videographer",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState("");
  const [estimate, setEstimate] = useState("");
  const [error, setError] = useState();
  const [submit, setSubmit] = useState(false);
  const [next, setNext] = useState(false);
  const hoursThreshold = 2;
  const hoursMax = 10;
  // const hostname = window.location.hostname;

  useEffect(() => {
    if (hours > 0) {
      setError(false);
      if (data.length === 1) {
        if (data.includes("photographer")) {
          // console.log("photographer rates");
          if (hours <= hoursThreshold) {
            // console.log("photographer with less than 3 hours");
            setEstimate(hours * 750 + 250);
          } else if (hours > hoursThreshold && hours <= hoursMax) {
            // console.log("photographer with more than 3 hours");
            setEstimate(hours * 600 + 150);
          }
        } else if (data.includes("videographer")) {
          // console.log("videographer rates");
          if (hours <= hoursThreshold) {
            // console.log("videographer with 3 hours or less");
            setEstimate(hours * 975 + 250);
          } else if (hours > hoursThreshold && hours <= hoursMax) {
            // console.log("videographer with more than 3 hours");
            setEstimate(hours * 775 + 150);
          }
        }
      } else if (data.length === 2) {
        // console.log("both");
        if (hours <= hoursThreshold) {
          // console.log("both for less than 3 hours");
          setEstimate(hours * 1025 + 250);
        } else if (hours > hoursThreshold && hours <= hoursMax) {
          // console.log("both for more than 3 hours");
          setEstimate(hours * 1225 + 150);
        }
      } else if (data.length === 0) {
        setError(true);
      }
    } else if (hours === "" || hours === 0) {
      setError(true);
    }
  }, [hours, data]);

  useEffect(() => {
    if (!hours) {
      setEstimate("");
    }
  }, [hours]);

  const onProfessionChange = (e) => {
    setSubmit(false);
    const { id, value, checked } = e.target;
    if (!checked) {
      // const index = photoSpecialisation.indexOf(value);
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }
    // setDataId(id);
  };

  const onHoursChange = (e) => {
    setSubmit(false);
    setHours(e.target.value);
  };

  // useEffect(() => {
  //   console.log(next);
  // });

  const onClick = async () => {
    setSubmit(true);

    const docRef = collection(db, "estimates");

    try {
      setLoading(true);
      await addDoc(docRef, {
        services: data,
        duration: hours,
        estimate: estimate,
        submittedAt: serverTimestamp(),
      });
      setNext(true);
      setLoading(false);
      // toast.success("Your form has been submitted, we will be in touch.");
      // navigate("/");
    } catch (error) {
      console.log(error.message);
      toast.error("Try again");
    }
  };

  return (
    <>
      <div className="bg-white sm:bg-gray-100 w-full h-full sm:py-4 flex flex-col justify-center items-center">
        <div className="mx-auto w-full sm:w-4/5 bg-white p-2 sm:p-10 sm:drop-shadow rounded-xl">
          <div className="w-full">
            <h1 className="relative text-2xl sm:text-2xl capitalize font-light">
              Book a Photographer/Videographer
            </h1>
            <h6 className="capitalize text-xl sm:text-2xl pt-10 pb-5">
              Get an estimate.
            </h6>
            <p className="w-4/5">
              Lets give you a sense of how much it will cost and then you can
              decide if you want to proceed.
            </p>
            <hr className="h-px my-8 bg-orange-400 border-0"></hr>
          </div>
          <h3 className="ml-4 mb-4 font-semibold text-gray-900">
            I'm looking for a...
          </h3>
          <div onChange={onProfessionChange}>
            <ul className="m-4 w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
              {professionArray.map((item) => (
                <li
                  key={item.value}
                  className="w-full border-b border-gray-200 rounded-t-lg"
                >
                  <div className="flex items-center pl-3">
                    <input
                      id={item.id}
                      type={item.type}
                      value={item.value}
                      className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                    />
                    <label
                      className="w-full py-3 ml-2 text-sm font-medium text-gray-900 "
                      htmlFor={item.id}
                    >
                      {item.title}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="m-4">
            {/* {error && (
              <p className="w-fit text-red-600 text-sm">
                Estimate is up to 10 hours
              </p>
            )} */}
            <label>
              <span>Number of Hours</span>
              <input
                type="number"
                id="numberOfHours"
                value={hours}
                min="0"
                max="10"
                onChange={onHoursChange}
                className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
              />
            </label>

            <p className="text-sm">
              Generally, the more hours you book, the lower the rate per hour
            </p>
          </div>
          <div>
            <button
              onClick={onClick}
              className={`${
                error
                  ? "bg-gray-300 cursor-not-allowed"
                  : "hover:bg-orange-500 active:bg-orange-600 shadow-lg bg-orange-400"
              } text-black text-xl px-2 py-1 m-5 `}
              disabled={error}
            >
              Generate Estimate
            </button>
          </div>
          <div className="m-4 text-xl sm:text-2xl text-orange-400">
            {estimate && submit && <h1>Estimated Cost: R{estimate}</h1>}
          </div>
          <hr className="h-px my-8 bg-orange-400 border-0"></hr>
          <div className="w-full flex">
            <button
              onClick={() => navigate("/booking")}
              disabled={!next}
              // className={`text-white text-2xl mt-5 mx-auto bg-orange-400 px-4 py-2 m-5 hover:bg-orange-500 active:bg-orange-600 shadow-lg`}
              className={`${
                next
                  ? "hover:bg-orange-500 active:bg-orange-600 shadow-lg bg-orange-400 text-white"
                  : "bg-gray-300 cursor-not-allowed text-black"
              } text-3xl mx-auto px-5 py-1 m-5 `}
            >
              {loading ? "working..." : "Next"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
