import React, { useState, useEffect } from "react";

export default function StudioDescription({ addData, studio, docReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("description");

  useEffect(() => {
    if (docReady && studio) {
      setData(studio.description);
    }
  }, [docReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { value, id } = e.target;

    setData(value);
    setDataId(id);
  };

  return (
    <div className="m-4">
      <label className="block mb-2">
        <span>Studio Description</span>
        <textarea
          className="block p-2 text-sm w-full md:w-4/5 text-gray-900 bg-gray-50 border-orange-400 rounded-lg border focus:border-orange-400"
          id="description"
          rows="4"
          onChange={onChange}
          value={data}
          name="description"
          required
        />
      </label>
    </div>
  );
}
