import React from "react";

export default function BookingEventDuration({ addData }) {
  const onChange = (e) => {
    const { id, value } = e.target;

    addData(id, value);
  };
  return (
    <div className="m-4">
      <label>
        <span>Event Duration (hours)</span>
        <input
          type="number"
          id="duration"
          min="1"
          step="1"
          max="10"
          onChange={onChange}
          required
          className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
        />
      </label>
    </div>
  );
}
