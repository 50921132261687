import React from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const d = new Date();
  const thisYear = d.getFullYear();
  const navigate = useNavigate();

  return (
    <>
      <footer className="shadow bg-black w-full">
        <div className="flex w-full">
          <div className="mx-auto">
            <span className="text-sm text-center text-gray-400">
              {thisYear}{" "}
              <a href="https://brathula.com/" className="hover:underline">
                BraThula™
              </a>
              . All Rights Reserved.
            </span>
          </div>
        </div>
        <div className="flex w-full">
          <div className="mx-auto text-center text-sm text-gray-400 pb-4 pl-4">
            <p>
              <a
                className="hover:underline hover:text-orange-400"
                href="mailto: hello@brathula.com"
              >
                {" "}
                Contact Us: hello@brathula.com
              </a>
            </p>
            <p>
              <a
                className="hover:underline hover:text-orange-400"
                href="https://wa.me/27780170931"
              >
                Whatsapp: +27 78 017 0931{" "}
              </a>
            </p>

            <br />
            <p
              className="text-orange-400 text-2xl underline cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Pro Login
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
