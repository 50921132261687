import React, { useState, useEffect } from "react";

export default function CreateTeam({ addData, proPackage, packageDocReady }) {
  const [data, setData] = useState();
  const [dataId, setDataId] = useState("teamSize");

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.teamSize);
    }
  }, [packageDocReady]);

  const onChange = (e) => {
    const { value, id } = e.target;
    setData(value);
    setDataId(id);
  };

  return (
    <>
      <div className="m-4">
        <label>
          <span>Size of your team</span>
          <input
            onChange={onChange}
            id="teamSize"
            value={data}
            type="number"
            min="1"
            max="10"
            required
            className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
          />
        </label>
      </div>
    </>
  );
}
