import React, { useState, useEffect } from "react";
import { studioAmenitiesArray } from "./arrays/studioAmenitiesArray";

export default function StudioAmenities({
  addData,
  studio,
  docReady,
  amenitiesError,
}) {
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("props");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (docReady && studio?.amenities) {
      setData(studio.amenities);
      setDataId(studioAmenitiesArray[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docReady]);

  // useEffect(() => {
  //   if (data.length === 0) {
  //     setError(true);
  //     return;
  //   } else {
  //     // addData(dataId, data);
  //     setError(false);
  //   }
  // }, [data, dataId]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { id, value, checked } = e.target;
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }
    setDataId(id);
  };

  return (
    <>
      <div className="my-10">
        <h3 className="ml-4  font-semibold text-gray-900">Studio Amenities</h3>
        {amenitiesError && (
          <p className="ml-4 text-red-600 text-xs">Please select atleast 1</p>
        )}
        <div>
          <ul
            className={`${
              amenitiesError
                ? "border-2 border-red-500"
                : "bg-white border border-gray-200"
            } m-4 w-48 text-sm font-medium text-gray-900 rounded-lg`}
          >
            {studioAmenitiesArray.map((item) => (
              <li
                key={item.value}
                className="w-full border-b border-gray-200 rounded-t-lg"
              >
                <div className="flex items-center pl-3">
                  <label className="w-full py-3 ml-2 text-sm font-medium text-gray-900 ">
                    <input
                      id={item.id}
                      type={item.type}
                      value={item.value}
                      name={item.name}
                      checked={data.includes(item.value)}
                      onChange={onChange}
                      className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                    />
                    <span className="ml-2">{item.title}</span>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
