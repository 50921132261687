import React, { useState, useEffect } from "react";
import { studioPropsArray } from "../../../assets/globalArrays/studioPropsArray";

export default function CreateProps({
  addData,
  studio,
  studioDocReady,
  proPackage,
  packageDocReady,
}) {
  const [studioProps, setStudioProps] = useState([]);
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("props");

  // Ensure initial setup of previous props
  useEffect(() => {
    if (packageDocReady && proPackage.props) {
      setData(proPackage.props);
    }
  }, [packageDocReady]);

  // Ensure initial setup of studio props
  useEffect(() => {
    if (studioDocReady && studio.props) {
      setStudioProps(studio.props);
      console.log(studioProps);
    }
  }, [studioDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [data, dataId, addData]);

  const onChange = (e) => {
    const { name, value, checked } = e.target;
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }
    setDataId(name);
  };

  return (
    <>
      <div className="ml-4 mt-5">
        <h3 className="text-xl text-gray-900">Props for this package</h3>
        <p className="text-blue-500 text-sm">
          Props, as specified in your studio
        </p>
        <div className="flex flex-wrap gap-x-10 gap-y-5">
          {studioPropsArray.map((item) => (
            <div className="" key={item.value}>
              <label htmlFor={item.value}>
                <input
                  className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                  id={item.id}
                  type={item.type}
                  value={item.value}
                  name={item.name}
                  checked={data?.includes(item.value)}
                  onChange={onChange}
                  disabled={!studioProps?.includes(item.value)}
                />
                <span
                  className={`${
                    !studioProps.includes(item.value) ? `line-through` : ``
                  } ml-2`}
                >
                  {item.title}
                </span>
                <img
                  className={`${
                    !studioProps.includes(item.value)
                      ? `grayscale scale-90`
                      : ``
                  } h-56 rounded-lg`}
                  src={item.image}
                  alt={item.value}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
