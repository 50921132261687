import { useState } from "react";
import CreateRegions from "../packageCreateComponents/packageDetailComponents/CreateRegion";
import { BiEdit } from "react-icons/bi";

export default function EditRegion({ handleAddData, handleRegion }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };
  return (
    <>
      <div className="ml-4 flex flex-col">
        <p>Regions: </p>
        <ul className="flex">
          {handleRegion?.map((region) => {
            return (
              <li
                className="bg-blue-400 border-blue-400 border p-1 m-1 rounded shadow-lg align-middle"
                key={region}
              >
                {region}
              </li>
            );
          })}
        </ul>
        <BiEdit
          onClick={onClick}
          className=" text-orange-400 m-1 w-8 h-8 cursor-pointer"
        />
        <br></br>
        {edit && <CreateRegions handleAddData={handleAddData} />}
      </div>
    </>
  );
}
