import { useState, useEffect, useRef } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import FsLightbox from "fslightbox-react";
// import "lightbox.js-react/dist/index.css";
// import { SlideshowLightbox, initLightboxJS } from "lightbox.js-react";

export default function ImageGallery({ profile }) {
  const imageListRef = useRef(ref(storage, `images/${profile.user}`));
  const [imageList, setImageList] = useState([]);

  const [toggler, setToggler] = useState(false);

  useEffect(() => {
    listAll(imageListRef.current).then(async (response) => {
      const promises = response.items.map((item) => getDownloadURL(item));
      const urls = await Promise.all(promises);

      if (urls) {
        urls.map((download) => {
          const newDownload = download.replace(
            "https://firebasestorage.googleapis.com",
            "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/"
          );
          setImageList((prevValue) => {
            return [...prevValue, newDownload];
          });
        });
      }
    });
  }, []);

  return (
    <>
      {" "}
      <div className="mb-2">
        <button
          className="bg-white mx-auto p-2"
          onClick={() => setToggler(!toggler)}
        >
          Open Slideshow
        </button>
        <FsLightbox toggler={toggler} sources={imageList} />
      </div>
      <div className="-m-1 flex flex-wrap md:-m-2">
        {imageList &&
          imageList.map((url, index) => {
            return (
              <div
                key={index}
                className="relative flex w-full md:w-1/4 sm:w-1/2 flex-wrap"
              >
                <div key={index} className="w-full h-full p-1">
                  {/* <img
                    src={url}
                    alt={`photographer gallery-${index}`}
                    className="block w-full h-full object-contain object-center shadow-xl"
                    loading="lazy"
                    key={url}
                  /> */}
                  <LazyLoadImage
                    alt={`photographer gallery-${index}`}
                    src={url}
                    key={url}
                    // loading="lazy"
                    className="block w-full h-full object-contain object-center shadow-xl"
                    effect="blur"
                  />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
