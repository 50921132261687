import Battery from "../equipmentIcons/Battery";
import Lighting from "../equipmentIcons/Lighting";
import Tripod from "../equipmentIcons/Tripod";
import SoundEquipment from "../equipmentIcons/SoundEquipment";
import Stabilizer from "../equipmentIcons/Stabilizer";

export const videoEquipmentArray = (className) => [
  {
    id: "videoEquipment",
    title: "Extra Batteries",
    value: "extraBatteries",
    type: "checkbox",
    icon: <Battery className={className} />,
  },
  {
    id: "videoEquipment",
    title: "Lighting",
    value: "lighting",
    type: "checkbox",
    icon: <Lighting className={className} />,
  },
  // {
  //   id: "videoEquipment",
  //   title: "External Flash",
  //   value: "external_flash",
  //   type: "checkbox",
  // },
  {
    id: "videoEquipment",
    title: "Tripod",
    value: "tripod",
    type: "checkbox",
    icon: <Tripod className={className} />,
  },
  {
    id: "videoEquipment",
    title: "Sound Equipment",
    value: "soundEquipment",
    type: "checkbox",
    icon: <SoundEquipment className={className} />,
  },
  {
    id: "videoEquipment",
    title: "Stabilizer",
    value: "stabilizer",
    type: "checkbox",
    icon: <Stabilizer className={className} />,
  },
];

// battery, lighting, sound equipment, stabilizer, tripod, external flash
