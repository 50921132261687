import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import CreateEditing from "../packageCreateComponents/packageDetailComponents/CreateEditing";

export default function EditEditing({ handleAddData, handleEdit }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };
  return (
    <>
      <div className="flex flex-col">
        <p className="ml-4 mt-4">
          Final Product:{" "}
          <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
            {handleEdit}
          </span>
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>

        {edit && <CreateEditing handleAddData={handleAddData} />}
      </div>
    </>
  );
}
