import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import ConfirmModal from "../packagesInterfaceComponents/ConfirmModal";

import { toast } from "react-toastify";
import { BsTrash } from "react-icons/bs";

export default function DeletePackage({ user, docId }) {
  const [hidden, setHidden] = useState(true);
  const [continueDelete, setContinueDelete] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const deletePackage = async () => {
      try {
        await deleteDoc(doc(db, "packages", docId));
        // navigate(`/packages-editable/${user}`);
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong, please try again.");
        setContinueDelete(false);
      }
      setContinueDelete(false);
    };

    if (continueDelete === true) {
      deletePackage();
      console.log("Continue Delete is True");
    }
  }, [continueDelete, docId, user]);

  const onDelete = (e) => {
    // console.log(e.target);
    // console.log(docId);
    setHidden(false);
  };

  const toggleConfirm = () => {
    setHidden(!hidden);
  };

  const confirmDelete = () => {
    setContinueDelete(true);
  };

  return (
    <>
      <BsTrash
        title="delete"
        className="absolute bottom-4 right-4 h-6 w-6 md:h-8 md:w-8 p-1 hover:rounded-full hover:ring-2 text-red-600 text hover:ring-red-800 hover:text-red-800 cursor-pointer"
        onClick={onDelete}
      />
      {!hidden && (
        <ConfirmModal
          toggleConfirm={toggleConfirm}
          confirmDelete={confirmDelete}
        />
      )}
    </>
  );
}
