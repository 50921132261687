import React, { useState, useEffect } from "react";

export default function CreateNoPeople({
  addData,
  proPackage,
  packageDocReady,
}) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("noSubjects");

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.noSubjects);
    }
  }, [packageDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { value, id } = e.target;
    setData(value);
    setDataId(id);

    // addData(id, value);
  };

  // useEffect(() => {
  //   console.log("Data: ", data);
  // }, [data]);

  return (
    <>
      <div className="flex items-center m-4">
        <span>Max number of subjects(people/pets etc)</span>
        <select
          required
          id="noSubjects"
          name="noSubjects"
          onChange={onChange}
          className="ml-2 border-orange-400 hover:border-orange-400"
        >
          <option
            value=""
            defaultValue="Please Select"
            selected={data === ""}
            disabled
          >
            Please Select
          </option>
          <option selected={data === "1"} value={1}>
            1
          </option>
          <option selected={data === "2"} value={2}>
            2
          </option>
          <option selected={data === "3"} value={3}>
            3
          </option>
          <option selected={data === "4"} value={4}>
            4
          </option>
          <option selected={data === "5"} value={5}>
            5
          </option>
          <option selected={data === "6"} value={6}>
            6
          </option>
          <option selected={data === "7"} value={7}>
            7
          </option>
          <option selected={data === "8"} value={8}>
            8
          </option>
          <option selected={data === "9"} value={9}>
            9
          </option>
          <option selected={data === 10} value={10}>
            10
          </option>
          <option selected={data === "unlimited"} value="Unlimited">
            Unlimited
          </option>
        </select>
      </div>
    </>
  );
}
