import React from "react";

export default function PackageStatus({ item }) {
  return (
    <>
      <h5 className="absolute text-xl left-1 top-2">{item.name}</h5>
      {item.userPublish === "true" && item.adminPublish === "true" ? (
        <p className="absolute text-xs top-10 left-1 py-1 px-2 bg-emerald-500">
          Live!
        </p>
      ) : (
        ""
      )}
      {item.userPublish === "true" && item.adminPublish === "false" ? (
        <p className=" absolute text-xs top-10 left-1 py-1 px-2 bg-blue-400">
          Under-Review
        </p>
      ) : (
        ""
      )}
      {item.userPublish === "false" && item.adminPublish === "false" ? (
        <p className="absolute text-xs top-10 left-1 py-1 px-2 bg-red-600">
          Inactive
        </p>
      ) : (
        ""
      )}
    </>
  );
}
