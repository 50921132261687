import React from "react";

import avatar from "../../../assets/Avatar/photo.svg";

export default function ListingImage({ profile }) {
  return (
    <div className="h-36">
      {/* h-36 sm:w-44 w-48 */}{" "}
      <img
        src={
          profile.profileImageURL ===
            "/static/media/logo.7e36e6aeac51429ea175.png" ||
          profile.profileImageURL === ""
            ? avatar
            : `https://ik.imagekit.io/ajuaxvsrja/tr:q-10,f-auto/${profile.profileImageURL}`
        }
        alt="profile"
        className="inline-block align-middle ml-1 mt-1 p-1 my-auto ring-2 ring-orange-400 object-cover w-28 h-28 rounded-full"
        loading="lazy"
        key={profile.user}
      />
    </div>
  );
}
