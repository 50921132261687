import React, { useCallback, useEffect, useState } from "react";
import Map from "./travel/Map";
import Inputs from "./travel/Inputs";
import { serverTimestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

import { toast } from "react-toastify";

export default function EditTravel({ profile }) {
  const [formData, setFormData] = useState();

  const [grace, setGrace] = useState();
  const [distanceBand, setDistanceBand] = useState();
  const [distanceRate, setDistanceRate] = useState();
  const [loading, setLoading] = useState(false);

  const [enableSubmission, setEnableSubmission] = useState(false);

  //function that changes all data changes in travel
  const addData = useCallback((id, data) => {
    if (enableSubmission === false) {
      setEnableSubmission(true);
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  //enables full display when user data already exists
  useEffect(() => {
    if (profile.trFixedKMId) {
      setDistanceBand(profile.trFixedKMId);
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const editRef = doc(db, `profiles/${profile.user}`);

    try {
      await updateDoc(editRef, {
        ...formData,
        trCompletedAt: serverTimestamp(),
      });
      toast.success("Travel details have been updated");
      // navigate(``)
      window.location.reload(true);
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong");
    }

    console.log(formData);
  };

  //enables changing grace distance in components
  const graceChangeFn = useCallback((graceValue) => {
    setGrace(graceValue);
  }, []);

  //enables changing distance band in components
  const distanceBandChangeFn = useCallback((distanceBand) => {
    setDistanceBand(distanceBand);
  }, []);

  //enables changing rate in components
  const distanceBandRateChangeFn = useCallback((bandRate) => {
    setDistanceRate(bandRate);
  }, []);

  return (
    <>
      {profile.coordinates ? (
        <form onSubmit={onSubmit}>
          <h3 className="text-center text-lg mb-5">
            Set up your transportation policy
          </h3>
          <div className="grid md:grid-cols-2">
            <div>
              <Inputs
                profile={profile}
                graceChangeFn={graceChangeFn}
                grace={grace}
                distanceBandChangeFn={distanceBandChangeFn}
                distanceBand={distanceBand}
                addData={addData}
                distanceBandRateChangeFn={distanceBandRateChangeFn}
                distanceRate={distanceRate}
              />
            </div>
            <div>
              <Map
                profile={profile}
                grace={grace}
                distanceBand={distanceBand}
              />
            </div>
          </div>
          <div className="flex m-10">
            <div className="mx-auto">
              <button
                disable={!enableSubmission}
                className={`p-4 shadow-lg text-white ${
                  enableSubmission
                    ? "bg-orange-400 hover:bg-orange-500 active:bg-orange-600"
                    : "bg-slate-400 cursor-not-allowed"
                }  `}
              >
                {loading ? `Working...` : `Make Changes`}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div>
          <p className="text-center my-10 font-bold">
            Please enter your <em className="text-orange-400">address</em>{" "}
            before editing this area
          </p>
        </div>
      )}
    </>
  );
}
