import React, { useState, useEffect } from "react";
import { packageTypeArray } from "./arrays/packageTypeArray";

export default function CreatePackageType({
  addData,
  proPackage,
  packageDocReady,
}) {
  const [data, setData] = useState();
  const [dataId, setDataId] = useState("type");

  useEffect(() => {
    if (packageDocReady && proPackage.type) {
      setData(proPackage.type);
    }
  }, [packageDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  // useEffect(() => {
  //   console.log("Type: ", data);
  // }, [data]);

  const onChange = (e) => {
    const { value, id } = e.target;
    // console.log("onChange: ", value);

    setData(value);
    setDataId(id);
  };
  return (
    <>
      <div className="ml-4 my-10">
        <h3>What type of package is this?</h3>
        <div className="flex items-center mb-4">
          <ul className="flex w-1/2">
            {packageTypeArray.map((item) => {
              return (
                <li key={item.value} className="w-full border-gray-200">
                  <input
                    id={item.id}
                    type={item.type}
                    value={item.value}
                    name={item.name}
                    required
                    checked={data === item.value}
                    onChange={onChange}
                    className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded-full focus:ring-orange-400 focus:ring-2"
                  />
                  <label className="w-full py-3 ml-2 text-gray-900 ">
                    {item.title}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
