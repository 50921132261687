import { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { db } from "../firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OAuth from "../components/OAuth";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";

export default function SignUp() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    displayName: "",
    email: "",
    password: "",
  });

  const { email, password, displayName } = formData;

  // useEffect(() => {
  //   const auth = getAuth();
  //   return console.log("Current User: ", auth.currentUser);
  // }, []);

  // useEffect(() => {
  //   const auth = getAuth();
  //   console.log("Current User: ", auth.currentUser);
  //   console.log("Attempt: ", auth.currentUser?.uid);
  // });

  const onChange = (e) => {
    setFormData((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);

    try {
      const auth = getAuth();
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await updateProfile(auth.currentUser, {
        displayName: formData.displayName,
      });

      const docRef = doc(db, `profiles/${userCredentials.user.uid}`);
      const docSnap = await getDoc(docRef);

      console.log(userCredentials);
      if (!docSnap.exists()) {
        await setDoc(docRef, {
          userName: displayName,
          adminPublish: "false",
          email: userCredentials.user.email,
          initiatedAt: serverTimestamp(),
        });
      }

      await sendEmailVerification(auth.currentUser);
      toast.info("A verification email has just been sent to your account.");
      toast.info("Please verify email before submission");

      navigate(`/profile-edit/${userCredentials.user.uid}`);

      // console.log(auth.currentUser)
    } catch (error) {
      toast.error("Something went wrong during authentication");
      toast.error(error.message);
    }

    // await createUserWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     console.log("The user has signed in");
    //     console.log(userCredential);
    //     toast.success("You have been signed up");

    //     navigate(`/profile-create/${userCredential.user.uid}`);
    //   })
    //   .catch((error) => {
    //     // Very specific reason for user not being created but i don't like "Firebase being shown"
    //     toast.error(error.message);
    //     console.log(error);
    //     console.log(error.message);
    //   });

    // await updateProfile(auth.currentUser, { displayName: name })
    //   .then(() => {
    //     console.log("Display name updated");
    //     console.log(auth.currentUser);
    //   })
    //   .catch((error) => {
    //     console.log(error.message);
    //     toast.error(error.message);
    //   });
  };

  return (
    <>
      {/* <h6 className="bg-red-400 text-center">Sign Up</h6> */}
      <p className="text-center pt-5 text-2xl">Sign Up</p>

      <form
        onSubmit={onSubmit}
        className="m-5 flex flex-col w-1/2 mx-auto md:w-1/4 "
      >
        <label htmlFor="username" className="text-center">
          Username
        </label>
        <input
          className="border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
          type="text"
          id="displayName"
          placeholder=""
          onChange={onChange}
          value={displayName}
          required
        />

        <label htmlFor="email" className="text-center">
          Email
        </label>
        <input
          className="border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
          type="email"
          id="email"
          placeholder=""
          onChange={onChange}
          value={email}
          required
        />

        <label htmlFor="password" className="text-center">
          Password
        </label>
        <input
          className="border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
          type="password"
          id="password"
          placeholder=""
          onChange={onChange}
          value={password}
          required
        />
        <button
          type="submit"
          className="mx-auto mt-5 px-10 py-2 block shadow-lg text-white uppercase bg-[#fa9204] hover:bg-orange-500 active:bg-orange-600"
        >
          Sign Up
        </button>
      </form>
      <div className="mx-auto w-1/2 flex items-center my-4 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
        <p className="text-center font-semibold mx-4">OR</p>
      </div>
      <OAuth />
    </>
  );
}
