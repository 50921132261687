import React, { useState, useEffect } from "react";

export default function GraceDistance({ profile, graceChangeFn, addData }) {
  const [example, setExample] = useState();

  // const forExample = Math.random

  const onChange = (e) => {
    const { id, value } = e.target;
    graceChangeFn(value);
    setExample(value);

    // console.log(id, value);
    addData(id, value);
  };

  // useEffect(() => {
  //   console.log("Example: ", example);
  //   console.log(typeof example);
  // }, [example]);

  useEffect(() => {
    if (profile.trGraceDistance) {
      graceChangeFn(profile.trGraceDistance);
    }
  }, []);

  return (
    <div className="shadow md:p-5 p-2 mb-5">
      <div className="">
        <h3 className="text-2xl">Grace Distance</h3>
        <div className="">
          <h3>The distance that you will travel to a customer for free</h3>
          <div className="flex items-center mb-4">
            <select
              required
              id="trGraceDistance"
              name="graceDistance"
              onChange={onChange}
              className="border-orange-400"
            >
              {profile.trGraceDistance ? (
                <option
                  value={profile.trGraceDistance}
                  defaultValue="Please Select"
                  selected
                  disabled
                >
                  {`0-${profile.trGraceDistance}km`}
                </option>
              ) : (
                <option value="" defaultValue="Please Select" selected disabled>
                  Please Select
                </option>
              )}
              <option value={5}>0-5km</option>
              <option value={10}>0-10km</option>
              <option value={15}>0-15km</option>
              <option value={20}>0-20km</option>
              <option value={25}>0-25km</option>
              <option value={30}>0-30km</option>
              <option value={35}>0-35km</option>
              <option value={40}>0-40km</option>
              <option value={45}>0-45km</option>
              <option value={50}>0-50km</option>
              <option value={0}>No Grace</option>
            </select>
          </div>
          <div>
            <div>
              {example > 0 && (
                <p>
                  Example: If a customer is{" "}
                  <em className="font-bold text-orange-400">
                    {Math.floor(
                      ((example * 1) / 4) * Math.random().toFixed(1) +
                        (example * 3) / 4
                    )}
                    km{" "}
                  </em>
                  away. There is{" "}
                  <em className="font-bold text-orange-400">R0</em> transport
                  fee.{" "}
                </p>
              )}
              {example === "0" && (
                <p className="">
                  <em className="font-bold text-red-600">No Grace</em> distance
                  applied
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
