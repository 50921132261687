import React from "react";

export default function Stabilizer({ className }) {
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n" />
        <g id="o">
          <g>
            <path d="M49,12h-14c-.552,0-1,.448-1,1v2.279l-4.667,1.556-.384-1.151c-.086-.259-.275-.471-.522-.588-.247-.117-.531-.126-.786-.029l-13,5c-.499,.192-.759,.743-.59,1.25l1,3c.086,.259,.275,.471,.522,.588,.135,.063,.281,.096,.427,.096,.122,0,.243-.022,.359-.066l13-5c.482-.186,.732-.705,.597-1.198l4.045-1.348v1.612c0,.552,.448,1,1,1h4v24h-1c-.552,0-1,.447-1,1v8c0,.553,.448,1,1,1v5c0,.553,.448,1,1,1v2c0,1.103,.897,2,2,2h2c1.103,0,2-.897,2-2v-2c.552,0,1-.447,1-1v-5c.552,0,1-.447,1-1v-8c0-.553-.448-1-1-1h-1V20h4c.552,0,1-.448,1-1v-6c0-.552-.448-1-1-1ZM16.618,22.691l-.367-1.101,11.131-4.281,.367,1.101-11.131,4.281Zm22.382,23.309h6v6h-6v-6Zm4.001,16h-2.001v-2h2v2Zm1-4h-4v-4h4v4Zm-1-14h-2V20h2v24Zm5-26h-12v-4h12v4Z" />
            <path d="M48,6c1.103,0,2-.897,2-2V2c0-1.103-.897-2-2-2h-12c-1.103,0-2,.897-2,2v2c0,1.103,.897,2,2,2h5v3h-7v2h16v-2h-7v-3h5Zm-12-4h12v2h-12V2Z" />
          </g>
        </g>
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
