import React, { useEffect, useState } from "react";

export default function StudioSize({ addData, studio, docReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("size");

  useEffect(() => {
    // console.log("Studio size: ", studio?.studioSize);
    if (docReady && studio) {
      setData(studio.size);
    }
  }, [docReady]);

  // useEffect(() => {
  //   console.log("StudioSize Prints", dataId, data);
  // }, [dataId, data]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, data, dataId]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setData(value);
    setDataId(id);
  };

  return (
    <>
      <div className="m-5">
        <label className="">
          <span className="mr-2">Studio Size(square metres)</span>
          <input
            className="text-center w-20 p-0 border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
            required
            type="number"
            min={10}
            id="size"
            value={data}
            onChange={onChange}
          />
        </label>
        <span>
          m<sup>2</sup>
        </span>
      </div>
    </>
  );
}
