export const studioPublishArray = [
  {
    id: "userPublish",
    title: "Yes",
    value: "true",
    type: "radio",
    name: "userPublish",
  },
  {
    id: "userPublish",
    title: "No",
    value: "false",
    type: "radio",
    name: "userPublish",
  },
];
