import React from "react";
import { exampleImagesArray } from "./exampleImagesArray";

export default function ExampleImages() {
  return (
    <>
      <div className="h-36 md:h-60 overflow-x-scroll flex flex-row no-wrap mx-auto bg-orange-100">
        {exampleImagesArray.map((item) => (
          <img
            className="mx-4 my-2 h-32 md:h-56 rounded-lg"
            src={item.image}
            alt={item.title}
          />
        ))}
      </div>
    </>
  );
}
