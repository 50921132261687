import { useEffect, useState } from "react";
// import { ref, listAll, getDownloadURL } from "firebase/storage";
// import { storage } from "../../firebase";

// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import FsLightbox from "fslightbox-react";
import ImageGallery from "./gallery/ImageGallery";
import VideoGallery from "./gallery/VideoGallery";

export default function MultiMedia({ profile }) {
  // const [imageGallery, setImageGallery] = useState(true);
  // const [videoGallery, setVideoGallery] = useState(false);

  const [imageGallery, setImageGallery] = useState(() => {
    if (profile.photographer === "photographer") {
      return true;
    }

    return false;
  });
  const [videoGallery, setVideoGallery] = useState(() => {
    if (profile.photographer === "photographer") {
      return false;
    }

    return true;
  });

  const onPhotographerClick = () => {
    setImageGallery(true);
    setVideoGallery(false);
    // console.log("View Images");
  };

  const onVideographerClick = () => {
    setImageGallery(false);
    setVideoGallery(true);
    // console.log("View Videos");
  };

  // useEffect(() => {
  //   console.log("Image Gallery: ", imageGallery);
  //   console.log("Video Gallery: ", videoGallery);
  // }, [imageGallery, videoGallery]);

  return (
    <>
      <div className="w-full sm:w-4/5 container px-2 mx-auto pt-1">
        <div className="w-full mx-auto px-1">
          {/* <h1 className="text-center mt-10">Edit Your Portfolio</h1> */}
          <div className="w-full inline-flex justify-center py-4">
            <button
              onClick={onPhotographerClick}
              className={`${
                imageGallery && profile.images > 0
                  ? "bg-orange-400 hover:bg-orange-500"
                  : "bg-white hover:bg-gray-200"
              }  text-gray-800 font-bold py-2 px-4 ${
                profile.images === 0 || profile.images === undefined
                  ? "cursor-not-allowed bg-gray-400"
                  : ""
              } border-gray-200 rounded-l-lg transition-all`}
              disabled={profile.images === 0 || profile.images === undefined}
            >
              Images
            </button>
            <button
              onClick={onVideographerClick}
              className={`${
                videoGallery && profile.videos > 0
                  ? "bg-orange-400 hover:bg-orange-500"
                  : "bg-white hover:bg-gray-200"
              }  text-gray-800 font-bold py-2 px-4 ${
                profile.videos === 0 || profile.videos === undefined
                  ? "cursor-not-allowed bg-gray-400"
                  : ""
              } border-gray-200 rounded-r-lg transition-all `}
              disabled={profile.videos === undefined || profile.videos === 0}
            >
              Videos
            </button>
          </div>
          {imageGallery ? (
            <ImageGallery profile={profile} />
          ) : (
            <VideoGallery profile={profile} />
          )}
        </div>
      </div>
    </>
  );
}
