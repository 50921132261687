import { useState } from "react";
import CreateNoOfImages from "../packageCreateComponents/packageDetailComponents/CreateNoOfImages";
import { BiEdit } from "react-icons/bi";

export default function EditNoOfImages({ handleAddData, handleImageNo }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      {handleImageNo && (
        <p className="m-4">
          Number of Images:{" "}
          <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
            {handleImageNo}
          </span>
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>
      )}
      {edit && <CreateNoOfImages handleAddData={handleAddData} />}
    </>
  );
}
