import React, { useEffect, useState } from "react";
import { photoSpecialisationArray } from "./arrays/photoSpecialisationArray";

export default function CreatePExpertise({
  addData,
  profileDocReady,
  profile,
  expertiseError,
}) {
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("photo_expertise");

  useEffect(() => {
    if (profileDocReady && profile.photo_expertise) {
      setData(profile.photo_expertise);
    }
  }, [profileDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [data, dataId, addData]);

  const onChange = (e) => {
    const { id, value, checked } = e.target;
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }
    setDataId(id);
  };

  useEffect(() => {
    console.log("Expertise Error: ", expertiseError);
  }, [expertiseError]);

  return (
    <>
      <div>
        <h3 className="text-gray-900 ml-4 font-semibold">Photo Expertise</h3>
        <p className="ml-4 mb-4 text-xs">Choose up to 3</p>
        {/* <ul className="m-4 w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg"> */}
        <ul
          className={`${
            expertiseError
              ? "border-2 border-red-500"
              : "border border-gray-200"
          } m-4 w-48 text-sm font-medium text-gray-900 rounded-lg`}
        >
          {photoSpecialisationArray.map((item) => (
            <li
              key={item.value}
              className="w-full border-b border-gray-200 rounded-t-lg"
            >
              <div className="flex items-center pl-3">
                <label className="w-full py-3 ml-2 text-sm font-medium text-gray-900 ">
                  <input
                    id={item.id}
                    type={item.type}
                    value={item.value}
                    checked={data.includes(item.value)}
                    onChange={onChange}
                    className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                  />
                  <span className="ml-2">{item.title}</span>
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
