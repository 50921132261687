import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import CreateVideoEquipment from "../packageCreateComponents/packageDetailComponents/CreateVideoEquipment";

export default function EditVideoEquipment({
  handleAddData,
  handleVideoEquipment,
}) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      <p className="m-4">Video Equipment</p>
      <ul className="flex flex-wrap ml-4">
        {handleVideoEquipment.map((item) => {
          let result = item.replace("_", " ");
          return (
            <li
              className="capitalize bg-blue-400 border-blue-400 border p-1 m-1 rounded shadow-lg"
              key={item}
            >
              {result}
            </li>
          );
        })}
        <BiEdit
          onClick={onClick}
          className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
        />
      </ul>
      {edit && <CreateVideoEquipment handleAddData={handleAddData} />}
    </>
  );
}
