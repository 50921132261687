import React from "react";

export default function CellphoneCam({ className }) {
  return (
    <>
      <svg
        className={className}
        id="Capa_1"
        enableBackground="new 0 0 512 512"
        height="512"
        viewBox="0 0 512 512"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g>
            <path d="m400 148v-24c0-3.524-2.434-6.473-5.711-7.276v-74.224c0-23.435-19.065-42.5-42.5-42.5h-191.579c-23.435 0-42.5 19.065-42.5 42.5v130.224c-3.276.804-5.71 3.752-5.71 7.276v71c0 3.524 2.434 6.472 5.71 7.276v211.224c0 23.435 19.065 42.5 42.5 42.5h31.79c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-31.79c-15.164 0-27.5-12.336-27.5-27.5v-310.924h145.852c15.163 0 27.5-12.336 27.5-27.5v-23.212-1-33.864c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v26.364h-56.079v-84.364h56.079v23c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-23h45.727c15.163 0 27.5 12.336 27.5 27.5v190.5c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-21.724c3.277-.803 5.711-3.752 5.711-7.276v-24c0-3.524-2.434-6.473-5.711-7.276v-17.448c3.277-.803 5.711-3.752 5.711-7.276zm-180.017-4.424h-87.273v-101.076c0-15.164 12.336-27.5 27.5-27.5h59.773zm71.079-28.212v15.712c0 6.893-5.607 12.5-12.5 12.5h-43.579v-28.212z" />
            <path d="m386.789 260.5c-4.143 0-7.5 3.358-7.5 7.5v201.5c0 15.164-12.337 27.5-27.5 27.5h-124.789c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h124.789c23.435 0 42.5-19.065 42.5-42.5v-201.5c0-4.142-3.357-7.5-7.5-7.5z" />
            <path d="m242.426 68.182c0 11.028 8.972 20 20 20s20-8.972 20-20-8.972-20-20-20-20 8.972-20 20zm20.001-5c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5z" />
            <path d="m143.387 49.576c0 14.336 11.664 26 26 26s26-11.664 26-26-11.664-26-26-26-26 11.663-26 26zm26-11c6.065 0 11 4.935 11 11s-4.935 11-11 11-11-4.935-11-11 4.934-11 11-11z" />
            <path d="m169.387 83.576c-14.336 0-26 11.664-26 26s11.664 26 26 26 26-11.664 26-26-11.664-26-26-26zm0 37c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.935 11-11 11z" />
            <path d="m169.324 404.5c-4.142 0-7.5 3.358-7.5 7.5v46c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-46c0-4.142-3.358-7.5-7.5-7.5z" />
            <path d="m176.824 372c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v14c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5z" />
            <circle cx="262.43" cy="34.68" r="7.5" />
          </g>
        </g>
      </svg>
    </>
  );
}
