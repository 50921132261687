import React from "react";

import { CiFaceMeh, CiFaceSmile, CiFaceFrown } from "react-icons/ci";

export default function QuantityIndicators({ mediaQuantity, maxImages }) {
  // useEffect(() => {
  //   console.log("MediaQuantity: ", mediaQuantity);
  //   console.log("maxImages: ", maxImages);
  // }, [mediaQuantity, maxImages]);

  return (
    <>
      {mediaQuantity < (1 / 3) * maxImages && (
        <div className="flex mb-10">
          <div className="mx-auto bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded border border-red-800">
            <CiFaceFrown className="text-4xl inline-block align-middle mr-4" />
            <p className="text-2xl inline-block align-middle">
              {`${mediaQuantity}/${maxImages} images`}
            </p>
          </div>
        </div>
      )}
      {mediaQuantity > (1 / 3) * maxImages &&
        mediaQuantity < (2 / 3) * maxImages && (
          <div className="flex mb-5">
            <div className="mx-auto bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded border border-yellow-800">
              <CiFaceMeh className="text-4xl inline-block align-middle mr-4" />
              <p className="text-2xl inline-block align-middle">
                {`${mediaQuantity}/${maxImages} images`}
              </p>
            </div>
          </div>
        )}
      {mediaQuantity > (2 / 3) * maxImages && (
        <div className="flex mb-5">
          <div className="mx-auto bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded border border-green-800">
            <CiFaceSmile className="text-4xl inline-block align-middle mr-4" />
            <p className="text-2xl inline-block align-middle">
              {`${mediaQuantity}/${maxImages} images`}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
