import React, { useState } from "react";
import { toast } from "react-toastify";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const auth = getAuth();

  const onSubmit = (e) => {
    e.preventDefault();

    if (email === "") {
      console.log("No email entered");
      toast.error("Please enter an email address");
      return;
    } else {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          toast.success(
            `If ${email} has an account, password reset email will be sent to it`
          );
          navigate("/login");
        })
        .catch((error) => {
          toast.success(
            `If ${email} has an account, password reset email will be sent to it.`
          );
          navigate("/login");
        });
    }
  };

  return (
    <div className="p-10">
      <form onSubmit={onSubmit}>
        <h1 className="text-2xl my-5">Reset Password</h1>
        <label htmlFor="email">Email</label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          id="email"
          value={email}
          placeholder="email address"
          className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
        />
        <button className="my-10 px-10 py-2 block shadow-lg text-white uppercase bg-[#fa9204] hover:bg-orange-500 active:bg-orange-600">
          Submit
        </button>
      </form>
    </div>
  );
}
