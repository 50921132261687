import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
// import { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function CreateContact({ addData, profileDocReady, profile }) {
  // const [value, setValue] = useState();
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("contactNumber");

  useEffect(() => {
    if (profileDocReady && profile.contactNumber) {
      setData(profile.contactNumber);
    }
  }, [profileDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, data, dataId]);

  const onChange = (e) => {
    setData(e);
    setDataId("contactNumber");
  };

  return (
    <>
      <div className="m-4 md:flex items-center">
        <h2 className="mr-2">Contact Number:</h2>
        <PhoneInput
          // international
          // countryCallingCodeEditable={false}
          placeholder="Starting with 0"
          defaultCountry="ZA"
          value={data}
          onChange={onChange}
          className="w-3/4 sm:w-1/3"
          required
          // id="contactNumber"
        />
      </div>
    </>
  );
}
