import React, { useEffect, useState } from "react";

export default function CreateVideographerRate({
  addData,
  profile,
  profileDocReady,
}) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("videographer_rate");

  useEffect(() => {
    if (profileDocReady && profile.videographer_rate) {
      setData(profile.videographer_rate);
    }
  }, [profileDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { value, id } = e.target;

    setData(value);
    setDataId(id);
  };

  return (
    <>
      <div className="m-4">
        <label>
          <span>Videographer Rate/hour(R)</span>
          <input
            type="number"
            id="videographer_rate"
            min="50"
            required
            onChange={onChange}
            value={data}
            placeholder="Amount"
            className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
          />
        </label>
      </div>
    </>
  );
}
