//Routing
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import { useEffect } from "react";
import { generateToken } from "./firebase";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase";

//Pages
import Home from "./pages/Home";
import LogIn from "./pages/LogIn";
import SignUp from "./pages/SignUp";
import ProfileCreate from "./pages/ProfileCreate";
import Profile from "./pages/Profile";
import Portfolio from "./pages/Portfolio";
import PortfolioUpload from "./pages/PortfolioUpload";
import Test from "./pages/Test";
// import PackageCreate from "./pages/PackageCreate";
import PackagesEditable from "./pages/PackagesEditable";
import Packages from "./pages/Packages";
import Portfolios from "./pages/Portfolios";
import ProfileEdit from "./pages/ProfileEdit";
import PackageEdit from "./pages/PackageEdit";
import ProPackages from "./pages/ProPackages";
import BraThulaIs from "./pages/BraThulaIs";
import Estimate from "./pages/Estimate";
import Booking from "./pages/Booking";
import ProPackagesList from "./pages/ProPackagesList";
// import ProVerification from "./pages/ProVerification";
import FAQCreate from "./pages/FAQCreate";
import FAQs from "./pages/FAQs";
import ForgotPassword from "./pages/ForgotPassword";
import ProReviews from "./pages/ProReviews";
import StudioEdit from "./pages/StudioEdit";
import PackageCreate from "./pages/PackageCreate";

//other imports
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      console.log(payload);
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route index element={<Home />} />
          {/* Authentication pages */}
          <Route path="/login" element={<LogIn />} />
          <Route path="/signup" element={<SignUp />} />
          {/* Professional Profile Pages Backend */}
          <Route path="/profile-create/:user" element={<ProfileCreate />} />
          <Route path="/profile-edit/:user" element={<ProfileEdit />} />
          <Route path="/profile/:user" element={<Profile />} />
          {/* Professional Portfolio Pages Backend */}
          <Route path="/portfolio/:user" element={<Portfolio />} />
          <Route path="/portfolio-edit/:user" element={<PortfolioUpload />} />
          {/* Professional Reviews backend */}
          <Route path="/proreviews/:user" element={<ProReviews />} />
          {/* Professional Packages Backend */}
          <Route
            path="/packages-editable/:user"
            element={<PackagesEditable />}
          />
          <Route path="/edit-package/:user/:docId" element={<PackageEdit />} />
          <Route path="/create-package/:user" element={<PackageCreate />} />
          <Route
            path="/create-package/:user/:docId"
            element={<PackageCreate />}
          />
          {/* Professional Studios Backend */}
          <Route path="/studio-edit/:user" element={<StudioEdit />} />
          {/* User frontend */}
          <Route path="/packages" element={<Packages />} />
          <Route path="/packages/:docid" element={<ProPackages />} />
          <Route path="/propackages/:user" element={<ProPackagesList />} />
          <Route path="/portfolios" element={<Portfolios />} />
          <Route path="/brathulais" element={<BraThulaIs />} />
          <Route path="/estimate" element={<Estimate />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/FAQCreate" element={<FAQCreate />} />
          <Route path="FAQs" element={<FAQs />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/test" element={<Test />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
