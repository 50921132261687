// import React from "react";

//Firebase Imports
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { storage } from "../../../firebase";

import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
// import { serverTimestamp, addDoc, collection } from "@firebase/firestore";

export default async function ImageUploadSubmit(
  images,
  user,
  imageIsLoading,
  imageNotLoading,
  imageFolder
) {
  const imageLoadingPromises = [];
  let imageID = "";

  if (images.length === 0) {
    toast.error("Please upload images");
    return;
  }
  if (images.length > 0) {
    //Not sure how to address async state here.
    // setImageUploading(true);
    imageIsLoading();
    images.forEach((image) => {
      // console.log("Uploading State: " + imageUploading);
      imageID = `${uuidv4()}-${image.name}`;
      const uploadPromise = new Promise((resolve) => {
        const storageRef = ref(
          storage,
          // "images/" + user + "/" + uuidv4() + "-" + image.name
          imageFolder + "/" + user + "/" + image.name
        );

        const uploadTask = uploadBytesResumable(storageRef, image);
        // console.log("Upload Status: " + imageUploading);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(
              image.name + ": Upload is " + Math.ceil(progress) + "% done"
            );
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
              default:
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            toast.error(error.message);
            console.log(error.message);
            // setImageUploading(false);
            imageNotLoading();
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              // console.log(uploadPromise);
              toast.success(image.name + " has been uploaded");
              console.log(image.name + " has been uploaded");

              // try {
              //   addDoc(collection(db, "images/"), {
              //     imageURL: downloadURL,
              //     imageName: image.name,
              //     createdAt: serverTimestamp(),
              //     user: user,
              //   });
              //   toast.success(image.name + " has been uploaded");
              //   console.log(image.name + " has been uploaded");
              // } catch (error) {
              //   console.log(error);
              //   toast.error("There was an error uploading your image/s");
              //   imageNotLoading();
              //   return;
              // }
              resolve();
            });
          }
        );
      });
      console.log("finished looping!");
      imageLoadingPromises.push(uploadPromise);
    });
  }
  // console.log("Process Complete 1");
  await Promise.all(imageLoadingPromises);
  imageNotLoading();
  // console.log("Shouldn't be loading image");

  //   return <div>ImageUploadSubmit</div>;
}
