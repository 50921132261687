import React from "react";

export default function ProBookingContactPerson({ addData }) {
  const onChange = (e) => {
    const { value, id } = e.target;

    addData(id, value);
  };
  return (
    <>
      <div className="m-5" onChange={onChange}>
        <label className="">
          <span className="mr-2">Contact Person</span>
          <input
            className="border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
            required
            type="text"
            id="customerName"
          />
        </label>
      </div>
    </>
  );
}
