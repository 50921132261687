import React from "react";
import GraceDistance from "./inputComponents/GraceDistance";
// import FirstBand from "./inputComponents/FirstBand";
import RandPerKM from "./inputComponents/RandPerKM";

export default function Inputs({
  profile,
  graceChangeFn,
  grace,
  distanceBandChangeFn,
  distanceBandRateChangeFn,
  distanceBand,
  addData,
  distanceRate,
}) {
  return (
    <>
      <div className="w-full flex px-2">
        <div className="w-full md:w-full mx-auto">
          <div>
            <GraceDistance
              profile={profile}
              graceChangeFn={graceChangeFn}
              addData={addData}
            />
            <RandPerKM
              distanceBand={distanceBand}
              distanceRate={distanceRate}
              addData={addData}
              grace={grace}
              profile={profile}
            />
          </div>
        </div>
      </div>
    </>
  );
}
