import React, { useState } from "react";

import VideoUploadSubmit from "./individualComponents/VideoUploadSubmit";
import VideoUpload from "./individualComponents/VideoUpload";
// import EditImageGallery from "./individualComponents/EditImageGallery";
import EditVideoGallery from "./individualComponents/EditVideoGallery";
import { useParams } from "react-router-dom";
import Loading from "../Loading";

export default function Videos() {
  const params = useParams();
  const { user } = params;

  const [videoUploading, setVideoUploading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [videoProgress, setVideoProgress] = useState("");
  const maxVideoSize = 200;

  const videoIsLoading = () => {
    setVideoUploading(true);
  };

  const videoNotLoading = () => {
    setVideoUploading(false);
  };

  const updateVideoProgress = (value) => {
    setVideoProgress(value);
  };

  const onVideoSubmit = async (e) => {
    e.preventDefault();

    VideoUploadSubmit(
      videos,
      user,
      videoIsLoading,
      videoNotLoading,
      updateVideoProgress,
      maxVideoSize
    );
  };

  return (
    <>
      {videoUploading ? (
        <>
          <Loading />
          <p className="text-center text-2xl mt-5">
            Uploading... {videoProgress}%
          </p>
        </>
      ) : (
        <>
          <form onSubmit={onVideoSubmit}>
            <VideoUpload setVideos={setVideos} maxVideoSize={maxVideoSize} />
            <button className="block mx-auto mt-4 p-4 border-2 border-orange-400">
              Upload Videos
            </button>
          </form>
          <EditVideoGallery user={user} />
        </>
      )}
    </>
  );
}
