import React from "react";

export default function FAQCategory({ addData }) {
  const categoryArray = [
    {
      id: "category",
      title: "CustomerFAQs",
      value: "customerFAQs",
      type: "radio",
      name: "faqCategory",
    },
    {
      id: "category",
      title: "ProFAQs",
      value: "proFAQs",
      type: "radio",
      name: "faqCategory",
    },
  ];

  const onChange = (e) => {
    const { id, value } = e.target;

    addData(id, value);
  };

  return (
    <>
      <div className="m-4">
        <h3>FAQ Category</h3>

        <div onChange={onChange} className="flex items-center mb-4">
          <ul className="flex w-1/2">
            {categoryArray.map((item) => {
              return (
                <li key={item.value} className="w-full border-gray-200">
                  <input
                    id={item.id}
                    type={item.type}
                    value={item.value}
                    name={item.name}
                    className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded-full focus:ring-orange-400 focus:ring-2"
                  />
                  <label className="w-full py-3 ml-2 text-gray-900 ">
                    {item.title}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
