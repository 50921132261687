//React or Firebase
// import React, { useState, useCallback, useEffect } from "react";
// import { db } from "../firebase";
// import { serverTimestamp, doc, updateDoc } from "firebase/firestore";

//page components
// // import CreateWorkSchedule from "../components/profileCreateComponents/CreateWorkSchedule";
// import CreateProfession from "../components/profileCreateComponents/CreateProfession";
// // import CreateTransport from "../components/profileCreateComponents/CreateTransport";
// import CreatePExpertise from "../components/profileCreateComponents/CreatePExpertise";
// import CreateVExpertise from "../components/profileCreateComponents/CreateVExpertise";
// import CreateProvince from "../components/profileCreateComponents/CreateProvince";
// import CreateRegion from "../components/profileCreateComponents/CreateRegion";
// // import CreateAvailability from "../components/profileCreateComponents/CreateAvailability";
// import CreateName from "../components/profileCreateComponents/CreateName";
// import CreateProfilePhoto from "../components/profileCreateComponents/CreateProfilePhoto";
// import CreatePhotographerRate from "../components/profileCreateComponents/CreatePhotographerRate";
// import CreateVideographerRate from "../components/profileCreateComponents/CreateVideographerRate";
// // import CreateBirthYear from "../components/profileCreateComponents/CreateBirthYear";
// import CreateAboutYou from "../components/profileCreateComponents/CreateAboutYou";
// import CreateContact from "../components/profileCreateComponents/CreateContact";
// import CreateProfilePublish from "../components/profileCreateComponents/CreateProfilePublish";
// import CreatePType from "../components/profileCreateComponents/CreatePType";

//others
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { useNavigate, useParams } from "react-router-dom";

export default function ProfileCreate() {
  //User import to be used to create user folder
  // const navigate = useNavigate();

  // const params = useParams();
  // const { user } = params;
  // const hostname = window.location.hostname;

  // //Loading state
  // const [isLoading, setIsLoading] = useState(false);

  // //The final upload state for all values
  // const [formData, setFormData] = useState({
  //   videographer: "",
  //   photographer: "",
  // });

  // const [photographer] = formData.photographer;
  // const [videographer] = formData.videographer;

  // useEffect(() => {
  //   if (hostname === "localhost") {
  //     console.log("Local Print: ", formData);
  //   }
  // }, [formData, hostname]);

  // // Function to be passed to create components
  // const addData = useCallback((id, data) => {
  //   setFormData((prevValue) => {
  //     return {
  //       ...prevValue,
  //       [id]: data,
  //     };
  //   });
  // }, []);

  // // empties every associated if "photographer" or "videographer" are empty
  // useEffect(() => {
  //   if (!photographer) {
  //     delete formData.photographerType;
  //     delete formData.photo_expertise;
  //     delete formData.photographer_rate;
  //   }
  //   if (!videographer) {
  //     delete formData.videographerType;
  //     delete formData.video_expertise;
  //     delete formData.videographer_rate;
  //   }
  // }, [photographer, videographer]);

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   const editRef = doc(db, `profiles/${user}`);

  //   try {
  //     await updateDoc(editRef, {
  //       ...formData,
  //       completedAt: serverTimestamp(),
  //       user: user,
  //     });
  //     toast.success("Your Profile has been uploaded");
  //     navigate(`/portfolio-edit/${user}`);
  //   } catch (error) {
  //     console.log(error.message);
  //     toast.error("Something went wrong");
  //   }
  //   setIsLoading(false);
  // };

  return (
    <>
      {/* {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="p-1 shadow-lg my-5 flex mx-auto w-full sm:w-2/3 overflow-x-hidden">
            <form onSubmit={onSubmit}>
              <CreateName handleAddData={addData} />
              <CreateProfilePhoto handleAddData={addData} />
              <CreateContact handleAddData={addData} />
              <CreateProfession handleAddData={addData} />
              {photographer && <CreatePType handleAddData={addData} />}
              {photographer && <CreatePExpertise handleAddData={addData} />}

              {videographer && <CreateVExpertise handleAddData={addData} />}
              {(videographer || photographer) && (
                <p className="m-4 w-2/3 md:w-1/2 text-left text-blue-600">
                  Unhappy with these options? Add your own
                  "videography/photography expertise" options. Just{" "}
                  <a className="underline" href="mailto:hello@brathula.com">
                    email
                  </a>{" "}
                  your suggestions.
                </p>
              )}
              {photographer && (
                <CreatePhotographerRate handleAddData={addData} />
              )}
              {videographer && (
                <CreateVideographerRate handleAddData={addData} />
              )}

              <CreateProvince handleAddData={addData} />
              <CreateRegion handleAddData={addData} />
              <CreateAboutYou handleAddData={addData} />
              <CreateProfilePublish handleAddData={addData} />

              <button
                className={`p-2 m-2 shadow ${
                  videographer || photographer
                    ? "bg-orange-500 hover:bg-orange-600 cursor-pointer"
                    : "bg-slate-400 cursor-not-allowed"
                }`}
                disabled={!videographer && !photographer}
              >
                {isLoading ? "Uploading..." : "Submit"}
              </button>
            </form>
          </div>
        </>
      )} */}
    </>
  );
}
