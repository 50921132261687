import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import CreateCancelPolicy from "../packageCreateComponents/packageDetailComponents/CreateCancelPolicy";

export default function EditCancelPolicy({ handleAddData, handleCancel }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      <div className="flex flex-col">
        <p className="ml-4 mt-4">
          Cancellation Policy:{" "}
          {handleCancel && (
            <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
              {handleCancel} hours
            </span>
          )}
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>

        {edit && <CreateCancelPolicy handleAddData={handleAddData} />}
      </div>
    </>
  );
}
