// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
// const { getStorage, connectStorageEmulator } = require("firebase/storage");
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getMessaging, getToken } from "firebase/messaging";
import { initializeFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const hostname = window.location.hostname;
// console.log("hostname: ", hostname);
const app =
  hostname === "localhost" || hostname === "127.0.0.1"
    ? initializeApp({
        apiKey: "demo-key",
        authDomain: "demo-domain",
        projectId: "demo-project",
        storageBucket: "demo-project.appspot.com",
        appId: "demo-appId",
        messagingSenderId: "demo-id",
        measurementId: "demo-measurementId",
      })
    : initializeApp({
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: process.env.REACT_APP_AUTHDOMAIN,
        projectId: process.env.REACT_APP_PROJECTID,
        storageBucket: process.env.REACT_APP_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_APPID,
        measurementId: process.env.REACT_APP_MEASUREMENTID,
      });

initializeFirestore(app, { ignoreUndefinedProperties: true });
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);
// db.settings({ ignoreUndefinedProperties: true });
// export const analytics = () => {
//   if (hostname === "localhost") {
//     return;
//   } else {
//     return getAnalytics(app);
//   }
// };

// console.log(analytics);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log(permission);
  if (permission === "granted") {
    try {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID,
      });
      console.log("Random: ", token);
    } catch (error) {
      console.log(error);
    }
  }
};

if (hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
