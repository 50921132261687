import React, { useState, useEffect } from "react";

//Components
import ImageUploadSubmit from "./individualComponents/ImageUploadSubmit";
import ImageUpload from "./individualComponents/ImageUpload";
import EditImageGallery from "./individualComponents/EditImageGallery";
// import ImageDisclaimer from "./individualComponents/ImageDisclaimer";
import EditImages from "./individualComponents/EditImages";

//Others
import { useParams } from "react-router-dom";
import Loading from "../Loading";
// import { getAuth } from "firebase/auth";

export default function Images() {
  //identify current user
  const params = useParams();
  const { user } = params;
  const imageFolder = "images";
  const maxImages = 100;

  //   const [profile, setProfile] = useState("");

  const [images, setImages] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);

  useEffect(() => {
    console.log("Are the images loading? ", imageUploading);
  });

  const imageIsLoading = () => {
    setImageUploading(true);
  };

  const imageNotLoading = () => {
    setImageUploading(false);
  };

  const onImageSubmit = async (e) => {
    e.preventDefault();

    ImageUploadSubmit(
      images,
      user,
      imageIsLoading,
      imageNotLoading,
      imageFolder
    );
  };

  return (
    <>
      {imageUploading ? (
        <Loading />
      ) : (
        <>
          <form onSubmit={onImageSubmit}>
            <ImageUpload setImages={setImages} />
            <button className="block mx-auto mt-4 p-4 border-2 border-orange-400">
              Upload Images
            </button>
          </form>
          <EditImageGallery
            user={user}
            imageFolder={imageFolder}
            maxImages={maxImages}
          />
          {/* <EditImages user={user} /> */}
        </>
      )}
    </>
  );
}
