import { useState } from "react";
import { BiEdit } from "react-icons/bi";
import CreatePackageName from "../packageCreateComponents/packageDetailComponents/CreatePackageName";

export default function EditPackageName({ handleAddData, handlePackageName }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      {handlePackageName && (
        <>
          <div className="ml-4 flex items-center">
            <p className="">Package Name:</p>
            <p className="ml-4 bg-blue-400 border-blue-400 border p-1 rounded shadow-lg">
              {handlePackageName}
            </p>
            <BiEdit
              onClick={onClick}
              className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
            />
          </div>

          {edit && <CreatePackageName handleAddData={handleAddData} />}
        </>
      )}
    </>
  );
}
