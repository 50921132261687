export const photoSpecialisationArray = [
  {
    id: "photo_expertise",
    title: "Events",
    value: "events",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Baby",
    value: "baby",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Wedding",
    value: "wedding",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Family",
    value: "family",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Sports",
    value: "sports",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Fashion",
    value: "fashion",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Commercials",
    value: "commercials",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Pets",
    value: "pets",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Documentaries",
    value: "documentaries",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Headshots",
    value: "headshots",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Property",
    value: "property",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Boudoir",
    value: "boudoir",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Fine Art",
    value: "fine_Art",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Conceptual",
    value: "conceptual",
    type: "checkbox",
  },
  {
    id: "photo_expertise",
    title: "Editorial",
    value: "editorial",
    type: "checkbox",
  },
];
