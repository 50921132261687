import React, { useEffect, useState } from "react";

function CreatePrice({ addData, proPackage, packageDocReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("price");

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  useEffect(() => {
    if (proPackage && packageDocReady) {
      setData(proPackage.price);
    }
  }, [packageDocReady]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setData(value);
    setDataId(id);
    // addData(id, value);
  };

  return (
    <>
      <div className="m-4">
        <label>
          <span>Price</span>
          <input
            type="number"
            id="price"
            value={data}
            onChange={onChange}
            min="100"
            className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
          />
        </label>
      </div>
    </>
  );
}

export default CreatePrice;
