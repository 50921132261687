import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { serverTimestamp, doc, getDoc, setDoc } from "firebase/firestore";
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase";

export default function OAuth() {
  const navigate = useNavigate();

  const onGoogleClick = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log(user);

      // check for users
      const docRef = doc(db, `profiles/${user.uid}`);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        await setDoc(docRef, {
          userName: user.displayName,
          email: user.email,
          initiatedAt: serverTimestamp(),
          publish: false,
        });
      }

      navigate("/");
    } catch (error) {
      toast.error("Could not authorise with Google");
      console.log(error.message);
    }
  };
  return (
    <div className="w-full">
      <button
        onClick={onGoogleClick}
        className="mx-auto flex items-center rounded justify-center w-11/12 sm:w-1/3 bg-blue-700 text-white px-7 py-3 uppercase text-sm font-medium hover:bg-blue-800 active:bg-blue-900"
      >
        <FcGoogle className="text-2xl bg-white rounded-full mr-2" />
        Continue with Google{" "}
      </button>
      ;
    </div>
  );
}
