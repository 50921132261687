import { useState } from "react";
import CreateNoOfVideos from "../packageCreateComponents/packageDetailComponents/CreateNoOfVideos";
import { BiEdit } from "react-icons/bi";

export default function EditNoOfVideos({ handleAddData, handleVideoNo }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };
  return (
    <>
      {handleVideoNo && (
        <p className="m-4">
          Number of Videos:{" "}
          <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
            {handleVideoNo}
          </span>
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>
      )}
      {edit && <CreateNoOfVideos handleAddData={handleAddData} />}
    </>
  );
}
