import React, { useCallback, useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";

import FAQQuestion from "../components/faqCreateComponents/FAQQuestion";
import FAQAnswer from "../components/faqCreateComponents/FAQAnswer";
import FAQCategory from "../components/faqCreateComponents/FAQCategory";

import { toast } from "react-toastify";

export default function FAQCreate() {
  const [formData, setFormData] = useState([]);

  const addData = useCallback((id, data) => {
    console.log("Add Data: ", id, data);
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);

    try {
      await addDoc(collection(db, "FAQs/"), {
        ...formData,
        createdAt: serverTimestamp(),
        adminPublish: "false",
      });
      toast.success("Your FAQ has been submitted");
      window.location.reload();
    } catch (error) {
      console.log(error.message);
      toast.error("There was a problem uploading your package");
    }
  };
  return (
    <>
      <div className="flex">
        <div className="w-4/5 mx-auto my-2">
          <form onSubmit={onSubmit}>
            <FAQQuestion addData={addData} />
            <FAQAnswer addData={addData} />
            <FAQCategory addData={addData} />
            <button className="bg-orange-500 hover:bg-orange-600 active:bg-orange-700 cursor-pointer p-2 m-2 shadow">
              Submit FAQ
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
