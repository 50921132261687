import React from "react";

export default function Flash({ className }) {
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
      >
        <g id="a" />
        <g id="b" />
        <g id="c" />
        <g id="d" />
        <g id="e" />
        <g id="f" />
        <g id="g" />
        <g id="h" />
        <g id="i" />
        <g id="j" />
        <g id="k" />
        <g id="l" />
        <g id="m" />
        <g id="n">
          <g>
            <path d="M48.536,3.464s-3.614-3.464-3.879-3.464H19.343c-.265,0-3.879,3.464-3.879,3.464-.812,.813-3.464,4.048-3.464,11.536s2.651,10.722,3.464,11.536c0,0,1.401,1.346,1.536,1.396v16.068c0,1.887,.872,5.292,4,5.897,0,0,.275,.915,.684,1.051l2.316,.772v3.279c0,.266,.586,1,.586,1h-2.586c-1.654,0-3,1.346-3,3v2c0,.553,.448,1,1,1h8c-.552,0-1,.447-1,1s.448,1,1,1h8c.552,0,1-.447,1-1s-.448-1-1-1h8c.552,0,1-.447,1-1v-2c0-1.654-1.346-3-3-3h-2.586s.586-.734,.586-1v-3.279l2.316-.772c.408-.136,.684-1.051,.684-1.051,3.128-.605,4-4.011,4-5.897V27.932c.135-.05,1.536-1.396,1.536-1.396,.812-.813,3.464-4.048,3.464-11.536s-2.651-10.722-3.464-11.536Zm-5.536,55.536v1H21v-1c0-.552,.449-1,1-1h20c.551,0,1,.448,1,1Zm0-11.174v-6.826c0-.553-.448-1-1-1s-1,.447-1,1v8.279l-2.316,.772c-.408,.136-.684,.518-.684,.948v3.586l-1.414,1.414h-9.172l-1.414-1.414v-3.586c0-.431-.275-.812-.684-.948l-2.316-.772v-8.279c0-.553-.448-1-1-1s-1,.447-1,1v6.822c-1.955-.754-1.999-3.682-2-3.822V28h26v16c0,.142-.027,3.076-2,3.826Zm4.122-22.704l-.879,.878H17.757l-.878-.878c-.481-.481-2.879-3.229-2.879-10.122s2.398-9.641,2.878-10.122l2.879-2.878h24.485l2.878,2.878c.481,.481,2.879,3.229,2.879,10.122s-2.398,9.641-2.878,10.122Z" />
            <path d="M45.707,6.293c-.188-.188-.442-.293-.707-.293H19c-.265,0-.52,.105-.707,.293-.235,.234-2.293,2.473-2.293,8.707s2.058,8.473,2.293,8.707c.188,.188,.442,.293,.707,.293h26c.265,0,.52-.105,.707-.293,.235-.234,2.293-2.473,2.293-8.707s-2.058-8.473-2.293-8.707Zm-1.204,15.707H19.497c-.465-.721-1.497-2.796-1.497-7s1.037-6.29,1.495-7h25.009c.465,.721,1.497,2.796,1.497,7s-1.031,6.279-1.497,7Z" />
            <path d="M36,41h-8c-1.654,0-3,1.346-3,3v2c0,1.654,1.346,3,3,3h8c1.654,0,3-1.346,3-3v-2c0-1.654-1.346-3-3-3Zm1,5c0,.552-.449,1-1,1h-8c-.551,0-1-.448-1-1v-2c0-.552,.449-1,1-1h8c.551,0,1,.448,1,1v2Z" />
            <path d="M22,30c-.552,0-1,.448-1,1v4c0,.553,.448,1,1,1s1-.447,1-1v-4c0-.552-.448-1-1-1Z" />
            <path d="M42,30c-.552,0-1,.448-1,1v4c0,.553,.448,1,1,1s1-.447,1-1v-4c0-.552-.448-1-1-1Z" />
            <path d="M38,30c-.552,0-1,.448-1,1v4c0,.552-.449,1-1,1h-8c-.551,0-1-.448-1-1v-4c0-.552-.448-1-1-1s-1,.448-1,1v4c0,1.654,1.346,3,3,3h8c1.654,0,3-1.346,3-3v-4c0-.552-.448-1-1-1Z" />
            <path d="M41,10H23c-.552,0-1,.448-1,1s.448,1,1,1h18c.552,0,1-.448,1-1s-.448-1-1-1Z" />
            <path d="M41,14H23c-.552,0-1,.448-1,1s.448,1,1,1h18c.552,0,1-.448,1-1s-.448-1-1-1Z" />
            <path d="M41,18H23c-.552,0-1,.448-1,1s.448,1,1,1h18c.552,0,1-.448,1-1s-.448-1-1-1Z" />
          </g>
        </g>
        <g id="o" />
        <g id="p" />
        <g id="q" />
        <g id="r" />
        <g id="s" />
        <g id="t" />
        <g id="u" />
        <g id="v" />
        <g id="w" />
        <g id="x" />
        <g id="y" />
        <g id="a`" />
        <g id="aa" />
        <g id="ab" />
        <g id="ac" />
        <g id="ad" />
      </svg>
    </>
  );
}
