import { useState } from "react";
import CreateServices from "../packageCreateComponents/packageDetailComponents/CreateServices";
import { BiEdit } from "react-icons/bi";

export default function EditServices({
  handleAddData,
  handlePhotography,
  handleVideography,
}) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      <div className="flex items-center">
        <p className="ml-4">
          Profession:
          {handlePhotography && (
            <span className="bg-blue-400 border-blue-400 border p-1 m-1 rounded shadow-lg align-middle">
              {handlePhotography}
            </span>
          )}
          {handleVideography && (
            <span className="bg-blue-400 border-blue-400 border p-1 m-1 rounded shadow-lg align-middle">
              {handleVideography}
            </span>
          )}
        </p>
        <BiEdit
          onClick={onClick}
          className=" text-orange-400 m-1 w-8 h-8 cursor-pointer"
        />
        {edit && (
          <div className="block">
            <CreateServices handleAddData={handleAddData} />
          </div>
        )}
      </div>
    </>
  );
}
