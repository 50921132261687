import React, { useEffect, useState } from "react";

export default function CreateCancelPolicy({
  addData,
  proPackage,
  packageDocReady,
}) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("cancellationPolicy");

  useEffect(() => {
    if (proPackage & packageDocReady) {
      setData(proPackage.cancellationPolicy);
    }
  }, [packageDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setData(value);
    setDataId(id);
  };
  return (
    <>
      <div className="flex items-center m-4">
        <h3>Cancellation policy:</h3>
        <select
          required
          id="cancellationPolicy"
          name="cancellationPolicy"
          onChange={onChange}
          className="ml-2 border-orange-400 hover:border-orange-400"
        >
          <option
            value=""
            defaultValue="Please Select"
            selected={data === ""}
            disabled
          >
            Please Select
          </option>
          <option selected={data === "1"} value={1}>
            1 hour
          </option>
          <option selected={data === "6"} value={6}>
            6 hours
          </option>
          <option selected={data === "24"} value={24}>
            24 hours
          </option>
          <option selected={data === "72"} value={72}>
            3 days
          </option>
          <option selected={data === "168"} value={168}>
            7 days
          </option>
          <option selected={data === "336"} value={336}>
            14 days
          </option>
          <option selected={data === "672"} value={672}>
            28 days
          </option>
        </select>
      </div>
    </>
  );
}
