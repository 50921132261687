import React, { useState, useEffect } from "react";

export default function CreateProvince({ addData, profile, profileDocReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("province");

  useEffect(() => {
    if (profileDocReady && profile.province) {
      setData(profile.province);
    }
  }, [profileDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { id, value } = e.target;

    setData(value);
    setDataId(id);
    // console.log(value);
  };

  return (
    <>
      <div className="m-4">
        <h3>In which province are you based?</h3>
        <div className="flex items-center mb-4">
          <select required id="province" name="province" onChange={onChange}>
            <option
              value=""
              defaultValue="Please Select"
              selected={data === ""}
              disabled
            >
              Please Select
            </option>
            <option selected={data === "Eastern Cape"} value="Eastern Cape">
              Eastern Cape
            </option>
            <option selected={data === "Free State"} value="Free State">
              Free State
            </option>
            <option selected={data === "Gauteng"} value="Gauteng">
              Gauteng
            </option>
            <option selected={data === "KwaZulu-Natal"} value="KwaZulu-Natal">
              KwaZulu-Natal
            </option>
            <option selected={data === "Limpopo"} value="Limpopo">
              Limpopo
            </option>
            <option selected={data === "Mpumalanga"} value="Mpumalanga">
              Mpumalanga
            </option>
            <option selected={data === "North West"} value="North West">
              North West
            </option>
            <option selected={data === "Northern Cape"} value="Northern Cape">
              Northern Cape
            </option>
            <option selected={data === "Western Cape"} value="Western Cape">
              Western Cape
            </option>
          </select>
        </div>
      </div>
    </>
  );
}
