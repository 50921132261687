import React, { useState, useEffect } from "react";

export default function StudioName({ addData, studio, docReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("name");

  useEffect(() => {
    if (docReady && studio?.name) {
      setData(studio.name);
    }
  }, [docReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { id, value } = e.target;

    setData(value);
    setDataId(id);
  };

  return (
    <>
      <div className="m-5">
        <label className="">
          <span className="mr-2">Studio Name</span>
          <input
            className="w-full md:w-1/3 border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
            required
            type="text"
            id="name"
            value={data}
            onChange={onChange}
          />
        </label>
      </div>
    </>
  );
}
