import React from "react";

export default function Testimonials() {
  const testimonial = [
    {
      name: "Matshepo Msibi",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,f-auto/photo2.jpg?updatedAt=1685403665388",
      title: "CEO of Starfish Foundation",
      event: "Golf Day",
      text: "Wide range of professionals to choose from for our company's golf day which included drone video. I'm very impressed with BraThula.",
    },
    {
      name: "Thabiso Tshabalala",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,f-auto/IMG-20220920-WA0007.jpg?updatedAt=1685403666060",
      title: "Director of Khameleon Kreatives",
      event: "Theatre Production EPK",
      text: "Finding videographers that understood the brief was key. BraThula not only provided that but they also gave input on what would and wouldn't work.",
    },
    {
      name: "Itumeleng Makgato",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,f-auto/IMG-20230530-WA0004.jpg?updatedAt=1685437717643",
      title: "Owner of Something About Francis",
      event: "Corporate Photoshoot",
      text: "The BraThula platform simplified the process of finding reputable and experienced photographers when we needed them for client content.  The photographers on the platform are professional, creative and execute briefs very well.",
    },
    {
      name: "Mary Mutlanyane",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,f-auto/SmartSelect_20230530-113004_Instagram.jpg?updatedAt=1685440026985",
      title: "SQ Global Earth Finalist",
      event: "High Tea",
      text: "I'm very happy with BraThula, I will definitely use them for upcoming events.",
    },
    {
      name: "Roche Mamabolo",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,f-auto/roche.jpeg?updatedAt=1697273329892",
      title: "LORA Centre for Innovation and Entrepreneurship Founder",
      event: "My Biggest Blunder Recordings",
      text: "The BraThula platform is user-friendly, smooth, intuitive, and efficient. With a few clicks, you can browse a catalogue of visual artists' styles and portfolios. It is an aesthetic journey. Booking a photographer has never been easier. It is the 'Uberisation' of visual arts, where you arrange a shoot, specify your preferences, and let the pros do the rest. In a world when time is everything, BraThula's efficiency is remarkable",
    },
    {
      name: "Rorisang Moeletsi",
      src: "https://ik.imagekit.io/ajuaxvsrja/tr:w-128,h-128,f-auto/Moretele-FM_logo.jpeg?updatedAt=1697272913564",
      title: "Moretele FM Community Radio Marketing Consultant",
      event: "Community Radio Sports Day and Birthday Celebration",
      text: "We were provided with nothing short of exceptional - high-quality content that exceeded my expectations. I highly recommend using the BraThula network for anyone in need of outstanding visual content.",
    },
  ];

  return (
    <>
      <div className="my-5 md:my-20">
        <h1 className="font-light text-center text-2xl md:text-3xl">
          Our Customers
        </h1>
        <div className="w-full h-fit grid grid-cols-1 grid-rows-4 md:grid-cols-2 md:grid-rows-2 gap-4">
          {testimonial.map((item) => {
            return (
              <div
                key={item.name}
                className="bg-gray-100 p-2 shadow-sm rounded"
              >
                <img
                  className="p-1 mx-auto ring-2 ring-orange-400 rounded-full block h-32 w-32 object-cover object-center"
                  src={item.src}
                  alt={item.name}
                />
                <h1 className="text-center text-xl font-light">{item.name}</h1>
                <h2 className="text-center font-light">{item.title}</h2>
                <h6 className="text-sm text-center">{item.event}</h6>
                <blockquote className="text-6xl font-bold">
                  <p className="mx-auto text-center text-sm px-1 w-full md:w-4/5 font-light">
                    {`"${item.text}"`}
                  </p>
                </blockquote>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
