import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import CreatePhotoEquipment from "../packageCreateComponents/packageDetailComponents/CreatePhotoEquipment";

export default function EditPhotoEquipment({
  handleAddData,
  handlePhotoEquipment,
}) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      <p className="m-4">Photo Equipment</p>
      <ul className="flex flex-wrap ml-4">
        {handlePhotoEquipment.map((item) => {
          let result = item.replace("_", " ");
          return (
            <li
              className="capitalize bg-blue-400 border-blue-400 border p-1 m-1 rounded shadow-lg"
              key={item}
            >
              {result}
            </li>
          );
        })}
        <BiEdit
          onClick={onClick}
          className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
        />
      </ul>
      {edit && <CreatePhotoEquipment handleAddData={handleAddData} />}
    </>
  );
}
