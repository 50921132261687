// import React from "react";

import { db, storage } from "../../../firebase";
import { v4 as uuidv4 } from "uuid";
import { ref, getDownloadURL, uploadBytesResumable } from "@firebase/storage";
import { toast } from "react-toastify";
import { serverTimestamp, addDoc, collection } from "@firebase/firestore";

export default async function VideoUploadSubmit(
  videos,
  user,
  videoIsLoading,
  videoNotLoading,
  updateVideoProgress,
  maxVideoSize
) {
  const videoLoadingPromises = [];

  if (videos.length === 0) {
    toast.error("Please upload a video");
    return;
  }
  if (videos.length > 0) {
    videoIsLoading();
    videos.forEach((video) => {
      const uploadPromise = new Promise((resolve) => {
        const storageRef = ref(
          storage,
          "videos/" + user + "/" + uuidv4() + "-" + video.name
        );

        const uploadTask = uploadBytesResumable(storageRef, video);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log(
            //   video.name + ": Upload is " + Math.ceil(progress) + "% done"
            // );
            updateVideoProgress(Math.ceil(progress));
          },
          (error) => {
            // Handle unsuccessful uploads
            toast.error(
              `There was an error uploading your video. Please make sure the format is .mp4, .avi or .mov and its less than ${maxVideoSize}MB.`
            );
            console.log(error.message);
            videoNotLoading();
            // setVideoUploading(false);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              // console.log(user);

              try {
                addDoc(collection(db, "videos"), {
                  videoURL: downloadURL,
                  videoName: video.name,
                  createdAt: serverTimestamp(),
                  user: user,
                });
                toast.success(video.name + " has been uploaded");
              } catch (error) {
                console.log(error);
                toast.error(
                  `There was an error uploading your video. Please make sure the format is .mp4, .avi or .mov and its less than 150MB.`
                );
                videoNotLoading();
                // setVideoUploading(false);
                return;
              }
              resolve();
            });
          }
        );
      });

      videoLoadingPromises.push(uploadPromise);
    });
  }
  await Promise.all(videoLoadingPromises);
  videoNotLoading();
  //   setVideoUploading(false);
}
