import React from "react";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
const navigate = useNavigate()

  return (
    <div onClick={() => navigate("/forgotpassword") } className="text-blue-600 hover:text-blue-700 cursor-pointer pt-2">
      Forgot password?
    </div>
  );
}
