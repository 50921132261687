import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import { GiFlowers } from "react-icons/gi";
import { TbPackages } from "react-icons/tb";

export default function PackageListButton({ profile }) {
  const navigate = useNavigate();
  const { user } = useParams();

  const hasPackages = profile.packagesPublished ? true : false;

  // useEffect(() => {
  //   console.log("hasPackages: ", hasPackages);
  //   console.log("Packages Published ", profile.packagesPublished);
  // });

  return (
    <div>
      <button
        onClick={() => navigate(`/propackages/${user}`)}
        className={`${
          hasPackages
            ? "bg-blue-400 hover:bg-blue-500 active:bg-blue-600"
            : "bg-gray-400 cursor-not-allowed"
        } block mx-auto my-2 p-2 border rounded-md shadow text-white text-lg sm:text-xl`}
        disabled={!hasPackages}
      >
        <TbPackages className="inline-block mr-2" />
        View Packages
      </button>
    </div>
  );
}
