import React, { useEffect, useState } from "react";

export default function RandPerKM({
  // distanceBand,
  // distanceRate,
  addData,
  grace,
  profile,
}) {
  const [rateChange, setRateChange] = useState();
  const [showDefinition, setShowDefinition] = useState(false);
  const exBillableDistance = grace * 2.5 - grace;
  const exBillableRate = (grace * 2.5 - grace) * rateChange;

  useEffect(() => {
    if (profile.trRandPerKM) {
      setRateChange(profile.trRandPerKM);
    }
  }, []);

  const onChange = (e) => {
    const { id, value } = e.target;
    setRateChange(value);
    addData(id, value);
    // console.log(id, value);
  };

  return (
    <>
      {grace && (
        <div className="shadow p-4 my-5">
          <h3 className="text-2xl py-5">Extended Distances</h3>
          <p>
            R/KM for distances greater than{" "}
            <em className="font-bold text-orange-400">{grace}km</em>.
          </p>
          <div className="" onChange={onChange}>
            <label className="">
              <span className="mr-2">Rand/km Rate</span>
              <input
                className="w-20 border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
                required
                type="number"
                id="trRandPerKM"
                max={20}
                min={1}
                step=".01"
                defaultValue={rateChange}
              />
            </label>
          </div>
          {showDefinition === true ? (
            <>
              <div
                className="my-5 cursor-pointer text-blue-600 w-full md:w-4/5"
                onClick={() => setShowDefinition(false)}
              >
                <p>
                  Rand Per km(R/km): an amount charged to a customer for every
                  kilometre travelled. According to SARS, the prescribed R/km,
                  for business travel, by the Minister of Finance is{" "}
                  <em className="font-bold">R4.64/km</em> as of 1st March 2023.
                </p>
                <p>
                  The{" "}
                  <a
                    className="hover:underline font-bold"
                    href="https://aa.co.za/vehicle-rates-calculator/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Automobile Association(AA)
                  </a>{" "}
                  provides a Vehicle Rates Calculator that calculates the R/km
                  for your specific vehicle.
                </p>
                <p>
                  Ride share services like Uber, Bolt etc use dynamic pricing
                  which is typically between{" "}
                  <em className="font-bold">R7-15/km</em>.
                </p>
              </div>
            </>
          ) : (
            <div
              className="cursor-pointer hover:underline"
              onClick={() => setShowDefinition(true)}
            >
              <p className="text-blue-600 font-bold text-lg my-5 hover:underline cursor-pointer">
                What is <em className="font-bold">R/km</em>?
              </p>
            </div>
          )}
          <p>
            Customer will have a free transport distance of{" "}
            <em className="font-bold text-orange-400">{grace}km</em>.
          </p>
          {rateChange && (
            <div className="py-5">
              <p>
                For every kilometre after{" "}
                <em className="font-bold text-orange-400">{grace}km</em>, it's
                charged at{" "}
                <em className="font-bold text-orange-400">R{rateChange}/km</em>.
              </p>
              <p>
                Example: if a customer is{" "}
                <em className="font-bold text-orange-400">{grace * 2.5}km</em>{" "}
                away. The first{" "}
                <em className="font-bold text-orange-400">{grace}km</em> are
                free, the next{" "}
                <em className="font-bold text-orange-400">
                  {exBillableDistance}km
                </em>{" "}
                is{" "}
                <em className="font-bold text-orange-400">
                  R{exBillableRate.toFixed(2)}
                </em>{" "}
                one-way and{" "}
                <em className="font-bold text-orange-400">
                  R{(2 * exBillableRate).toFixed(2)}
                </em>{" "}
                both ways.
              </p>
              <br />
              <p>
                The total cost is{" "}
                <em className="font-bold text-orange-400">
                  R{(2 * exBillableRate).toFixed(2)}
                </em>{" "}
                .
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
