import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

import FAQCustomers from "../components/faqShowComponents/FAQCustomers";
import FAQPros from "../components/faqShowComponents/FAQPros";

export default function FAQs() {
  const [customerFAQs, setCustomerFAQs] = useState([]);
  const [proFAQs, setProFAQs] = useState([]);

  const [showCustomers, setShowCustomers] = useState(true);
  const [showPros, setShowPros] = useState(false);

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "FAQs");
      const q = query(
        colRef,
        where("category", "==", "customerFAQs"),
        where("adminPublish", "==", "true"),
        orderBy("order")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => {
        // console.log(doc.id, " => ", doc.data());
        // console.log("Doc: ", doc);
        return doc;
      });

      setCustomerFAQs(docs);
      console.log("CustomerFAQs: ", docs);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "FAQs");
      const q = query(
        colRef,
        where("category", "==", "proFAQs"),
        where("adminPublish", "==", "true"),
        orderBy("order")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => {
        // console.log(doc.id, " => ", doc.data());
        // console.log("Doc: ", doc);
        return doc;
      });

      setProFAQs(docs);
      console.log("ProFAQs: ", docs);
    })();
  }, []);

  const onShowCustomerFAQs = () => {
    setShowCustomers(true);
    setShowPros(false);
  };

  const onShowProFAQs = () => {
    setShowCustomers(false);
    setShowPros(true);
  };

  return (
    <>
      <div className="bg-gray-200 p-1 md:p-5">
        <h1 className="text-center text-xl mt-10 md:mt-0 font-light">
          Frequently Asked Questions
        </h1>

        <div className="w-full sm:w-4/5 container px-2 mx-auto pt-1">
          <div className="w-full mx-auto px-1">
            {/* <h1 className="text-center mt-10">Edit Your Portfolio</h1> */}
            <div className="w-full inline-flex justify-center py-4">
              <button
                onClick={onShowCustomerFAQs}
                className={`${
                  showCustomers
                    ? "bg-orange-400 hover:bg-orange-500"
                    : "bg-white hover:bg-gray-100"
                }  text-gray-800 font-bold py-2 px-4 border-gray-200 rounded-l-lg transition-all`}
              >
                Customers
              </button>
              <button
                onClick={onShowProFAQs}
                className={`${
                  showPros
                    ? "bg-orange-400 hover:bg-orange-500"
                    : "bg-white hover:bg-gray-100"
                }  text-gray-800 font-bold py-2 px-4 border-gray-200 rounded-r-lg transition-all `}
              >
                Professionals
              </button>
            </div>
            {showCustomers ? (
              <FAQCustomers customerFAQs={customerFAQs} />
            ) : (
              <FAQPros proFAQs={proFAQs} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
