import React, { useEffect, useState } from "react";
import { photoEquipmentArray } from "../../../assets/globalArrays/photoEquipmentArray";

export default function CreatePhotoEquipment({
  addData,
  proPackage,
  packageDocReady,
}) {
  const [data, setData] = useState([]);
  const [dataId, setDataID] = useState("photoEquipment");
  const [error, setError] = useState(false);
  const iconClasses = "w-10 h-10 fill-current text-orange-700";

  useEffect(() => {
    if (packageDocReady && proPackage.photoEquipment) {
      setData(proPackage.photoEquipment);
    }
  }, [packageDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  useEffect(() => {
    //Need to think through this part for Equipment
    if (data.length === 0) {
      setError(true);
      return;
    } else {
      addData(dataId, data);
      setError(false);
      // console.log(dataId, data);
    }
  }, [data, dataId, addData]);

  const onChange = (e) => {
    const { id, value, checked } = e.target;
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }

    setDataID(id);
  };
  return (
    <>
      <div>
        <h3 className="ml-4 font-semibold text-gray-900">
          Photography Equipment
        </h3>
        <p className="ml-4 mb-4 text-sm">Please specify equipment</p>
        {error && (
          <p className="ml-4 text-red-600 text-xs">Please select atleast 1</p>
        )}
        <div>
          <ul className="m-4 w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
            {photoEquipmentArray(iconClasses).map((item) => (
              <li
                key={item.value}
                className="w-full border-b border-gray-200 rounded-t-lg"
              >
                <div className="flex items-center pl-3">
                  <label className="w-full py-3 ml-2 text-sm font-medium text-gray-900 ">
                    <input
                      id={item.id}
                      type={item.type}
                      value={item.value}
                      checked={data.includes(item.value)}
                      onChange={onChange}
                      className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                    />
                    <span className="ml-2">{item.title}</span>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
