import React, { useState, useEffect } from "react";

export default function StudioBasePrice({ addData, studio, docReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("baseRate");

  useEffect(() => {
    if (docReady && studio) {
      setData(studio.baseRate);
    }
  }, [docReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setData(value);
    setDataId(id);
  };

  return (
    <>
      <div className="m-4">
        <h3>Starting Rate/hour (including professional)</h3>
        <input
          type="number"
          id="baseRate"
          onChange={onChange}
          min="100"
          value={data}
          required
          className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
        />
      </div>
    </>
  );
}
