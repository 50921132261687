import React, { useEffect, useState } from "react";
import { publishArray } from "./arrays/publishArray";

export default function CreatePackagePublish({
  addData,
  packageDocReady,
  proPackage,
}) {
  const [data, setData] = useState();
  const [dataId, setDataId] = useState("userPublish");
  // const [publishID, setPublishID] = useState("");

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.userPublish);
    }
  }, [packageDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  // useEffect(() => {
  //   console.log("Data: ", data);
  //   console.log("UserPublish: ", proPackage.userPublish);
  // });

  const onChange = (e) => {
    const { value, id } = e.target;

    setData(value);
    setDataId(id);
  };

  return (
    <>
      <div className="m-4">
        <h3>Publish this package?</h3>
        {data === "true" && (
          <p className="text-xs text-blue-500">
            This package will be reviewed and activated in 24hrs.
          </p>
        )}
        {data === "false" && (
          <p className="text-xs text-blue-500">
            This package will remain as a draft in your account
          </p>
        )}

        <div onChange={onChange} className="flex items-center mb-4">
          <ul className="flex w-1/2">
            {publishArray.map((item) => {
              return (
                <li key={item.value} className="w-full border-gray-200">
                  <input
                    id={item.id}
                    type={item.type}
                    value={item.value}
                    name={item.name}
                    checked={data === item.value}
                    required
                    className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded-full focus:ring-orange-400 focus:ring-2"
                  />
                  <label className="w-full py-3 ml-2 text-gray-900 ">
                    {item.title}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
