import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import CreatePackagePublish from "../packageCreateComponents/packageDetailComponents/CreatePackagePublish";

export default function EditPackagePublish({
  handleAddData,
  handlePackagePublish,
}) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };

  return (
    <>
      {handlePackagePublish && (
        <p className="m-4">
          Publish this Package?{" "}
          <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
            {handlePackagePublish}
          </span>
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>
      )}
      {edit && <CreatePackagePublish handleAddData={handleAddData} />}
    </>
  );
}
