import { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  // geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

export default function StudioAddress({
  addData,
  studio,
  docReady,
  addressError,
}) {
  // const [isProvince, setIsProvince] = useState();
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [selectPrompt, setSelectPrompt] = useState(false);

  useEffect(() => {
    if (docReady && studio) {
      setAddress(studio.address);
      setRegion(studio.region);
      setCoordinates(studio.coordinates);
    }
  }, [docReady]);

  useEffect(() => {
    const work = addData("region", region);

    return work;
  }, [region, addData]);

  useEffect(() => {
    const work = addData("address", address);

    return work;
  }, [address, addData]);

  useEffect(() => {
    const work = addData("coordinates", coordinates);

    return work;
  }, [coordinates, addData]);

  useEffect(() => {
    if (selectPrompt === true) {
      setRegion("");
    }
  }, [selectPrompt]);

  const onChange = (value) => {
    setAddress(value);
    setSelectPrompt(true);
  };

  const handleSelect = async (value) => {
    setSelectPrompt(false);
    const result = await geocodeByAddress(value);
    const components = result[0].address_components;
    console.log("Result: ", result);
    // setAddress(result[0].address_components);

    // if (result[0].types[0] === "administrative_area_level_1") {
    //   setIsProvince(true);
    // } else if (result[0].address_components[3].short_name === "ZA") {
    //   setIsProvince(false);
    //   setRegion(
    //     result[0].address_components[0].long_name +
    //       ", " +
    //       result[0].address_components[2].short_name
    //   );
    // } else {
    //   setIsProvince(false);
    //   setRegion(
    //     result[0].address_components[0].long_name +
    //       ", " +
    //       result[0].address_components[3].short_name
    //   );
    // }

    if (components.length <= 5) {
      // console.log("The length is 5 or less");
      setRegion(components[0].long_name + ", " + components[2].short_name);
    } else if (components.length === 6) {
      // console.log("The length is 6");
      setRegion(components[2].long_name + ", " + components[4].short_name);
    } else if (components.length === 7) {
      setRegion(components[2].long_name + ", " + components[4].short_name);
      // console.log("The length is 7");
    } else if (components.length >= 8) {
      // console.log("The length is 8 or more");
      setRegion(components[3].long_name + ", " + components[5].short_name);
    }

    const coordinates = await getLatLng(result[0]);
    // console.log(coordinates);

    // console.log("Formatted Address: ", result[0].formatted_address);
    // console.log("Components: ", result[0].address_components);
    // console.log("Long Name: ", result[0].address_components[3].long_name);
    // console.log("Short Name: ", result[0].address_components[5].short_name);
    // setAddress(value);
    setCoordinates(coordinates);

    setAddress(result[0].formatted_address);
    // setRegion(
    //   result[0].address_components[3].long_name +
    //     ", " +
    //     result[0].address_components[5].short_name
    // );
    // console.log("Components Length: ", result[0].address_components.length);

    // handleAddData("address", result[0].formatted_address);
  };

  return (
    <>
      <div className="m-4">
        <PlacesAutocomplete
          value={address}
          onChange={onChange}
          onSelect={handleSelect}
          highlightFirstSuggestion={true}
          searchOptions={{
            componentRestrictions: { country: ["za"] },
            // types: ["address"],
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <label>
                <span className="mr-2">Studio Address</span>
                {selectPrompt && (
                  <p className="text-sm text-red-500">
                    Please select your address from the drop down suggestions
                  </p>
                )}

                <input
                  {...getInputProps({
                    placeholder: "Enter your address",
                    className: "location-search-input",
                  })}
                  required
                  className="block w-4/5 md:w-1/2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
                />
              </label>

              <div className="autocomplete-dropdown-container w-full md:w-1/2">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: "orange",
                        cursor: "pointer",
                        borderRadius: "25px",
                        paddingLeft: "10px",
                      }
                    : {
                        backgroundColor: "white",
                        cursor: "pointer",
                        color: "dimGray",
                        paddingLeft: "10px",
                      };
                  return (
                    <div
                      key={suggestion.description}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {/* {isProvince && (
          <p className="text-sm text-red-500">Enter your address</p>
        )} */}
      </div>
    </>
  );
}
