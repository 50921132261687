import React, { useState, useEffect } from "react";

export default function StudioAppConfirm({ addData, studio, docReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("appConfirmation");

  useEffect(() => {
    if (studio && docReady) {
      setData(studio.appConfirmation);
    }
  }, [docReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, data, dataId]);

  const onChange = (e) => {
    const { id, checked } = e.target;

    setData(checked);
    setDataId(id);
  };

  return (
    <>
      <div>
        <label
          htmlFor="appConfirmation"
          className="w-full py-3 ml-4 font-medium text-gray-900 "
        >
          <input
            id="appConfirmation"
            name="appConfirmation"
            type="checkbox"
            // value="true"
            required
            checked={data === true}
            className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
            onChange={onChange}
          />
          <span className="ml-2">
            Please confirm that your studio accepts appointments
          </span>
        </label>
      </div>
    </>
  );
}
