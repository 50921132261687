import React, { useState, useEffect } from "react";

export default function CreateAboutYou({ addData, profileDocReady, profile }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("aboutyou");

  useEffect(() => {
    if (profileDocReady && profile.aboutyou) {
      setData(profile.aboutyou);
    }
  }, [profileDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, data, dataId]);

  const onChange = (e) => {
    const { value, id } = e.target;

    setData(value);
    setDataId(id);
  };
  return (
    <div className="m-4">
      <label className="block mb-2">
        <span>Tell us more about yourself</span>
        <textarea
          className="block p-2 text-sm w-2/3 sm:w-3/4 text-gray-900 bg-gray-50 border-orange-400 rounded-lg border focus:border-orange-400"
          id="aboutyou"
          rows="4"
          onChange={onChange}
          name="aboutyou"
          required
          value={data}
        />
      </label>
    </div>
  );
}
