import React, { useState, useEffect } from "react";
import { studioAvailabilityArray } from "./arrays/studioAvailabilityArray";

export default function StudioAvailability({ addData, studio, docReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("availability");

  useEffect(() => {
    if (docReady && studio) {
      setData(studio.availability);
    }
  }, [docReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setData(value);
    setDataId(id);
  };

  // useEffect(() => {
  //   console.log("data: ", data);
  // }, [data]);

  return (
    <>
      <div>
        <h3 className="ml-4  font-semibold text-gray-900">
          Studio Availability
        </h3>
        <div>
          <ul className="m-4 w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
            {studioAvailabilityArray.map((item) => (
              <li
                key={item.value}
                className="w-full border-b border-gray-200 rounded-t-lg"
              >
                <div className="flex items-center pl-3">
                  <label className="w-full py-3 ml-2 text-sm font-medium text-gray-900 ">
                    <input
                      id={item.id}
                      type={item.type}
                      value={item.value}
                      name={item.name}
                      required
                      checked={data === item.value}
                      onChange={onChange}
                      // {...(studio?.studioAvailability === item.value
                      //   ? { checked: true }
                      //   : {})}
                      className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                    />
                    <span className="ml-2">{item.title}</span>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
