import React, { useState } from "react";
import CreateVideoDuration from "../packageCreateComponents/packageDetailComponents/CreateVideoDuration";
import { BiEdit } from "react-icons/bi";

export default function EditVideoDuration({
  handleAddData,
  handleVideoDuration,
}) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };
  return (
    <>
      {handleVideoDuration && (
        <p className="m-4">
          Video Duration:{" "}
          <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
            {handleVideoDuration}
          </span>
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>
      )}
      {edit && <CreateVideoDuration handleAddData={handleAddData} />}
    </>
  );
}
