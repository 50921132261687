import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import CreateProductDelivery from "../packageCreateComponents/packageDetailComponents/CreateProductDelivery";

export default function EditProductDelivery({ handleAddData, handleDelivery }) {
  const [edit, setEdit] = useState(false);

  const onClick = () => {
    setEdit(!edit);
  };
  return (
    <>
      <div className="flex flex-col">
        <p className="ml-4 mt-4">
          Product Delivery:{" "}
          <span className="bg-blue-400 border-blue-400 border p-1 rounded shadow-lg align-middle">
            {handleDelivery < 25
              ? `${handleDelivery} hours`
              : `${handleDelivery / 24} days`}
          </span>
          <BiEdit
            onClick={onClick}
            className="inline text-orange-400 m-1 w-8 h-8 cursor-pointer"
          />
        </p>

        {edit && <CreateProductDelivery handleAddData={handleAddData} />}
      </div>
    </>
  );
}
