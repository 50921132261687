export const videoSpecialisationArray = [
  {
    id: "video_expertise",
    title: "Events",
    value: "events",
    type: "checkbox",
  },
  {
    id: "video_expertise",
    title: "Wedding",
    value: "wedding",
    type: "checkbox",
  },
  {
    id: "video_expertise",
    title: "Documentary",
    value: "documentary",
    type: "checkbox",
  },
  {
    id: "video_expertise",
    title: "Drone Footage",
    value: "drone footage",
    type: "checkbox",
  },
  {
    id: "video_expertise",
    title: "Promotional Videos",
    value: "promotions",
    type: "checkbox",
  },
  {
    id: "video_expertise",
    title: "Interviews",
    value: "interviews",
    type: "checkbox",
  },
  {
    id: "video_expertise",
    title: "Podcasts",
    value: "podcasts",
    type: "checkbox",
  },
  {
    id: "video_expertise",
    title: "Tutorials",
    value: "tutorials",
    type: "checkbox",
  },
  {
    id: "video_expertise",
    title: "Webinars",
    value: "webinars",
    type: "checkbox",
  },
  {
    id: "video_expertise",
    title: "Boudoir",
    value: "boudoir",
    type: "checkbox",
  },
];
