//React
import { useEffect, useState } from "react";

//Firebase
import { db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";

import Images from "../components/portfolioUploadComponents/Images";
import Videos from "../components/portfolioUploadComponents/Videos";

//Others
import { useParams } from "react-router-dom";

export default function PortfolioUpload() {
  //identify current user
  const params = useParams();
  const { user } = params;

  const [profile, setProfile] = useState([]);
  const [docReady, setDocReady] = useState(false);

  useEffect(() => {
    const docRef = doc(db, "profiles", user);
    getDoc(docRef).then((snapshot) => {
      setProfile(snapshot.data());
      setDocReady(true);
    });
  }, [user]);

  useEffect(() => {
    if (docReady && profile.profession?.includes("photographer") === "") {
      setEditImages(false);
      setEditVideos(true);
    }
  }, [docReady, profile]);

  const [editImages, setEditImages] = useState(true);
  const [editVideos, setEditVideos] = useState(false);

  const onPhotographerClick = () => {
    setEditImages(true);
    setEditVideos(false);
    console.log("Edit Images");
  };

  const onVideographerClick = () => {
    setEditImages(false);
    setEditVideos(true);
    console.log("Edit Videos");
  };

  return (
    <>
      {" "}
      {docReady && (
        <>
          {!profile.profession?.includes("photographer") &&
          !profile.profession?.includes("videographer") ? (
            <div className="w-full">
              <p className="my-10 text-center">
                Please complete your profile before editing your portfolio
              </p>
            </div>
          ) : (
            <div className="w-full mx-auto px-1">
              <h1 className="text-center mt-10">Edit Your Portfolio</h1>
              <div className="w-full inline-flex justify-center py-4">
                {profile.profession.includes("photographer") && (
                  <button
                    onClick={onPhotographerClick}
                    className={`${
                      editImages && profile.profession.includes("photographer")
                        ? "bg-orange-400 hover:bg-orange-500"
                        : "bg-white hover:bg-gray-200"
                    }  text-gray-800 font-bold py-2 px-4 border ${
                      !profile.profession.includes("photographer")
                        ? "cursor-not-allowed bg-gray-400"
                        : ""
                    } border-gray-200 rounded-l-lg transition-all`}
                    disabled={!profile.profession.includes("photographer")}
                  >
                    Edit Images
                  </button>
                )}
                {profile.profession.includes("videographer") && (
                  <button
                    onClick={onVideographerClick}
                    className={`${
                      editVideos && profile.profession.includes("videographer")
                        ? "bg-orange-400 hover:bg-orange-500"
                        : "bg-white hover:bg-gray-200"
                    }  text-gray-800 font-bold py-2 px-4 border ${
                      !profile.profession.includes("videographer")
                        ? "cursor-not-allowed bg-gray-400"
                        : ""
                    } border-gray-200 rounded-r-lg transition-all`}
                    disabled={!profile.profession.includes("videographer")}
                  >
                    Edit Videos
                  </button>
                )}
              </div>
              {editVideos ? <Videos /> : <Images />}
            </div>
          )}
        </>
      )}
    </>
  );
}
