import { useEffect, useState } from "react";

function CreateEditing({ addData, proPackage, packageDocReady }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("shootImages");

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.shootImages);
    }
  }, [packageDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setData(value);
    setDataId(id);

    // addData(id, value);
  };

  return (
    <>
      <div className="flex items-center m-4">
        <span>Final Product</span>
        <select
          required
          id="shootImages"
          name="shootImages"
          onChange={onChange}
          className="ml-2 border-orange-400 focus:border-orange-400 "
        >
          <option
            value=""
            defaultValue="Please Select"
            selected={data === ""}
            disabled
          >
            Please Select
          </option>
          <option selected={data === "editedImages"} value="edited_images">
            Edited Images
          </option>
          <option selected={data === "rawImages"} value="raw_images">
            Raw Images
          </option>
          <option selected={data === "rawAndEdited"} value="raw_and_edited">
            Both Raw and Edited
          </option>
        </select>
      </div>
    </>
  );
}

export default CreateEditing;
