import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";

export default function Profile() {
  const params = useParams();
  const { user } = params;
  const [profile, setProfile] = useState();

  useEffect(() => {
    console.log("User: ", user.toString());
    // const docRef = doc(db, "profiles", user);
    // onSnapshot(docRef, (doc) => {
    //   console.log(doc.data(), doc.id);
    //   setProfile(doc.data());
    // });

    const docRef = doc(db, "profiles", user);
    getDoc(docRef).then((snapshot) => {
      console.log("Snapshot: ", snapshot);
      console.log("Snapshot data:, ", snapshot.data());
      setProfile(snapshot.data());
    });
  }, [user]);

  return (
    <>
      <div>Name: {profile && profile.name}</div>
      <div>Province: {profile && profile.province}</div>
      {/* <p>Profession: {profile && profile.profession[0]}</p> */}
    </>
  );
}
