export const studioAmenitiesArray = [
  {
    id: "amenities",
    title: "Restroom",
    value: "restroom",
    type: "checkbox",
    name: "amenities",
  },
  {
    id: "amenities",
    title: "Change Room",
    value: "changeRoom",
    type: "checkbox",
    name: "amenities",
  },
  {
    id: "amenities",
    title: "Waiting Area",
    value: "waitingArea",
    type: "checkbox",
    name: "amenities",
  },
  {
    id: "amenities",
    title: "Coffee/Tea Station",
    value: "coffeeStation",
    type: "checkbox",
    name: "amenities",
  },
  {
    id: "amenities",
    title: "Free Wifi",
    value: "freeWifi",
    type: "checkbox",
    name: "amenities",
  },
  {
    id: "amenities",
    title: "Private/Adult Shoot",
    value: "privateShoot",
    type: "checkbox",
    name: "amenities",
  },
  {
    id: "amenities",
    title: "Safe Parking",
    value: "safeParking",
    type: "checkbox",
    name: "amenities",
  },
  {
    id: "amenities",
    title: "Child Friendly",
    value: "childFriendly",
    type: "checkbox",
    name: "amenities",
  },
  {
    id: "amenities",
    title: "Pet Friendly",
    value: "petFriendly",
    type: "checkbox",
    name: "amenities",
  },
  {
    id: "amenities",
    title: "Backup Power",
    value: "backupPower",
    type: "checkbox",
    name: "amenities",
  },
];
