import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import PackagesInterface from "../components/packagesEditableComponents/PackagesInterface";

export default function PackagesEditable() {
  const { user } = useParams();
  const [profile, setProfile] = useState("");
  const [docReady, setDocReady] = useState(false);
  const [packagesDocReady, setPackagesDocReady] = useState(false);

  const [packages, setPackages] = useState([]);
  const [ids, setIDs] = useState([]);
  const maxPackages = 7;

  useEffect(() => {
    const docRef = doc(db, "profiles", user);
    getDoc(docRef).then((snapshot) => {
      setProfile(snapshot.data());
      setDocReady(true);
    });
  }, [user]);

  useEffect(() => {
    console.log("Profile: ", profile);
  }, [profile]);

  // const packagesLocation = query(collection(db, `packages/${user}`))
  useEffect(() => {
    (async () => {
      const colRef = collection(db, "packages");
      const q = query(colRef, where("user", "==", user));
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      const docsID = snapshots.docs.map((doc) => doc.id);
      setPackages(docs);
      setIDs(docsID);
      setPackagesDocReady(true);
    })();
  }, [user]);

  // useEffect(() => {
  //   console.log("Packages: ", packages);
  // }, [packages]);

  return (
    <>
      {docReady &&
      !profile.profession.includes("photographer") &&
      !profile.profession.includes("videographer") ? (
        <div className="w-full">
          <p className="my-10 text-center">
            Please complete your profile before creating any packages
          </p>
        </div>
      ) : (
        <PackagesInterface
          packages={packages}
          ids={ids}
          maxPackages={maxPackages}
          user={user}
          packagesDocReady={packagesDocReady}
        />
      )}
    </>
  );
}
