import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../firebase";

import ProReview from "../components/proReviewComponents/ProReview";

export default function ProReviews() {
  const [reviews, setReviews] = useState([]);
  const params = useParams();
  const { user } = params;

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "reviews");
      const q = query(
        colRef,
        where("proID", "==", user),
        orderBy("shootDate", "desc")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      setReviews(docs);
      console.log("docs: ", docs);
    })();
  }, []);

  return <ProReview reviews={reviews} />;
}
