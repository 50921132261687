import React, { useCallback, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
} from "@react-google-maps/api";

export default function Map({ profile, grace, distanceBand }) {
  // const [radius, setRadius] = useState(10000);

  // useEffect(() => {
  //   console.log("Map firstBand: ", firstBand);
  //   // console.log(typeof grace);
  // });

  const circle1Options = {
    fillColor: "#fa9204",
    fillOpacity: 0.3,
    strokeWeight: 1,
    strokeColor: "white",
    clickable: false,
    editable: false,
    zIndex: 5,
  };

  const circle2Options = {
    fillColor: "blue",
    fillOpacity: 0.3,
    strokeWeight: 1,
    strokeColor: "white",
    clickable: false,
    editable: false,
    zIndex: 1,
  };

  const center = {
    lat: profile.coordinates.lat,
    lng: profile.coordinates.lng,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_KEY,
  });

  const [map, setMap] = useState(null);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
      <div className="flex">
        <GoogleMap
          className="mx-auto"
          mapContainerStyle={{ width: "100vw", height: "100vh" }}
          center={center}
          zoom={11}
          onLoad={(map) => setMap(map)}
          onUnmount={onUnmount}
          options={{
            mapTypeControl: false,
            streetView: false,
            fullscreenControl: false,
            zoomControl: true,
          }}
        >
          <Marker position={center} />
          <Circle
            center={center}
            radius={grace * 1000}
            options={circle1Options}
          />
          <Circle
            center={center}
            radius={distanceBand * 1000}
            options={circle2Options}
          />
        </GoogleMap>
      </div>
      <p className="mt-5 font-bold italic">
        Please Note: This map is for illustrative purposes only. The transport
        cost will only be calculated using route distance rather than radius.
      </p>
    </>
  ) : (
    <></>
  );
}
