import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";

//import components
import Listing from "../components/portfoliosList/Listing";
import UserLocation from "../components/portfoliosList/UserLocation";
import PrOptions from "../components/portfoliosList/PrOptions";

export default function Portfolios() {
  const [profiles, setProfiles] = useState([]);
  const [userAddress, setUserAddress] = useState();

  const handleAddress = (data) => {
    setUserAddress(data);
  };

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "profiles");
      const q = query(
        colRef,
        where("adminPublish", "==", "true"),
        where("userPublish", "==", "true"),
        // orderBy("reviewsPublishedRating", "desc"),
        // orderBy("profileImageURL", "desc")
        orderBy("images", "desc")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      setProfiles(docs);
      // console.log("docs: ", docs);
    })();
  }, []);

  return (
    <>
      {/* <div className="flex mx-auto items-center justify-center">
        <UserLocation handleAddress={handleAddress} />
        <PrOptions />
      </div> */}

      <Listing profiles={profiles} userAddress={userAddress} />
    </>
  );
}
