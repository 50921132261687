import { useState, useEffect } from "react";
import { servicesArray } from "./arrays/studioServicesArray";

export default function StudioServices({
  addData,
  docReady,
  studio,
  servicesError,
}) {
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("services");

  useEffect(() => {
    if (docReady && studio) {
      setData(studio.services);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docReady]);

  useEffect(() => {
    if (dataId) {
      addData(dataId, data);
    }
  }, [data, dataId, addData]);

  const onChange = (e) => {
    const { id, value, checked } = e.target;
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    }
    if (checked) {
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }
    setDataId(id);
  };

  return (
    <>
      <h3 className="text-gray-900 ml-4 font-semibold">Studio Services</h3>
      <div>
        {/* <ul className="m-4 w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg"> */}
        <ul
          className={`${
            servicesError
              ? "border-2 border-red-500"
              : "bg-white border border-gray-200"
          } m-4 w-48 text-sm font-medium text-gray-900 rounded-lg`}
        >
          {servicesArray.map((item) => (
            <li
              key={item.value}
              className="w-full border-b border-gray-200 rounded-t-lg"
            >
              <div className="flex items-center pl-3">
                <input
                  id={item.id}
                  type={item.type}
                  value={item.value}
                  checked={data.includes(item.value)}
                  onChange={onChange}
                  className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                />
                <label
                  className="w-full py-3 ml-2 text-sm font-medium text-gray-900 "
                  htmlFor={item.id}
                >
                  {item.title}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
