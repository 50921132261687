//libraries
import { doc, getDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase";

//external icons
import { BsPlayBtn } from "react-icons/bs";
import { MdOutlinePhotoLibrary } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { GiPaintBrush } from "react-icons/gi";
import { GiShoppingCart } from "react-icons/gi";

//necessary components
import ShowPhotoCams from "../components/proPackageComponents/ShowPhotoCams";
import ShowPhotoEquipment from "../components/proPackageComponents/ShowPhotoEquipment";
import ShowVideoCams from "../components/proPackageComponents/ShowVideoCams";
import ShowVideoEquipment from "../components/proPackageComponents/ShowVideoEquipment";
import ShowTitle from "../components/proPackageComponents/ShowTitle";
import PackageEnquiry from "../components/proPackageComponents/PackageEnquiry";
import PackagePurchase from "../components/proPackageComponents/PackagePurchase";

export default function ProPackages() {
  const [proPackage, setProPackage] = useState();
  const [user, setUser] = useState();
  const [profile, setProfile] = useState();
  const [imageType, setImageType] = useState();
  const navigate = useNavigate();

  //icon components
  const iconClass = "w-10 h-10 fill-current text-orange-400";
  const containerClass = "m-2 flex items-center";
  //grid-cols-1 md:
  const lineItemClass = "py-2 underline decoration-2 decoration-orange-400";

  const { docid } = useParams();

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "packages", docid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        setProPackage(docSnap.data());
        setUser(docSnap.data().user);
      } else {
        console.log("No such document!");
      }
    })();
  }, [docid]);

  useEffect(() => {
    console.log(docid);
    console.log(proPackage);
  }, [docid, proPackage]);

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "profiles", user);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Profile Data: ", docSnap.data());
        setProfile(docSnap.data());
      } else {
        console.log("No such profile exists");
      }
    })();
  }, [user]);

  useEffect(() => {
    const type = proPackage?.shootImages.replaceAll("_", " ");
    setImageType(type);
  }, [proPackage?.shootImages]);

  return (
    <>
      {proPackage && (
        <>
          <div className="w-full bg-gray-100 py-2">
            <div className="mt-6 p-2 w-11/12 md:w-4/5 mx-auto bg-white shadow-md relative">
              <span className="text-xl sm:text-4xl absolute right-2 top-2 p-1 bg-[#fa9204] hover:shadow-md shadow">
                R{proPackage.price}
              </span>
              <ShowTitle
                proPackage={proPackage}
                profile={profile}
                user={user}
              />
              <div className="place-content-center items-center">
                {/* <button className="block mx-auto my-2 p-2 border rounded-md shadow text-white text-xl sm:text-2xl bg-green-400 hover:bg-green-500 active:bg-green-600">
                  <GiShoppingCart className="inline-block mr-2" />
                  Buy Package
                </button> */}
                {/* <button
                  onClick={() => navigate(`/portfolio/${user}`)}
                  className="min-w-min block mx-auto my-2 p-2 border rounded-md shadow text-white text-xl sm:text-2xl bg-blue-700 hover:bg-blue-800 active:bg-blue-900"
                >
                  <GiPaintBrush className="inline-block" />
                  View Portfolio
                </button> */}
                <PackagePurchase
                  profile={profile}
                  docid={docid}
                  proPackage={proPackage}
                />
                <PackageEnquiry
                  profile={profile}
                  docid={docid}
                  proPackage={proPackage}
                />
              </div>
              <div className="p-2">
                <div className="flex gap-2 pt-2 place-content-center items-center">
                  {proPackage.photography && (
                    <>
                      <MdOutlinePhotoLibrary className="w-10 h-10 text-black mr-2" />
                      <span>Photos</span>
                    </>
                  )}
                  {proPackage.videography && (
                    <>
                      <BsPlayBtn className="w-10 h-10 text-black ml-2" />
                      <span>Videos</span>
                    </>
                  )}
                </div>

                {proPackage.shootHours && (
                  <p className="py-2">
                    Shoot Duration:{" "}
                    <span className={lineItemClass}>
                      {proPackage.shootHours} hours
                    </span>
                  </p>
                )}
                {proPackage.noImages && (
                  <p className="py-2">
                    # of Images:{" "}
                    <span className={lineItemClass}>{proPackage.noImages}</span>
                  </p>
                )}
                {proPackage.shootImages && (
                  <p className="py-2">
                    Type of Images:{" "}
                    <span className={`${lineItemClass} capitalize`}>
                      {imageType}
                    </span>
                  </p>
                )}
                {proPackage.noVideos && (
                  <p className="py-2">
                    # of Videos:{" "}
                    <span className={lineItemClass}>{proPackage.noVideos}</span>
                  </p>
                )}
                {proPackage.videoDuration && (
                  <p className="py-2">
                    Video Duration:{" "}
                    <span className={lineItemClass}>
                      {proPackage.videoDuration} minutes
                    </span>
                  </p>
                )}
                {proPackage.delivery_time && (
                  <p className="py-2">
                    Delivery Time:{" "}
                    <span className={lineItemClass}>
                      {proPackage.delivery_time > 24
                        ? `${proPackage.delivery_time / 24} days`
                        : `${proPackage.delivery_time} hours`}
                    </span>
                  </p>
                )}
                {proPackage.aboutPackage && (
                  <p className="py-2">
                    About Package:{" "}
                    <span className={lineItemClass}>
                      {proPackage.aboutPackage}
                    </span>
                  </p>
                )}

                {/* <ul>
                {proPackage.regions.map((region) => {
                  return <li>{region}</li>;
                })}
              </ul> */}
                <div className="flex flex-wrap items-center place-content-center">
                  {proPackage.regions.map((region) => {
                    return (
                      <div
                        key={region}
                        className="min-w-min border text-center m-2 px-5 p-1 rounded-full"
                      >
                        <span className="">
                          <MdLocationOn className="text-orange-400 inline-block align-middle" />{" "}
                          {region}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="flex">
                  <p className="mx-auto text-sm">
                    No additional cost in these regions.
                  </p>
                </div>
              </div>

              {(proPackage.photoCams || proPackage.photoEquipment) && (
                <>
                  <h2 className="text-xl">Photography Devices and Equipment</h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2">
                    {proPackage.photoCams && (
                      <ShowPhotoCams
                        handleData={proPackage.photoCams}
                        handleIconClass={iconClass}
                        handleContainerClass={containerClass}
                      />
                    )}
                    {proPackage.photoEquipment && (
                      <ShowPhotoEquipment
                        handleData={proPackage.photoEquipment}
                        handleIconClass={iconClass}
                        handleContainerClass={containerClass}
                      />
                    )}
                  </div>
                </>
              )}
              {(proPackage.videoCams || proPackage.videoEquipment) && (
                <>
                  <h2 className="text-xl">Videography Devices and Equipment</h2>
                  <div className="grid sm:grid-cols-2 grid-cols-1">
                    {proPackage.videoCams && (
                      <ShowVideoCams
                        handleData={proPackage.videoCams}
                        handleIconClass={iconClass}
                        handleContainerClass={containerClass}
                      />
                    )}
                    {proPackage.videoEquipment && (
                      <ShowVideoEquipment
                        handleData={proPackage.videoEquipment}
                        handleIconClass={iconClass}
                        handleContainerClass={containerClass}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
