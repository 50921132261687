// React
import React, { useCallback, useState } from "react";

// Firebase
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";

// Components
import EnquiryComsPref from "../portfolioPageComponents/enquiryFormComponents/EnquiryComsPref";
// import EnquiryContactNumber from "../portfolioPageComponents/enquiryFormComponents/EnquiryContactNumber";
import EnquiryDetails from "../portfolioPageComponents/enquiryFormComponents/EnquiryDetails";
import EnquiryEmail from "../portfolioPageComponents/enquiryFormComponents/EnquiryEmail";
import EnquiryName from "../portfolioPageComponents/enquiryFormComponents/EnquiryName";
import EnquiryContactNo from "../portfolioPageComponents/enquiryFormComponents/EnquiryContactNo";

// Extras
import { toast } from "react-toastify";
import { GoQuestion } from "react-icons/go";

export default function PackageEnquiry({ profile, docid, proPackage }) {
  const [show, setShow] = useState(false);

  // const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  // const [profError, setProfError] = useState(false);

  const hostname = window.location.hostname;

  const addData = useCallback((id, data) => {
    if (hostname === "localhost") {
      console.log("Local onChange: ", id, data);
    }

    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  const closeModal = () => {
    setShow(false);
    // console.log("close modal activated");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (hostname === "localhost") {
      console.log(formData);
    }

    const docRef = collection(db, "bookingEnquiries");

    try {
      await addDoc(docRef, {
        ...formData,
        submittedAt: serverTimestamp(),
        answered: false,
        professional: profile.name,
        user: profile.user,
        status: "just asked",
        packageID: docid,
        package: [{ ...proPackage }],
        price: proPackage.price,
      });
      toast.success("Your form has been submitted, we will be in touch.");
      setFormData("");
      document.getElementById("form").reset();
      closeModal();
      // console.log("After Submit: ", formData);
    } catch (error) {
      console.log(error.message);
      toast.error(
        "Something went wrong with your submission, please try again"
      );
    }
  };

  return (
    <>
      <div>
        <button
          onClick={() => setShow(true)}
          className="block mx-auto my-2 p-2 border rounded-md shadow text-white text-lg sm:text-xl bg-blue-400 hover:bg-blue-500 active:bg-blue-600"
        >
          <GoQuestion className="inline-block mr-2" />
          Make Enquiry
        </button>

        {/* <!-- Main modal --> */}
        <div
          className={`${
            show === true ? "" : "hidden"
          } absolute top-0 left-0 bg-black w-screen h-full bg-opacity-80`}
          onClick={closeModal}
        ></div>
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`${
            show === true ? "" : "hidden"
          } absolute left-0 md:left-10 top-5 md:top-10 w-screen md:w-4/5 z-10 p-4`}
        >
          <div className="relative w-full max-w-2xl max-h-full">
            {/* <!-- Modal content --> */}
            <div className="bg-white rounded-lg shadow">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-4 border-b rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  Ask {profile?.name} about their package
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={closeModal}
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-1 sm:p-6 space-y-6">
                <p className="text-base leading-relaxed text-gray-500">
                  Ask {profile?.name} a question about their photography and/or
                  videography services.
                  <br />
                  Only BraThula will have your contact details.
                  <br />
                  If you have any questions, please email: hello@brathula.com
                </p>
                <form onSubmit={onSubmit} id="form">
                  <EnquiryName addData={addData} />
                  <EnquiryDetails addData={addData} />
                  <EnquiryEmail addData={addData} />
                  <EnquiryContactNo addData={addData} />
                  <EnquiryComsPref addData={addData} />
                  {/* <!-- Modal footer --> */}
                  <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                      data-modal-hide="defaultModal"
                      className="text-white bg-orange-400 hover:bg-orange-500 active:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      Ask
                    </button>
                    <button
                      data-modal-hide="defaultModal"
                      type="button"
                      onClick={closeModal}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal toggle --> */}
    </>
  );
}
