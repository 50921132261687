import React, { useState, useEffect } from "react";

export default function FAQPros({ proFAQs }) {
  const [showAnswer, setShowAnswer] = useState([]);
  // const [showProAnswer, setShowProAnswer] = useState([]);

  useEffect(() => {
    setShowAnswer(Array(proFAQs.length).fill(false));
    console.log("Lets see these FAQS please:", proFAQs);
  }, [proFAQs]);

  return (
    <>
      {proFAQs.map((item, index) => {
        //   console.log(item.data());
        const handleClick = () => {
          // console.log("I have been clicked: ", index);
          const nextShow = showAnswer.map((show, idx) => {
            if (idx === index) {
              if (show === true) {
                return false;
              } else {
                return true;
              }
            } else {
              return show;
            }
          });
          setShowAnswer(nextShow);
          console.log(showAnswer);
        };

        return (
          <div key={item.data().question} className="flex">
            <div
              onClick={handleClick}
              key={item.data().question}
              className="bg-white mx-auto border w-full md:w-4/5 m-2 shadow-lg hover:shadow-orange-400"
            >
              <h1 className="text-xl m-5 capitalize">{item.data().question}</h1>
              {showAnswer[index] ? (
                <div className="border-l-2 px-5 my-5 border-orange-400">
                  <div
                    dangerouslySetInnerHTML={{ __html: item.data().answer }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
