export const studioTypeArray = [
  {
    id: "type",
    title: "Home Studio",
    value: "home",
    type: "radio",
    name: "type",
  },
  {
    id: "type",
    title: "Mall Studio",
    value: "mall",
    type: "radio",
    name: "type",
  },
  {
    id: "type",
    title: "Office Studio",
    value: "office",
    type: "radio",
    name: "type",
  },
];
