import React, { useState, useEffect } from "react";

export default function CreateName({ addData, profileDocReady, profile }) {
  const [data, setData] = useState("");
  const [dataId, setDataId] = useState("name");

  useEffect(() => {
    if (profileDocReady && profile.name) {
      setData(profile.name);
    }
  }, [profileDocReady]);

  useEffect(() => {
    addData(dataId, data);
  }, [addData, data, dataId]);

  const onChange = (e) => {
    const { value, id } = e.target;

    setDataId(id);
    setData(value);
  };
  return (
    <>
      <div className="m-5">
        <label className="">
          <span className="mr-2">Name</span>
          <input
            className="border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
            required
            type="text"
            id="name"
            value={data}
            onChange={onChange}
          />
        </label>
      </div>
    </>
  );
}
