import React from "react";

export default function FAQQuestion({ addData }) {
  return (
    <>
      <div className="m-4 w-full">
        <label>
          Question:
          <input
            className="w-full md:w-1/2 border-b-2 border-t-0 border-l-0 border-r-0 focus:border-b focus:ring-0 focus:border-orange-400 border-orange-400"
            type="text"
            id="question"
            name="question"
            onChange={(e) => addData(e.target.id, e.target.value)}
          />
        </label>
      </div>
    </>
  );
}
