import React from "react";
import { BsPlayBtn } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { MdOutlinePhotoLibrary } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import PackageStatus from "./packagesInterfaceComponents/PackageStatus";
import DeletePackage from "./packagesInterfaceComponents/DeletePackage";

export default function PackagesInterface({
  packages,
  user,
  ids,
  maxPackages,
  packagesDocReady,
}) {
  const navigate = useNavigate();
  return (
    <>
      {packagesDocReady && packages.length > 0 ? (
        <div className="w-full bg-gray-100">
          <div className="items-center mx-auto w-full sm:w-4/5 md:w-2/3 m-2">
            <div className="grid flex-col gap-3 m-2 p-1">
              {packages.map((item, index) => {
                // setID(ids[index]);
                return (
                  <div
                    className="relative bg-white border shadow-lg"
                    key={index}
                  >
                    <PackageStatus item={item} />
                    <BiEdit
                      title="edit package"
                      onClick={() =>
                        navigate(`/create-package/${user}/${ids[index]}`)
                      }
                      className="absolute h-6 w-6 md:h-8 md:w-8 bottom-4 left-4 p-1 hover:rounded-full hover:ring-orange-500 hover:ring-2 text-orange-400 hover:text-orange-500 active:text-orange-600 cursor-pointer"
                    />

                    <div className="mx-auto">
                      <div className="flex gap-2 pt-10 place-content-center">
                        {item?.services.includes("photography") && (
                          <MdOutlinePhotoLibrary className="w-6 h-6 text-black mr-2" />
                        )}
                        {item?.services.includes("videography") && (
                          <BsPlayBtn className="w-6 h-6 text-black ml-2" />
                        )}
                      </div>
                      <div className="text-sm text-center">
                        {item?.shootHours && (
                          <p>Shoot Duration: {item.shootHours} hours</p>
                        )}
                        {item?.noImages && <p># Images: {item?.noImages}</p>}
                        {item?.noVideos && <p># Videos: {item?.noVideos}</p>}
                        {item?.delivery_time === "0" ? (
                          <p>Delivery Time: Immediate</p>
                        ) : item.deliveryTime > 0 && item.deliveryTime < 24 ? (
                          <p>Delivery Time: {item.deliveryTime} hours</p>
                        ) : (
                          <p>Delivery Time: {item.deliveryTime / 24} days</p>
                        )}
                      </div>
                    </div>

                    {/* <div className="">
                      <ul className="place-content-center text-xs flex flex-wrap gap-2 p-1">
                        {item.regions &&
                          item.regions.map((place) => {
                            return (
                              <li
                                key={place}
                                className="bg-transparent border-2 border-gray-400 p-1"
                              >
                                {place}
                              </li>
                            );
                          })}
                      </ul>
                    </div> */}
                    <span className="absolute right-0 top-0 p-1 bg-[#fa9204] shadow">
                      R{item.price}
                    </span>
                    <DeletePackage user={user} docId={ids[index]} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex w-full bg-gray-100">
            <button
              onClick={() => {
                navigate(`/create-package/${user}`);
              }}
              type="button"
              disabled={packages.length >= maxPackages}
              className={`${
                packages.length >= maxPackages
                  ? "bg-gray-400 cursor-not-allowed"
                  : "border-blue-400 bg-blue-400 hover:bg-blue-500 active:bg-blue-600"
              } mx-auto border p-2 m-5 `}
            >
              Add Package
            </button>
          </div>
        </div>
      ) : (
        <div className="flex w-full py-10">
          <a
            href={`/create-package/${user}`}
            className="font-medium text-lg mx-auto"
          >
            Don't have a package?{" "}
            <span className="text-[#fa9204] cursor-pointer">Create one</span>
          </a>
        </div>
      )}
    </>
  );
}
