import React, { useEffect, useState } from "react";

function CreateShootDuration({ addData, proPackage, packageDocReady }) {
  const [data, setData] = useState();
  const [dataId, setDataId] = useState("shootHours");

  useEffect(() => {
    addData(dataId, data);
  }, [addData, dataId, data]);

  useEffect(() => {
    if (packageDocReady && proPackage) {
      setData(proPackage.shootHours);
    }
  }, [packageDocReady]);

  const onChange = (e) => {
    const { value, id } = e.target;
    setData(value);
    setDataId(id);

    // addData(id, value);
  };

  return (
    <>
      <div className="m-4">
        <label>
          <span>Shoot duration (hours)</span>

          <input
            type="number"
            min="1"
            max="24"
            required
            value={data}
            id="shootHours"
            onChange={onChange}
            className=" ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
          />
        </label>
        <p className="text-xs mt-2">number of hours for the shoot</p>
      </div>
    </>
  );
}

export default CreateShootDuration;
