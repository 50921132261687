import { getDownloadURL, listAll, ref } from "firebase/storage";
import React, { useRef, useEffect, useState } from "react";
import { storage } from "../../../firebase";
import { BsPlayCircle } from "react-icons/bs";

export default function VideoGallery({ profile }) {
  const imageListRef = useRef(ref(storage, `videos/${profile.user}`));
  const [videoList, setVideoList] = useState([]);

  const [toggler, setToggler] = useState(false);

  const [showControls, setShowControls] = useState(
    Array(videoList.length).fill(false)
  );
  // const controlsRef = useRef([]);
  const vidRef = useRef([]);

  useEffect(() => {
    listAll(imageListRef.current).then(async (response) => {
      const promises = response.items.map((item) => getDownloadURL(item));
      const urls = await Promise.all(promises);

      if (urls) {
        urls.map((download) => {
          const newDownload = download.replace(
            "https://firebasestorage.googleapis.com",
            "https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/"
          );
          setVideoList((prevValue) => {
            return [...prevValue, newDownload];
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    setShowControls(Array(videoList.length).fill(false));
  }, [videoList]);

  //How to play only the current video
  const playVideo = (index) => {
    vidRef.current[index].play();
    setShowControls((prev) => {
      return prev.map((control, idx) => {
        if (idx !== index) return control;
        return true;
      });
    });

    // console.log("show controls: ", showControls);
  };

  return (
    <>
      <div className="relative w-full p-1 group">
        <div className=" flex flex-wrap -m-1 md:-m-2 ">
          {videoList.map((url, index) => {
            // const videoURL = data.videoURL.replace(
            //   "https://firebasestorage.googleapis.com",
            //   ""
            // );
            return (
              <>
                <div
                  key={url}
                  className="relative flex w-full md:w-1/3 sm:w-1/2 flex-wrap group"
                >
                  <div key={url} className="relative w-full p-1 group">
                    {url && (
                      <video
                        src={url}
                        preload="metadata"
                        volume="true"
                        controls={showControls[index] ? "controls" : ""}
                        controlsList="nodownload"
                        ref={(el) => vidRef.current.push(el)}
                      />
                    )}
                    <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                      <BsPlayCircle
                        className={`text-[#fa9204] text-6xl cursor-pointer ${
                          showControls[index] ? `hidden` : ``
                        }`}
                        onClick={() => playVideo(index)}
                        value={url}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
