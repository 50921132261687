export const packageTypeArray = [
  {
    id: "type",
    title: "Studio",
    value: "studio",
    type: "radio",
    name: "type",
  },
  {
    id: "type",
    title: "On-Site",
    value: "onSite",
    type: "radio",
    name: "type",
  },
];
