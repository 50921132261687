import React from "react";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/Avatar/photo.svg";

export default function ShowTitle({ proPackage, user, profile }) {
  const navigate = useNavigate();
  return (
    <>
      <span className="text-xl sm:text-4xl capitalize">
        {proPackage.packageName}
      </span>
      <div className="h-24">
        <span>by</span>
        {/* <img
          src={`https://ik.imagekit.io/ajuaxvsrja/${profile?.profileImageURL}/tr:fo-auto,w-400,h-400,q-20`}
          className="w-20 h-20 rounded-full ring-2 ring-orange-400 p-1 m-2 inline-block"
          alt="profile"
        /> */}
        <img
          src={
            profile?.profileImageURL ===
              "/static/media/logo.7e36e6aeac51429ea175.png" ||
            profile?.profileImageURL === ""
              ? avatar
              : `https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/${profile?.profileImageURL}`
          }
          alt="profile"
          className="inline-block align-middle ml-1 mt-1 p-1 my-auto ring-2 ring-orange-400 object-cover w-20 h-20 rounded-full"
          loading="lazy"
          key={profile?.user}
          onClick={() => navigate(`/portfolio/${user}`)}
        />
        <span
          onClick={() => navigate(`/portfolio/${user}`)}
          className="cursor-pointer ml-2 hover:underline"
        >
          {profile?.name}
        </span>
      </div>
    </>
  );
}
